import React, { useState, useEffect } from 'react';
import DropdownTypeahead, { MenuItem } from '@ids-beta/dropdown-typeahead';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxEvent } from '../../../../assets/pagedata/constant';
import '../../../../stylesheets/comboBox.scss';
import { trackClickEvent } from '../../Util/CommonUtils';

export const ComboBox = (props) => {
  const [inputValue, setInputValue] = useState('');
  const [value, setValue] = useState('');
  const [typeValue, setTypeValue] = useState('');
  const [showFullData, setShowFullData] = useState(true);
  const customizationReducer = useSelector((state) => state.customizationReducer);
  const mainReducer = useSelector((state) => state.mainReducer);
  const isTrackingEnabled =
    mainReducer.clientConfigs.TrackStarenabled &&
    mainReducer.clientConfigs.TrackStarenabled.toLowerCase() === 'true';
  const { comboDataSet } = customizationReducer;
  const dispatch = useDispatch();

  useEffect(() => {
    if (props.defaultSelectedValue) {
      setInputValue(props.defaultSelectedValue);
      setValue(props.defaultSelectedValue);
    } else {
      setInputValue(props.defaultTypedValue);
      setValue(props.defaultTypedValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.defaultSelectedValue]);

  useEffect(() => {
    if (props.defaultTypedValue) {
      setInputValue(props.defaultTypedValue);
      setValue(props.defaultTypedValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filterData = (data, inputData) => {
    if (!inputData) {
      return data;
    }
    return data.filter((item) => item.label.toLowerCase().indexOf(inputData.toLowerCase()) > -1);
  };

  const addToData = (newValue) => {
    const filterArr =
      comboDataSet &&
      comboDataSet.filter((item) => item.label.toLowerCase() === newValue.toLowerCase());
    if (filterArr && filterArr.length === 0) {
      comboDataSet.push({ value: newValue, label: newValue });
      dispatch({ type: ReduxEvent.COMBO_BOX_LIST, data: { comboDataSet } });
    }
  };

  const handleAddNewClick = (e) => {
    const newValue = e.target.value;
    addToData(newValue);
  };
  const addNewItemProps = { onClick: handleAddNewClick };

  const handleRenderItem = (data) => {
    return <MenuItem value={data.value}>{data.label}</MenuItem>;
  };

  const handleBlur = (e) => {
    const data =
      comboDataSet &&
      comboDataSet.filter(
        (item) => e.target.value && item.label.toLowerCase() === e.target.value.toLowerCase(),
      );
    const val = data && data.length > 0 ? data[0].value : '';
    const inpValue = val ? data[0].label : e.target.value;
    const dropdownValue = inpValue || val;
    setInputValue(inpValue);
    setShowFullData(true);
    setValue(val);
    addToData(inpValue);
    props.handleDropDownChange(dropdownValue);
    if (isTrackingEnabled) {
      trackClickEvent(e);
    }
  };

  const handleChange = (e, { selectedItem, highlightedIndex, method }) => {
    if (!selectedItem.value || !selectedItem.label) {
      return;
    }
    // check if keydown to select an addNew item
    if (highlightedIndex === 0 && method === 'enter') {
      addToData(selectedItem.label);
    }
    setInputValue(selectedItem.label);
    setShowFullData(true);
    setValue(selectedItem.value);
    props.handleDropDownChange(selectedItem.value);
    props.handleResetSelection();
  };

  const handleClick = () => {
    setShowFullData(true);
  };

  const handleKeyDown = (e, { highlightedItem }) => {
    if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
      const inputVal = highlightedItem.label || '';
      setShowFullData(!!value);
      setInputValue(inputVal);
    }
  };

  const handleInputChange = (e) => {
    setShowFullData(false);
    setInputValue(e.target.value);
    setTypeValue(e.target.value);
    setValue('');
    props.handleDropDownChange(e.target.value);
    props.handleResetSelection();
  };

  const data = showFullData ? comboDataSet : filterData(comboDataSet, typeValue);

  return (
    <DropdownTypeahead
      data-wa-link="choose bank name"
      data-ui-object="dropdown"
      data-ui-object-details="Bank Name"
      data-refer-ui-access-point="choose_bank_name"
      label=""
      theme="quickbooks"
      addNew
      addNewText="Add New Bank"
      addNewItemProps={addNewItemProps}
      dataSource={data}
      errorText={props.validationError && !inputValue ? props.errMsg : ''}
      inputValue={inputValue}
      onBlur={handleBlur}
      onChange={handleChange}
      onClick={handleClick}
      onKeyDown={handleKeyDown}
      onSearch={handleInputChange}
      renderItem={handleRenderItem}
      value={value}
      className="comboBox combobox-container"
    />
  );
};
