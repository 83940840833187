import React from 'react';
import ICON_FEATUREDARTICLE from '../../images/IM/Resources/icon_featuredarticles.png';
import ICON_CHECKS from '../../images/IM/Resources/icon_checks.png';
import ICON_TAXFORMS from '../../images/IM/Resources/icon_taxforms.png';
import ICON_BUSINESSFRAUD from '../../images/IM/Resources/icon_businessfraudprevention.png';
import ICON_CHECKFRAUD from '../../images/IM/Resources/icon_checkfraudprevention.png';
import FeatureImage from '../../images/IM/Resources/image_resourcecentre_hero.jpg';
import CheckCompChart from '../../images/IM/Resources/CheckComparisonChart.png'

export const ResourceCenterData = {
  resourceCenter: {
    featureArticle: {
      title: 'FEATURED ARTICLES',
      icon: ICON_FEATUREDARTICLE,
      background: true,
      articleTopPadding: 'padding-top-80px',
      link: false,
      heroArticle: {
        details: {
          img: FeatureImage,
          smallHeader: 'CHECK FRAUD PROTECTION',
          heading: '5 Essential Tips on Check Security',
          description:
            'If you’re not careful, your physical checks can become a fraudster’s key to unlocking your finances, leaving you to spend months, maybe even years, recouping your losses.',
          bottomMargin: '',
          link: '/resources/fraud-prevention/5-things-to-look-for',
        },
      },
      faq: [
        {
          smallHeader: 'CHECK FRAUD PROTECTION',
          heading: 'The Big Three in Check Security',
          description:
            'While no check can claim to be 100% fraud proof, the right security features can go a long way to keep criminals from altering or photocopying your checks.',
          bottomMargin: '',
          link: '/resources/fraud-prevention/security-check-features',
        },
        {
          smallHeader: 'BUSINESS FRAUD PREVENTION',
          heading: 'Small Business Tax Scam: How to Lower Your Risk',
          description:
            'To gain access to your business’ most sensitive information, criminals may use tax scams to contact you directly for the information they need.',
          bottomMargin: '',
          link: '/resources/business-fraud-prevention/tax-scams-to-avoid',
          paraStyle: 'padding-right-30px',
          boxStyle: 'padding-right-10px',
        },
        {
          smallHeader: 'CHECKS',
          heading: 'Four Questions to Ask When Choosing Business Checks',
          description:
            'Asking yourself these four questions will help you figure out which business check meets your company’s needs.',
          bottomMargin: '',
          link: '/resources/fraud-prevention/4-questions-to-ask-when-choosing-checks',
          paraStyle: 'padding-right-30px',
          boxStyle: 'padding-right-20px',
        },
      ],
    },
    checks: {
      title: 'CHECKS',
      icon: ICON_CHECKS,
      background: false,
      link: '/resources/checks',
      buttonPadding: 'margin-bottom-80px',
      articleTopPadding: 'padding-top-60px',
      faq: [
        {
          smallHeader: 'CHECKS',
          heading: 'What You Need to Know About Business Checks',
          description:
            'While using a paper document for a payment may seem outdated, by no means are paper checks going out of style.',
          bottomMargin: '',
          link: '/resources/checks/what-is-a-business-check',
          paraStyle: 'padding-right-20px',
        },
        {
          smallHeader: 'CHECKS',
          heading: 'Business Check Endorsements: What You Should Know',
          description:
            'You receive a check for payment for your business. What next? You sign the back of the check and then take it to the bank, right? Not so fast.',
          bottomMargin: '',
          link: '/resources/checks/endorsements',
          paraStyle: 'padding-right-45px',
          boxStyle: 'padding-right-0px',
        },
        {
          smallHeader: 'CHECKS',
          heading: '5 Must-Haves for Your Check Writing Software',
          description:
            'If you’re considering printing business checks we recommend keeping these five things in mind when deciding which check-writing software to use.',
          bottomMargin: '',
          link: '/resources/checks/check-printing-software',
          paraStyle: 'padding-right-15px',
        },
      ],
    },
    taxForms: {
      title: 'TAX FORMS',
      icon: ICON_TAXFORMS,
      background: false,
      link: '/resources/tax-forms',
      buttonPadding: 'margin-bottom-60px',
      articleTopPadding: '',
      faq: [
        {
          smallHeader: 'TAX FORMS',
          heading: 'Get to Know IRS Tax Forms 1099 and 1096',
          description:
            'Have part-time workers or contractors you’ve paid more than $600 this year? You’ll need to send them a 1099 form.',
          bottomMargin: '',
          link: '/resources/tax-forms/1099-1096',
        },
        {
          smallHeader: 'TAX FORMS',
          heading: 'Get to Know IRS Tax Form W-2',
          description:
            'If you have employees, you’re required to report their wages and tax withholdings each year to the IRS. The W-2 is how you report an individual employee’s total annual wages.',
          bottomMargin: '',
          link: '/resources/tax-forms/w2-w3',
          boxStyle: 'margin-right-5px',
        },
      ],
    },
    checkFraud: {
      title: 'CHECK FRAUD PREVENTION',
      icon: ICON_CHECKFRAUD,
      background: true,
      link: '/resources/fraud-prevention',
      buttonPadding: 'margin-bottom-80px',
      articleTopPadding: 'padding-top-60px',
      faq: [
        {
          smallHeader: 'CHECK FRAUD PREVENTION',
          heading: 'What is Check Fraud & Why it Matters to Your Business',
          description:
            'Check fraud is a type of payment fraud that often involves someone intercepting your check and changing its information (washing) or creating fake checks (counterfeiting).',
          bottomMargin: '',
          link: '/resources/fraud-prevention/counterfeiting',
          paraStyle: 'padding-right-50px',
          boxStyle: 'padding-right-0px',
        },
        {
          smallHeader: 'CHECK FRAUD PREVENTION',
          heading: '5 Essential Tips on Check Security',
          description:
            'If you’re not careful, your physical checks can become a fraudster’s key to unlocking your finances, leaving you to spend months, maybe even years, recouping your losses.',
          bottomMargin: '',
          link: '/resources/fraud-prevention/5-things-to-look-for',
          paraStyle: 'padding-right-25px',
          boxStyle: 'padding-left-25px padding-right-0px',
        },
        {
          smallHeader: 'CHECK FRAUD PREVENTION',
          heading: 'How to Properly Dispose of Business Checks',
          description:
            'Choosing the right way to get rid of your checks can help make sure your financial and business information remains safe and secure.',
          bottomMargin: '',
          link: '/resources/fraud-prevention/how-to-dispose-of-checks',
          boxStyle: 'padding-left-20px padding-right-15px',
          // paraStyle: 'padding-right-10px',
        },
      ],
    },
    businessFraud: {
      title: 'BUSINESS FRAUD PREVENTION',
      icon: ICON_BUSINESSFRAUD,
      background: true,
      link: '/resources/business-fraud-prevention',
      buttonPadding: 'margin-bottom-60px',
      articleTopPadding: '',
      faq: [
        {
          smallHeader: 'BUSINESS FRAUD PREVENTION',
          heading: '10 Ways Your Employees Can Help You Fight Fraud',
          description:
            'Cybercriminals are waiting outside the proverbial door to steal confidential financial data and more. And guess who lets them in?',
          bottomMargin: '',
          link: '/resources/business-fraud-prevention/10-ways-employees-stop-scams',
          boxStyle: 'padding-right-10px',
        },
        {
          smallHeader: 'BUSINESS FRAUD PREVENTION',
          heading: 'Small Business Tax Scams: How to Lower Your Risk',
          description:
            'By learning how to spot the tax scams criminals like to use, you can help prevent your business from becoming a tax fraud victim.',
          bottomMargin: '',
          link: '/resources/business-fraud-prevention/tax-scams-to-avoid',
          boxStyle: 'padding-right-0px',
        },
        {
          smallHeader: 'BUSINESS FRAUD PREVENTION',
          heading: '6 Scams Targetting Small Businesses and How to Avoid Them',
          description:
            'Small businesses are increasingly falling victim to scams, so it pays to keep up your guard.',
          bottomMargin: '',
          link: '/resources/business-fraud-prevention/6-scams-to-know-and-avoid',
          paraStyle: 'padding-right-35px',
          boxStyle: 'padding-right-0px padding-left-5px',
        },
      ],
    },
    support: {
      links: [
        {
          linkText: 'Ordering and Shipping',
          ref: '/help/ordering-and-shipping',
        },
        {
          linkText: 'Managing My Account',
          ref: '/help/account',
        },
        {
          linkText: 'Terms of Service',
          ref: 'https://www.intuit.com/legal/terms/en-us/quickbooks/checks-supplies',
          target: '_blank',
        },
        {
          linkText: 'Customizing Products',
          ref: '/help/customization',
        },
        {
          linkText: 'Contact Checks & Supplies',
          ref: '/help/contact-intuit-market',
        },
      ],
    },
  },
  readMoreArticleData: {
    checks: {
      typeofBusinessChceks: {
        title: 'Choosing the Right Type of Business Checks',
        smallHeader: 'CHECKS',
        description: (
          <span>
            Business checks continue to thrive as a convenient and widely accepted form of payment.
            And they come in all sorts of styles, each with unique benefits. Picking the right check
            style for your business really comes down to how you plan to use the checks.
          </span>
        ),
        Topic: [
          {
            heading: <span>Business check basics</span>,
            para: (
              <>
                <span>
                  People will often consider these three basic check features when deciding what
                  checks are right for them.
                </span>
                <ul>
                  <li>
                    Printed checks or hand-written. Most businesses print their checks. However,
                    they may keep some checks on hand that they can write on the go.
                  </li>
                  <li>
                    One per page or three-to-a-page. One check per page will leave room for
                    additional information while three-to-a-a-page can only fit the checks.
                  </li>
                  <li>
                    Size. Business checks can vary in size depending on what you use them for.
                  </li>
                </ul>
              </>
            ),
            bottomPadding: 'padding-bottom-1px',
          },
          {
            heading: <span>Business check types</span>,
            subHeadings: [
              {
                subHeading: <span>Voucher checks</span>,
                subHeadingDetails: (
                  <span>
                    The most popular type of business check, voucher checks typically come with one
                    or more attached stubs. These stubs allow you to give employees or vendors a
                    receipt of payment for their records. They’re great if you need business checks
                    for payroll or for your accounts payable.
                    <br />
                    <br />
                    <span>
                      <a href="/checks/voucher-checks">QuickBooks Voucher Checks </a>
                    </span>
                    include two stubs – one for your employee’s records and one for your own.
                  </span>
                ),
              },
              {
                subHeading: <span>Wallet checks</span>,
                subHeadingDetails: (
                  <span>
                    Smaller and more compact than most business checks, wallet checks offer the
                    convenience of a form of payment you can carry around in… well, your wallet. If
                    you find yourself on the go for your business, these checks can easily go with
                    you.
                    <br /> <br />
                    <span>
                      <a href="/checks/wallet-checks">QuickBooks Wallet Checks </a>
                    </span>
                    include a check stub for recording payment details and come three-to-a-page for
                    fast printing, right from QuickBooks.
                  </span>
                ),
              },

              {
                subHeading: <span>Three-to-a-page checks</span>,
                subHeadingDetails: (
                  <span>
                    Like wallet checks, three-to-a-page checks allow you to print up to three checks
                    per page, shaving time off the printing process. If you like the
                    three-checks-to-a-page idea but prefer to pay your vendors and suppliers with a
                    more traditionally-sized business check, these checks might work best for you.{' '}
                    <br /> <br />
                    <span>
                      <a href="/checks/standard-checks">QuickBooks Standard Checks</a>
                    </span>{' '}
                    come in this three-to-a-page style. Like Voucher and Wallet checks, they’re
                    designed specifically for QuickBooks and they’ll print perfectly every time.
                  </span>
                ),
              },
              {
                subHeading: <span>Manual checks</span>,
                subHeadingDetails: (
                  <span>
                    If you’d prefer checks you can handwrite rather than print, manual checks might
                    be the right way to go. These checks come three-to-a-page with side-tear stubs
                    for recording transaction details. They’re easy to carry and fill out anywhere.
                    <br /> <br />
                    With{' '}
                    <span>
                      <a href="/checks/manual-checks">
                        QuickBooks Three-to-a-Page General Purpose Manual Checks
                      </a>
                    </span>{' '}
                    you also have the option of a seven-ring binder to keep your checks neat and
                    organized (sold separately).
                  </span>
                ),
              },
            ],
          },
        ],
      },
      whatIsBusinessCheck: {
        title: 'What You Need to Know About Business Checks',
        smallHeader: 'CHECKS',
        description: (
          <span>
            If you need to pay suppliers, vendors, or employees, a business check makes a convenient
            – and very sensible – form of payment. While using a paper document for a payment may
            seem outdated, by no means are paper checks going out of style. In fact, according to
            AFP’s <span className="font-italic">Not Going Anywhere: Why Checks Still Matter,</span>
            51% of organizations make business-to-business payments by check.
            <br /> <br />
            In this article, we’ll examine business checks in detail, see how they differ from
            personal checks, and help you get to know this important form of payment.
          </span>
        ),
        noTopicSpace: true,
        Topic: [
          {
            heading: <span>Larger sized</span>,
            para: (
              <span>
                Business checks typically come in a bigger size than personal checks. This makes the
                checks easier to print and read. It also allows them to fit neatly into a
                double-windowed envelope. If you need to mail them off, the address on the checks
                won’t get lost from view. <br /> <br /> The larger size also gives business checks
                some breathing room to feature a company logo. With{' '}
                <a href="/checks">QuickBooks Printable Checks</a>, you can add your logo and
                customize your checks the way you want them. If you don’t have a logo, no problem.
                You can choose from a library of graphics to add just the right touch of
                personalization.{' '}
                <a href="/help/customization">Learn more about customizing your checks</a>
              </span>
            ),
          },
          {
            heading: <span>More professional</span>,
            para: (
              <span>
                Unlike personal checks that let you choose your favorite sports team or cityscape
                for a design, business check designs tend to be cleaner and more straightforward.
                They may let you choose between colors when you order, but the final product usually
                features a single color on the front. The goal is to look elegant and professional,
                not flashy or funny. Remember, your business checks make a statement about you and
                your business.
              </span>
            ),
          },
          {
            heading: <span>More options</span>,
            para: (
              <span>
                Business checks usually offer more options and more flexibility, such as a larger
                memo line, giving you more room to record the details of the transaction. They also
                include a larger payee line to accommodate your payee’s address, if needed. <br />{' '}
                <br />
                If you’re paying employees or vendors, you may want to consider a check with an
                attached stub. Our <a href="/checks/voucher-checks">QuickBooks Voucher Checks </a>
                even offer two stubs for added convenience – one stub you can give your employee and
                one you can keep for your own records.
              </span>
            ),
          },
          {
            heading: <span>Multiple signature lines</span>,
            para: (
              <span>
                With business checks, you also have the option of featuring two signature lines.
                This means that two signatures are required for the check to be considered valid. A
                two-signature requirement puts control of your business payments in the hands of two
                people instead of one, thus lowering the risk of someone writing improper checks on
                your company’s dime.
              </span>
            ),
          },
          {
            heading: <span>More security</span>,
            para: (
              <span>
                If you’re a small business owner, you must make efforts to protect your documents
                from fraud or you open your company up to potential liability and lawsuits. Business
                checks often help in that regard by offering more security and fraud protection than
                personal checks.
                <br /> <br />
                Always make sure any business checks you buy are printed on a unique paper stock. If
                the paper can’t be bought anywhere else, criminals can’t easily duplicate your
                checks. Some other important anti-fraud security features to look for include
                chemically-reactive paper, watermarks, heat-sensitive icons, and fraud-sensitive
                ink. When it comes to keeping criminals out of your finances, the more security you
                have, the better.
                <br /> <br />
                <a href="/spvcpremier">QuickBooks Secure Premier Checks</a> offer 29 anti-fraud
                features, including a security hologram (like passports have). They’re the most
                secure checks on the market.<sup>1</sup>
              </span>
            ),
          },
        ],
        notes: [
          {
            note: '1.	Based on feature comparison of high-security checks, conducted February 2018',
          },
        ],
      },
      endorsements: {
        title: 'Business Check Endorsements: Types & Restrictions',
        smallHeader: 'CHECKS',
        description: (
          <span>
            You receive a check for payment for your business. What next? You sign the back of the
            check and then take it to the bank, right? Not so fast. If not endorsed in the right way
            and at the right time, you could be putting your business at risk. <br /> <br />
            Here’s what to keep in mind when you need to endorse a check.
          </span>
        ),
        Topic: [
          {
            heading: 'When to endorse',
            para: (
              <span>
                You should never endorse a check before you're ready to deposit or cash it. The
                moment you sign the back of a check, it becomes negotiable. Anyone who gets their
                hands on it can take it to the bank and cash it. When it comes to an endorsed check,
                you can’t trust the lock on your car to keep it safe. You can’t trust the safe at
                work. The safest place to keep a signed check is on deposit at your bank.
              </span>
            ),
          },
          {
            heading: 'Types of endorsements',
            para: (
              <span>
                Endorsing a check is as simple as flipping it over and signing your name, right?
                Before you do that, consider these five types of check endorsements. They might fit
                your needs better than a simple signature.
                <br />
                <br />
                There are five basic types of check endorsements:
              </span>
            ),
            subHeadings: [
              {
                subHeading: <span>1. Qualified endorsement</span>,
                subHeadingDetails: (
                  <span>
                    With a qualified endorsement, you remove yourself from any responsibility should
                    the check get returned. Typically, this endorsement includes language like
                    “Without recourse.”
                    <br /> <br />
                    Of course, your bank probably won’t accept your check with this type of
                    endorsement since you’re basically telling them they can’t recoup the funds from
                    your account if the check bounces.
                  </span>
                ),
              },
              {
                subHeading: <span>2. Restrictive endorsement</span>,
                subHeadingDetails: (
                  <span>
                    Like a qualified endorsement, a restrictive endorsement also places a condition
                    on the negotiability of the check. The most common restriction is “for deposit
                    only.”
                    <br /> <br />
                    Unlike qualified endorsements, banks receive this type of endorsement all the
                    time. They’ll usually accept it without question.
                  </span>
                ),
              },
              {
                subHeading: <span>3. Special endorsement</span>,
                subHeadingDetails: (
                  <span>
                    A special endorsement allows you to make your business check payable to someone
                    else. On the back of the check, you would write “Pay to the order of Jane Doe”
                    and then sign it. This effectively turns the check ownership over to that person
                    or entity.
                    <br />
                    However, banks don’t usually recommend that you go this route. When Jane Doe
                    attempts to cash the check, the bank will likely require that you’re present for
                    the transaction to confirm the validity of your special endorsement.
                  </span>
                ),
              },
              {
                subHeading: <span>4. Conditional endorsement</span>,
                subHeadingDetails: (
                  <span>
                    A conditional endorsement works similarly to a special endorsement, except that
                    you’re adding a caveat to the endorsement. The language specifies that something
                    needs to happen before the check becomes payable. For example, “Payable to Jane
                    Doe upon successful completion of roof replacement.” Then you’d sign it. This
                    turns the check over to her. <br />
                    Like the special endorsement, your bank may frown down on this type of
                    endorsement and even outright refuse to pay the check. In the case of our Jane
                    Doe example, she needs to replace a roof before negotiating the check. How would
                    the bank verify that?
                  </span>
                ),
              },
              {
                subHeading: <span>5. Blank endorsement</span>,
                subHeadingDetails: (
                  <span>
                    This is the most common type of endorsement. If you flip a check payable to you
                    over and sign the back without adding any restrictive language, you’ve just
                    blank endorsed it. But don’t forget, once you sign a check like this, it’s
                    immediately negotiable. Make sure you deposit it or cash it right away so
                    someone else doesn’t.
                  </span>
                ),
              },
            ],
          },
          {
            heading: <span>Endorsement stamps</span>,
            para: (
              <span>
                Endorsement stamps are certainly faster and more convenient than hand signing a
                stack of checks. For those who regularly endorse a lot of checks, it may make sense
                to get a stamp. If you decide to use an endorsement stamp, make sure your deposit
                text follows a format like this: <br />
                <ul className="list-style-none">
                  <li>Pay to the order of</li>
                  <li>Your bank name</li>
                  <li>For deposit only</li>
                  <li>Your company name</li>
                  <li>Your bank account number</li>
                </ul>
                Additionally, when you stamp your checks, always check that the stamped endorsement
                is clear and legible. If the check gets stolen or misplaced, it’ll be harder for
                someone to alter the deposit information.
                <br /> <br />
                <a href="/stampsandlabels/endorsement-stamps">QuickBooks Endorsement Stamp </a>
                is self-inking, so you’ll get better quality impressions and save money with ink
                refill pads.
              </span>
            ),
          },
        ],
      },
      check21: {
        title: 'What Is Check 21 and How Does It Affect Small Business Owners?',
        smallHeader: 'CHECKS',
        description: (
          <span>
            If you’re like most small business owners, you write and deposit checks. A lot of them.
            In 2004, a federal law passed that made regulations around the process of clearing
            checks faster and more efficient. That law was called the Check Clearing for the 21st
            Century Act or Check 21 for short.
          </span>
        ),
        Topic: [
          {
            heading: <span>Clearing checks</span>,
            para: (
              <span>
                In the past, banks that received checks for deposit would have to ship those paper
                checks off to the bank that pays them. This process often took days, even weeks, for
                checks to clear. The Check 21 rules allow banks to capture images of checks and
                clear them electronically rather than using paper documents. This dramatically
                decreased the time needed for check processing. Where payment once took days, checks
                can now clear in a matter of hours.
                <br /> <br />
                Remember, because of Check 21, your checks can clear at any time: Make sure you
                always have sufficient funds in your bank account to cover the checks you write.
              </span>
            ),
          },
          {
            heading: <span>Substitute checks</span>,
            para: (
              <span>
                You probably didn’t notice anything when Check 21 took effect. However, if you ask
                your bank to mail your checks back to you after they clear, you’ll now get copies
                rather than the originals. These copies are referred to as “substitute checks” or in
                legal terms, “image replacement documentation” (IRDs) and they’re considered just as
                legal as your original documents. Since they’re considered legal documents,
                substitute checks can be used as proof of payment and the IRS will accept them as
                proof that you paid your taxes.
                <br /> <br />
                A substitute check is slightly larger than a typical personal check and includes an
                image of your original document. Check 21 established printing standards for
                substitute checks, so it can be treated as the original legal document. The check
                must accurately represent the original check information. It must also display this
                statement: “This is a legal copy of your check. You can use it the same way you
                would use the original check.” If you receive a substitute check and anything looks
                out of place – like the image of the original appears blurry or the substitute check
                statement is missing - contact your bank immediately and let them know.
                <br /> <br />
                Banks are required to provide a disclosure about substitute checks to any customers
                who routinely receive their checks back with their monthly bank statements. If you
                opened your bank account after October 2004, you likely received this disclosure
                with your new account materials. Your bank must also provide this disclosure if you
                request an original check (or copy of the original) and they send you a substitute
                check.
                <br /> <br />
                Bear in mind, if you ask your bank for your original checks back, they’re not
                required to keep them, so getting your originals back may not be an option for you.
                Check 21 doesn’t mandate any retention requirements for financial institutions. But
                again, a substitute check is legally the same as the original.
                <br /> <br />
                If you receive a substitute check that represents a fraudulent original, check law
                offers you protections against this – as long as you notify your bank in a timely
                manner. These protections apply whether you receive a fraudulent original check, a
                substitute check, an image statement, or a line item in your banking account
                statement.
              </span>
            ),
          },
          {
            heading: <span>Protection against errors and fraud</span>,
            para: (
              <span>
                You’re protected against errors and unauthorized check payments by check law. Check
                21 also offers some new protections, including “expedited recredit,” a special
                refund process you can take advantage of if you’ve suffered a loss due to a
                substitute check.
                <br /> <br />
                If you take a loss because of a substitute check, you’ll need to file a claim for a
                refund under Check 21’s expedited recredit procedure. You can do this if you believe
                the substitute check was incorrectly charged to your bank account, if you incurred a
                loss because of the substitute check, and if you have the original check or a copy
                sufficient to demonstrate the substitute check was incorrectly charged to your bank
                account.
                <br /> <br />
                To file a claim under this Check 21 refund procedure, you should contact your
                financial institution as soon as you can, but not later than 40 days from your bank
                mailing or delivering your account statement. Your bank will ask you for information
                to investigate your claim.
                <br /> <br />
                Once you’ve filed your claim, your bank should process your claim right away. Should
                your bank determine that you’ve been incorrectly charged because of the substitute
                check, they must refund the amount of your claim and, if it’s an interest-bearing
                account, any loss of funds due to interest. If your bank determines you were charged
                incorrectly, you should receive a notice by the next business day outlining the
                amount of the refund and the date the refunded money will become available to you.
                If your bank finds they charged you correctly, you’ll also receive a notice, sent by
                the next business day, explaining the reasons for their decision. That notice will
                include the original check – or whatever copy you provided to validate your claim.
              </span>
            ),
          },
          {
            heading: <span>QuickBooks Checks</span>,
            para: (
              <span>
                If you’re a QuickBooks Checks customer, rest assured that your checks meet your
                bank’s electronic imaging requirements for Check 21 technology. In fact, we only
                sell checks that comply with Check 21 formatting.
                <br />
                <br />
                If you’d like more information about Check 21, contact your financial institution.
              </span>
            ),
          },
        ],
      },
      checkPrintingSoftware: {
        title: '5 Must-Haves for Your Check Writing Software',
        smallHeader: 'CHECKS',
        description: (
          <span>
            Not long ago, writing checks to pay business expenses meant physically writing checks.
            For a lot of businesses, that could mean dozens or even hundreds of checks every month,
            all done by hand. Fortunately, technology has provided some relief from this
            once-grueling task. Nowadays, your laptop and office printer can do virtually all the
            heavy lifting for you.
            <br /> <br />
            Yes, your business checks can be printed with a computer and an inkjet or laser printer.
            But to do this, you need software to correctly and accurately format and print the
            information on your check paper.
            <br /> <br />
            If you’re considering printing business checks instead of doing them the old-fashioned
            way, we recommend keeping these five things in mind when deciding which check-writing
            software to use.
          </span>
        ),
        Topic: [
          {
            heading: '1. Easy to use',
            para: (
              <span>
                Printing checks should lighten your workload, not add to it. If you’re investigating
                a check-writing software option and find yourself wondering if it would take you
                less effort to simply keep handwriting your checks, you might consider another
                option. The software should help you create, format, and print your business checks,
                subtracting work from you in the process. This includes being able to print a
                variety of types of business checks and voucher checks. You shouldn’t have to add
                complicated workarounds to make that software fit your business check needs.
              </span>
            ),
            bottomPadding: 'padding-bottom-0px',
          },
          {
            heading: '2. Syncs with your accounting system ',
            para: (
              <span>
                If you’re like many businesses, you use accounting software to do your bookkeeping
                and to make sure your financial picture always remains clear. Tracking the business
                checks you write is an important component of that. Your check printing software
                should sync perfectly with your accounting system, so your books stay accurate and
                up to date at all times.
                <br /> <br />
                QuickBooks comes with business check writing tools with proven software integration
                and Checks & Supplies provides Quickbooks printer checks. When you print your
                business checks, your books always stay in sync.
              </span>
            ),
            bottomPadding: 'padding-bottom-0px',
          },
          {
            heading: '3. Priced right',
            para: (
              <span>
                Like most products and services, pricing depends heavily on what you need. From free
                check writing software with very few bells and whistles to full-service offerings
                that actually print and mail all your checks for you, there’s something for just
                about every business need out there.
                <br /> <br />
                Often, check writing software offers an introductory or trial period that lets you
                test out the service before going all-in. Take advantage of a trial like that if
                it’s available, so you know the software is right for you. Also, check out the
                company offering the software to make sure they’re reputable and worth your time and
                money. Lastly, be sure the price for the software includes unlimited check printing.
                Maybe you’re only printing a handful of checks now, but if your check writing needs
                ever change, so will that price.
              </span>
            ),
            bottomPadding: 'padding-bottom-0px',
          },
          {
            heading: '4. Multi-bank account capable',
            para: (
              <span>
                Maybe you’re using one checking account for payroll, another to pay your invoices
                and vendors, and yet another for occasional office expenses. Many small businesses
                often use multiple bank accounts as a smart way to stay organized and keep finances
                secure. Your check printing software should be smart too and capable of switching
                between the bank accounts you need to use. Also, ensure the software includes
                unlimited bank accounts, so you don’t incur extra charges if you need to add
                additional checking accounts down the line.
              </span>
            ),
            bottomPadding: 'padding-bottom-0px',
          },
          {
            heading: '5. Handles deposit slips too',
            para: (
              <span>
                Your business checks are a critical component of your finances, but don’t forget
                that you probably make deposits to fund those checks. Why not print the deposit
                slips and lighten your load there too? Good business check writing software should
                also be able to print deposit slips and sync those deposits in your accounting
                system. This way, your books always stay up to date with those checking account
                transactions as well.
              </span>
            ),
            bottomPadding: 'padding-bottom-25px',
          },
        ],
      },
      fourBusinessChecks: {
        title: 'Four Questions to Ask When Choosing Business Checks',
        smallHeader: 'CHECKS',
        image: CheckCompChart,
        description: (
          <span>
            <a href="https://intuitmarket.intuit.com/checks/voucher-checks">Voucher checks</a> are
            the most popular choice for businesses; with one check on top and two stubs underneath,
            they’re ideal for payroll and invoice payment details. But checks are not a one-size
            fits all product and you might need more than one type during the life of your business.
            With all the different check types, how do you know which one to pick?
            <br /> <br />
            Asking yourself these four questions help you figure out which business check meets your
            company’s needs:
            <br /> <br />
            <p className="padding-left-40px font-size-20px">
              <li>Am I going to print checks, handwrite them, or both?</li>
              <li>Do I need to write checks away from the computer?</li>
              <li>How many checks will I be printing at once?</li>
              <li>What check fraud protections are best for my business?</li>
            </p>
          </span>
        ),
        Topic: [
          {
            heading: 'Am I going to print checks, handwrite them,or both?',
            para: (
              <span>
                <p className="padding-left-40px font-size-20px">
                  <span className="list-span">Best checks for handwriting:</span> Wallet or Manual
                  <br />
                  <span className="list-span">Best checks for printing:</span> Voucher or Standard
                  <br />
                  <span className="list-span">Best checks for either:</span> Wallet or Manual
                </p>
                <br />
                With accounting software like QuickBooks, printing checks through your computer can
                be a quick and efficient way to use business checks. The check number, amount,
                payee, and other important information is automatically captured by the accounting
                software, and you get a professional-looking, printed check. However, there are
                times when having checks you can handwrite is helpful.
                <br />
                <br />
                Hand-written checks are useful for writing bonus checks, covering unexpected
                expenses, or making a payment away from the office. If you will be handwriting
                checks, don’t forget to record the details so you can key them into your accounting
                software. Several check types offer stubs to help capture these details.
                <br />
                <br />
                <p className="list-span font-size-20px">Reasons why you’d handwrite checks:</p>
                <p className="padding-left-40px font-size-20px">
                  <li>Writing a small number of checks, usually less than 50</li>
                  <li>One-off or last-minute checks</li>
                  <li>Check writing away from the computer</li>
                </p>
                <br />
                <p className="list-span font-size-20px">Reasons why you’d print checks:</p>
                <p className="padding-left-40px font-size-20px">
                  <li>Automatically captures check information</li>
                  <li>Printing several checks at once, usually more than 50</li>
                  <li>Needing to capture payroll or invoice payment details</li>
                  <li>Wanting professional-looking checks</li>
                </p>
              </span>
            ),
          },
          {
            heading: <p className='heading-width'>Do I need to write checks away from the computer?</p>,
            para: (
              <span>
                <p className="padding-left-40px font-size-20px">
                  <span className="list-span">Best portable checks:</span> Wallet or Manual
                  <br />
                  <span className="list-span">Mix of portable and office:</span> Wallet
                </p>
                <br />
                If your work takes you out in the field or you don’t have a regular office, you
                might want portable checks. Although all checks are portable, some are designed to
                be used while on the go. These checks are often smaller than printable checks and
                include features like check stubs to record payment details and pre-punched holes so
                you can put them in a binder. Having something to store your checks in while away
                from the office can help prevent wrinkles, tears, and spills.
                <br />
                <br />
                When you’re taking your checks with you, remember to handle them the same way you
                handle cash, making sure they’re out of sight and out of reach of anyone who might
                want to steal them.
                <br />
                <br />
                <p className="list-span font-size-20px">Reasons why you’d want portable checks:</p>
                <p className="padding-left-40px font-size-20px">
                  <li>Often writing checks away from the computer</li>
                  <li>Needing a stub to record check info to key in later</li>
                  <li>Switching between handwriting and printing checks</li>
                </p>
              </span>
            ),
          },
          {
            heading: 'How many checks will I be printing at once?',
            para: (
              <span>
                <p className="padding-left-40px font-size-20px">
                  <span className="list-span">3-to-a-page:</span> Standard, Wallet, Manual, or
                  Office & Away
                  <br />
                  <span className="list-span">One check per page:</span> Voucher
                </p>
                <br />
                Most business checks come three to a page, making them ideal for when you need to
                print a lot of checks. An alternative is voucher checks which are one check and two
                stubs on a single page. Voucher checks are good for itemized information like
                withholdings, medical, and taxes on payroll checks or line-item details for vendor
                payments.
                <br />
                <br />
                The choice comes down to whether you need a check with detailed information, or a
                lot of checks printed at the same time. Depending on your business, you might even
                want both types of checks.
                <br />
                <br />
                <p className="list-span font-size-20px">Reasons why you’d want 3-to-a-page-checks</p>
                <p className="padding-left-40px font-size-20px">
                  <li>Printing many checks at a time</li>
                  <li>Handwriting, or alternating between printing and handwriting checks</li>
                  <li>Need for checks to be portable</li>
                </p>
                <br />
                <p className="list-span font-size-20px">Reasons why you’d want one check per page</p>
                <p className="padding-left-40px font-size-20px">
                  <li>Printing payroll checks</li>
                  <li>Printing checks that require detailed information</li>
                </p>
              </span>
            ),
          },
          {
            heading: 'What check fraud protections are best for my business?',
            para: (
              <span>
                <p className="padding-left-40px font-size-20px">
                  <span className="list-span">
                    The most secure checks in the U.S.<sup>1</sup>:
                  </span>{' '}
                  Secure Premier Voucher or Standard
                  <br />
                  <span className="list-span">Very secure checks:</span> Secure Plus Voucher,
                  Standard, or Wallet
                </p>
                <br />
                All checks come with basic security features; the padlock icon on a check indicates
                that it has at least three features designed to help prevent against fraud. But,
                with 82% of companies experiencing payment fraud², added security measures are
                critical for every business.
                <br />
                <br />
                Making sure the checks you buy have{' '}
                <a href="https://intuitmarket.intuit.com/resources/fraud-prevention/security-check-features">
                  the right security features
                </a>{' '}
                can help protect your business from fraud, no matter how you’re using them. Of
                course, the checks you use are only one{' '}
                <a href="https://intuitmarket.intuit.com/resources/fraud-prevention/5-things-to-look-for">
                  part of protecting your business from fraud
                </a>
                .
                <br />
                <br />
                <p className="list-span font-size-20px">Reasons why you’d want secure checks</p>
                <p className="font-size-20px padding-left-40px padding-bottom-8px">
                  <li>To reduce overall fraud risk</li>
                  <li>Your checks are handled by multiple parties</li>
                  <li>You’re often mailing checks</li>
                  <li>You have someone else writing checks for you</li>
                </p>
              </span>
            ),
          },
          {
            para: (
              <span>
                <p className="list-span font-size-20px">Read more:</p>
                <p className="padding-bottom-80px font-size-20px">
                  <a href="https://intuitmarket.intuit.com/resources/fraud-prevention/counterfeiting">
                    What Is Check Fraud & Why It Matters to Your Business
                  </a>
                  <br />
                  <a href="https://intuitmarket.intuit.com/resources/business-fraud-prevention/10-ways-employees-stop-scams">
                    10 Ways Your Employees Can Help You Fight Fraud
                  </a>
                  <br />
                  <a href="https://intuitmarket.intuit.com/resources/checks/check-printing-software">
                    5 Must-Haves for Your Check Writing Software
                  </a>
                  <br />
                </p>
                <p className="font-size-12 padding-bottom-5px">
                  <span>1. Based on feature comparison of secure checks conducted June 2021</span>
                  <br />
                  <span>2. 2019 AFP® Payments Fraud and Control Survey</span>
                </p>
              </span>
            ),
          },
        ],
      },
      sevenBusinessChecks: {
        title: '7 Most Common Business Check Mistakes to Avoid',
        smallHeader: 'CHECKS',
        description: (
          <span>
            To “write” a check, most business checks require that you fill in the same basic
            information: Payee, date, amount, and a signature (or two). Simple, right? Not so fast.
            Even a minor mistake when writing your check can cause your bank to reject it,
            potentially racking up fees for you and for your payee in the process. In some cases,
            your bank may even deem your innocent mistake as fraud.
            <br /> <br />
            Never fear—the most common mistakes made on business checks are easy enough to avoid. In
            this article, we’ll lay out some common check writing errors, so you can take steps to
            stay clear of them.
          </span>
        ),
        headingBottomSpace: true,
        Topic: [
          {
            heading: '1. Using the wrong color ink',
            headingSubContent: [
              <span className="headingSubContent">
                If you handwrite your business checks, it’s easy to grab the nearest pen around.
                Before you start writing, make sure that pen has blue or black ink. Using any other
                color could trigger your bank’s fraud protocols, particularly the color red. Red ink
                has been associated with fraud and fraud detection since the Cold War era.
                Additionally, modern check scanners use a red laser for scanning, making it blind to
                the red ink from your pen.
                <br /> <br />
                For extra security, you can use{' '}
                <a href="https://intuitmarket.intuit.com/supplies/security-pens">
                  a pen that has specially formatted ink designed to soak into the check and resist
                  check washing and alteration
                </a>
                .
                <br /> <br />
                <p className='font-size-20px'>
                  <span className="list-span">Do This Instead:</span> Always use blue or black ink
                  when signing a check.
                </p>
              </span>,
            ],
          },
          {
            heading: '2. “Fixing” mistakes',
            headingSubContent: [
              <span className="headingSubContent">
                Anyone who writes a check has scrawled in the wrong amount or date at some point.
                Before you scratch out the mistake or reach for whiteout, consider what you’re
                correcting. Depending on what you’re fixing and how you’re planning on fixing it,
                your “fixed” check may appear to your bank like one that’s been tampered with.
                <br /> <br />
                If it’s a small correction, cross out the mistake with a single line and write your
                initials next to it. Never use dry correction fluid or tape to cover it up. The best
                course of action? Void he check that has the mistake, shred it, and write a new one.
                <br /> <br />
                <p className='font-size-20px'>
                  <span className="list-span">Do This Instead:</span> Use a new check or correctly
                  notate if mistakes are made.
                </p>
              </span>,
            ],
          },
          {
            heading: '3. Not double-checking your checks',
            headingSubContent: [
              <span className="headingSubContent">
                You’re working fast and feverishly to write all your checks and get them in the
                mail. But, if you’re in a hurry, you could overlook a simple mistake. Is your
                payee’s name spelled right? Is the date correct? What about the amount? Do the
                digits in your check amount match what you’ve written out in words?
                <br /> <br />
                Incorrect, inconsistent, or missing information can cause the bank to reject your
                check outright. Before you put your check into an envelope and mail it, stop and
                take a careful look at it. Catching a simple typo now could save you from a bounced
                check – and expensive bank fees - later.
                <br /> <br />
                <p className='font-size-20px'>
                  <span className="list-span">Do This Instead:</span> Before sending a check, check
                  the spelling, amounts and signatures.
                </p>
              </span>,
            ],
          },
          {
            heading: '4. Postdating business checks',
            headingSubContent: [
              <span className="headingSubContent">
                While putting a future date on a check (a.k.a. “postdating”) is generally legal in
                the United States, you should check your local and state laws to make sure it’s
                legal for you. But, even if it’s legal in your area, that doesn’t mean your bank
                will accept a check with a future date on it.
                <br /> <br />
                Many banks have policies that prohibit this practice and will reject checks with a
                date that’s down the road. Rather than postdating checks, write them on the day
                they’re needed, not before.
                <br /> <br />
                <p className='font-size-20px'>
                  <span className="list-span">Do This Instead:</span> Never postdate checks.
                </p>
              </span>,
            ],
          },
          {
            heading: '5. Inconsistent signatures',
            headingSubContent: [
              <span className="headingSubContent">
                Whether you use a signature stamp or hand sign your checks, a signature is an easy
                thing to overlook when you’re writing a check. Checks without the proper signatures
                are not considered negotiable by most banks. If it’s not signed correctly, your
                check might bounce. Always make sure you use a consistent signature – and never
                initial checks to sign them. If your checks need two signatures, make sure both
                signatures are present. One signature won’t do.
                <br /> <br />
                <p className='font-size-20px'>
                  <span className="list-span">Do This Instead:</span> Use the same signature stamp,
                  signature image file, or signature on every check written.
                </p>
              </span>,
            ],
          },
          {
            heading: '6. Using checks with minimal security',
            headingSubContent: [
              <span className="headingSubContent">
                Checks with effective security features in the ink, paper, and printing methods help
                protect you from the risk of check fraud. And, while they may cost more, the extra
                cost is minimal compared to the amount of money at risk by using cheap checks with
                basic security.
                <br /> <br />
                <p className='font-size-20px'>
                  <span className="list-span">Do This Instead:</span> Only use{' '}
                  <a href="https://intuitmarket.intuit.com/resources/fraud-prevention/security-check-features">
                    business checks with fraud preventing security features
                  </a>
                  .
                </p>
              </span>,
            ],
          },
          {
            heading: '7. Unsecured check storage',
            headingSubContent: [
              <span className="headingSubContent">
                In general, you should treat paper checks the same way you treat cash: store them in
                a secure location that limits access to them. You should also{' '}
                <a href="https://intuitmarket.intuit.com/resources/fraud-prevention/how-to-dispose-of-checks">
                  shred or destroy cashed and voided checks
                </a>
                . One of the easiest ways for thieves to cash fraudulent checks from your business
                is by having access to blank or voided checks.
                <br /> <br />
                Consider keeping your checks somewhere that only you and the employees who need to
                know will have access to. Places like locked desk drawers, small lockboxes, and
                safes are the most common choices.
                <br /> <br />
                <p className='font-size-20px'>
                  <span className="list-span">Do This Instead:</span> Always store your checks in a
                  secure location, ideally somewhere that you can lock.
                </p>
              </span>,
            ],
          },
          {
            para: (
              <p className="padding-bottom-5px">
                <p className="font-size-20px list-span">Read more:</p>
                <p className="font-size-20px list-style-none">
                  <li>
                    <a href="https://intuitmarket.intuit.com/resources/checks/endorsements">
                      Business Check Endorsements: Types & Restrictions
                    </a>
                  </li>
                  <li>
                    <a href="https://intuitmarket.intuit.com/resources/fraud-prevention/5-things-to-look-for">
                      5 Essential Tips on Check Security
                    </a>
                  </li>
                  <li>
                    <a href="https://intuitmarket.intuit.com/resources/fraud-prevention/security-check-features">
                      The Big Three in Check Security: Paper, Ink, and Printing
                    </a>
                  </li>
                </p>
              </p>
            ),
          },
        ],
      },
    },
    taxForms: {
      w2_w3: {
        title: 'Get to Know IRS Tax Form W-2',
        smallHeader: 'TAX FORMS',
        description: (
          <span>
            If you have employees, you’re required to report their wages and tax withholdings each
            year to the IRS. The W-2 is how you report an individual employee’s total annual wages.{' '}
            <br /> <br />
            Making sure you’ve filled out this form correctly and filed it on time for each of your
            employees is critical. We’ve gathered some important resources below to help you make
            sure you don’t miss a thing.
          </span>
        ),
        Topic: [
          {
            heading: <span>Learn about Form W-2</span>,
            subHeadings: [
              {
                subHeading: (
                  <span>
                    <a
                      href="https://quickbooks.intuit.com/r/payroll/w2-form-explained/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      An Interactive Guide to Form W-2
                    </a>
                  </span>
                ),
                subHeadingDetails: (
                  <span>
                    Our interactive guide will walk you through the entire W-2 form, offering
                    explanations and tips about each of the fields in the standard form.
                  </span>
                ),
              },
              {
                subHeading: (
                  <span>
                    <a
                      href="https://quickbooks.intuit.com/learn-support/en-us/help-article/payroll-additions-deductions/understand-wage-amounts-different-employee-w-2/L8MguisX6_US_en_US"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="font-19-8"
                    >
                      Understanding Why Wage Amounts are Different on Your Employee’s W-2
                    </a>
                  </span>
                ),
                subHeadingDetails: (
                  <span>
                    In this QuickBooks support article, learn about things that affect the amounts
                    in Boxes 1, 3, and 5 on your employees’ W-2s with some common payroll examples.
                  </span>
                ),
              },
              {
                subHeading: (
                  <span>
                    <a
                      href="https://quickbooks.intuit.com/r/payroll/w2-form-explained/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Get Answers to Your W-2 Questions{' '}
                    </a>
                    <span className="font-wgt-500">(video)</span>
                  </span>
                ),
                subHeadingDetails: (
                  <span>
                    Check out our helpful W-2 webinars for answers to common questions about the
                    form, from filing them in QuickBooks to fixing mistakes.
                  </span>
                ),
              },
              {
                subHeading: (
                  <span>
                    <a
                      href="https://quickbooks.intuit.com/learn-support/en-us/help-article/print-file/print-w-2-forms/L76UaJUti_US_en_US"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Printing W-2 Forms from QuickBooks Payroll{' '}
                    </a>
                  </span>
                ),
                subHeadingDetails: (
                  <span>
                    Learn all about printing W-2s from your version of QuickBooks Payroll with these
                    step-by-step guides.
                  </span>
                ),
              },
            ],
          },
        ],
      },
      taxForms1099_1096: {
        title: 'Get to Know IRS Tax Forms 1099 and 1096',
        smallHeader: 'TAX FORMS',
        description: (
          <span>
            Have part-time workers or contractors you’ve paid more than $600 this year? You’ll need
            to send them a 1099 form. Additionally, you’ll need to prepare a 1096 form to summarize
            the information you’re sending to the IRS.
            <br /> <br />
            If you’re not familiar with these forms, don’t worry. We’ve assembled some valuable
            resources to help you get to know these important IRS documents.
          </span>
        ),
        Topic: [
          {
            heading: <span>Learn About 1099-NEC & 1099-MISC Forms</span>,
            subHeadings: [
              {
                subHeading: (
                  <span>
                    <a
                      href="https://www.irs.gov/forms-pubs/about-form-1099-misc"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      About Form 1099-MISC, Miscellaneous Income
                    </a>
                  </span>
                ),
              },
              {
                subHeading: (
                  <span>
                    <a
                      href="https://www.irs.gov/forms-pubs/about-form-1099-nec"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      About Form 1099-NEC, Nonemployee Compensation
                    </a>
                  </span>
                ),
                subHeadingDetails: (
                  <span>
                    Detailed information from the IRS for Forms 1099-MISC and 1099-NEC, including
                    filing dates and other important reminders, as well as box-by-box instructions
                    to help you accurately complete the two forms.
                  </span>
                ),
              },
              {
                subHeading: (
                  <span>
                    <a
                      href="https://quickbooks.intuit.com/learn-support/en-us/help-article/form-1099-misc/need-file-1099-forms-state/L4kgvl9Tb_US_en_US"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      1099 Requirements By State
                    </a>
                  </span>
                ),
                subHeadingDetails: (
                  <span>
                    Not sure if your state requires you to file a 1099? Our support article lists
                    all the states that require the form and includes links to their specific 1099
                    instructions.
                  </span>
                ),
              },
              {
                subHeading: (
                  <span>
                    <a
                      href="https://quickbooks.intuit.com/r/taxes/how-to-fill-out-a-1099-form/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Handle Your 1099 Forms For Contractors Like A Pro
                    </a>
                  </span>
                ),
                subHeadingDetails: (
                  <span>
                    Nonemployee compensation needs to be accounted for the company, the contractors,
                    the IRS and, depending on where you live, the state. Know the specific
                    responsibilities and tips for preparing a 1099 like a pro.
                  </span>
                ),
              },
              {
                subHeading: (
                  <span>
                    <a
                      href="https://quickbooks.intuit.com/learn-support/en-us/help-article/form-1099-nec/create-file-1099s-using-quickbooks-online/L2BapEpb1_US_en_US"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Create and file 1099s using QuickBooks Online{' '}
                    </a>
                  </span>
                ),
                subHeadingDetails: (
                  <span>
                    Learn how to prepare your Form 1099s using QuickBooks Online with our guided
                    how-to video or our step-by-step instructions.
                  </span>
                ),
              },
              {
                subHeading: (
                  <span>
                    <a
                      href="https://quickbooks.intuit.com/learn-support/en-us/help-article/printers-printing/print-1099-forms/L2aynlbIc_US_en_US"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Print Your 1099 Forms{' '}
                    </a>
                  </span>
                ),
                subHeadingDetails: (
                  <span>Learn how to print your Form 1099s you’ve prepared using QuickBooks. </span>
                ),
              },
            ],
          },
          {
            heading: <span>Learn About Form 1096</span>,
            subHeadings: [
              {
                subHeading: (
                  <span>
                    <a
                      href="https://www.irs.gov/forms-pubs/about-form-1096"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      About Form 1096, Annual Summary and Transmittal of U.S. Information Returns{' '}
                    </a>
                  </span>
                ),
                subHeadingDetails: (
                  <span>
                    Detailed information from the IRS for Form 1096, including filing dates and
                    other important reminders, as well as box-by-box instructions.
                  </span>
                ),
              },
              {
                subHeading: (
                  <span>
                    <a
                      href="https://quickbooks.intuit.com/learn-support/en-us/help-article/printers-printing/print-1099-forms/L2aynlbIc_US_en_US"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      How To Print 1096 Forms In QuickBooks
                    </a>
                  </span>
                ),
                subHeadingDetails: (
                  <span>
                    Print 1096 Forms from QuickBooks <br />
                    Get step-by-step instructions for printing 1096 forms from QuickBooks.
                  </span>
                ),
              },
            ],
          },
        ],
      },
    },
    businessFraud: {
      TenWaysStopScam: {
        title: '10 Ways Your Employees Can Help You Fight Fraud',
        smallHeader: 'BUSINESS FRAUD PREVENTION',
        description: (
          <span>
            Small businesses are big targets for fraud. With fewer resources than larger businesses,
            they have fewer safeguards in place to protect against identity theft and data breaches.
            Cybercriminals are waiting outside the proverbial door to steal confidential financial
            data and more. And guess who lets them in?
            <br />
            <br />
            Employees. At least most of the time. Employee negligence is the biggest information
            security risk for small businesses. That’s not a judgment; it’s simply a fact. Employees
            use so many devices – desktop computers, laptops, tablets, phones – that it’s all too
            easy to unwittingly give out information. It’s easy to get distracted and not see a scam
            lurking right under their noses. All it takes is the wrong tap or click on the wrong
            link and boom! Malware starts uploading your most sensitive business information to a
            criminal and suddenly, you’re the victim of a breach.
            <br />
            <br />
            Luckily, there is something you can do about it: education and preparation. The more you
            learn about the threats out there and the best practices to avoid letting the wolves in
            the door, the better your chances of not becoming a victim yourself.
          </span>
        ),
        Topic: [
          {
            noBottomMargin: true,
            para: (
              <span>
                Here are 10 common sense tips to avoid getting scammed that you can share with your
                employees:
              </span>
            ),
            subHeadings: [
              {
                subHeading: <span>1. &nbsp; Don’t trust anyone you don’t know personally.</span>,
                subHeadingDetails: (
                  <span>
                    This sounds like something you learned in an after-school special when you were
                    5, but it’s worth repeating in the workplace. Scammers pretend to be someone you
                    trust. They make it sound like they’re connected with a partner, vendor, or
                    government agency you know.
                  </span>
                ),
              },
              {
                subHeading: (
                  <span>2. &nbsp; Don’t make quick decisions because someone urges you.</span>
                ),
                subHeadingDetails: (
                  <span>
                    Scammers will create a sense of urgency to get you to quickly give them
                    something they want. For example, they might pose as your utility company
                    contacting you with threats of shutting off your power if you don’t immediately
                    give them a credit card number to pay a bill. Don’t do it. Take the time to
                    double check who you’re communicating with and make sure they’re legit.
                  </span>
                ),
              },
              {
                subHeading: <span>3. &nbsp; Don’t break company protocol to pay someone.</span>,
                subHeadingDetails: (
                  <span>
                    Scammers want a quick score. They often ask for payment through wire transfers,
                    reloadable cash cards, or gift cards. Don’t fall for it. Follow your company
                    procedures on payment. If you don’t have any in place, now is the perfect time
                    to create them. For instance, only pay with an invoice, always confirm a bill is
                    legitimate by calling the source through a verified number, never pay with cash
                    cards, and never pay with a wire transfer unless you are in communication with
                    all parties involved, including the bank.
                  </span>
                ),
              },
              {
                subHeading: (
                  <span>
                    4. &nbsp; Never give out confidential information to someone calling you.
                  </span>
                ),
                subHeadingDetails: (
                  <span>
                    If someone calls claiming they need your bank routing number to pay a bill, get
                    that person’s information and hang up. Then look up their company phone number
                    from a verified source – like an old bill – and call them directly to inquire if
                    anything is really needed. And, of course, do the same thing if the request came
                    via email.
                  </span>
                ),
              },
              {
                subHeading: <span>5. &nbsp; Fight phishing scams.</span>,
                subHeadingDetails: (
                  <span>
                    Phishing scams are the number one means of getting employees to give up
                    sensitive company information. They look like legitimate emails that lead you to
                    a website appearing to be an exact replica of a site you use all the time.
                    Before you know it, you’re entering bank information, credit card numbers, or
                    passwords, and a criminal has been able to steal it. Instead of giving in to
                    that request, delete the email. Call the company through a trusted phone number
                    you know and ask if you need to do anything.
                  </span>
                ),
              },
              {
                subHeading: <span>6. &nbsp; Be password smart.</span>,
                subHeadingDetails: (
                  <span>
                    Never send passwords via email. If you need to convey a password or other piece
                    of critical information to someone, do it over the phone or, better yet, in
                    person. While you’re at it, don’t leave Post-It notes laying around with
                    passwords on them. Create strong, long passwords with special characters,
                    numbers, and upper and lowercase letters. And change them on a regular basis.
                    Use a password manager to create strong passwords and store them for you.
                  </span>
                ),
              },
              {
                subHeading: <span>7. &nbsp; Watch out for fake invoices and checks.</span>,
                subHeadingDetails: (
                  <span>
                    Scammers may send you invoices that appear legit – maybe for additional cleaning
                    supplies you actually use, or for extra printer paper. They hope you’ll pay
                    first and ask questions later. Ask questions first instead. Scammers may also
                    try “overpaying” you with a check and then calling to ask you to refund the
                    difference. By the time the bank notifies you that you’ve deposited a bad check,
                    the scammer already has the money you sent them.
                  </span>
                ),
              },
              {
                subHeading: <span>8. &nbsp; Shred everything.</span>,
                subHeadingDetails: (
                  <span>
                    We’ve focused a lot on digital fraud in this article, but there’s an
                    old-fashioned way a crook can get your sensitive business information. It’s
                    called “rummaging through the trash.” If you’re a paper-heavy company, shred
                    everything and dispose of documents securely.{' '}
                  </span>
                ),
              },
              {
                subHeading: <span>9. &nbsp; Keep an eye out for intruders.</span>,
                subHeadingDetails: (
                  <span>
                    If you work in a small business, chances are good you know most of the people
                    around you. If someone shows up who you don’t recognize, put the alert out. Call
                    security. Call someone. That computer technician could be about to walk out the
                    door with a laptop that has access to all your company data.
                  </span>
                ),
              },
              {
                subHeading: <span>10. &nbsp; Spread the word if you spot a scam.</span>,
                subHeadingDetails: (
                  <span>
                    Don’t be embarrassed, and don’t be quiet about it. If you were targeted, chances
                    are someone else was targeted too. Scammers don’t discriminate when it comes to
                    trying to get information or money.
                  </span>
                ),
              },
            ],
          },
          {
            para: (
              <span>
                Remember, securing your business should be a team effort. Management has to help
                educate about data security risks, and in turn employees have to look out for each
                other. The more you work together and share best practices, the more you can fight
                fraud.
              </span>
            ),
          },
        ],
      },
      sixScamToAvoid: {
        title: '6 Scams Targeting Small Businesses and How To Avoid Them',
        smallHeader: 'BUSINESS FRAUD PREVENTION',
        description: (
          <span>
            If you run a small business, know that thieves are out there waiting for opportunities
            to strike. In fact, 58% of data breach victims are small businesses<sup>1</sup> and
            business identity theft jumped 250% in 2017.<sup>2</sup> Small businesses are
            increasingly falling victim to scams, so it pays to keep up your guard.
          </span>
        ),
        descriptionBottomMargin: 'margin-bottom-40px',
        Topic: [
          {
            para: (
              <span>
                Here are some common scams to watch out for – and what you can do to avoid them:
                <br />
                <br />
                <span className="font-wgt-600">
                  Criminals set themselves up as the owner of your business
                </span>{' '}
                <br />
                Criminals can sell off your business assets, make purchases in your business’ name,
                and open or access bank accounts, loans, or lines of credit. All it takes is filing
                a false business registration form with your state. For the minimal cost of a filing
                fee – usually not more than $20 – a thief can file a fraudulent change of business
                address form for your company and file to change business officers or directors.
                Most states are good faith filing states. As long as basic filing requirements are
                met, they accept the information filed about a business at face value. <br /> <br />
                <span className="font-wgt-600">How to avoid it: </span>Vigilance is key. Regularly
                review your state business filings for any changes you did not make. Enroll in any
                email alerts or other notifications your state may offer.
              </span>
            ),
            bottomPadding: 'padding-bottom-8px',
          },
          {
            para: (
              <span>
                <span className="font-wgt-600">
                  Fake emails trick you into divulging sensitive business info{' '}
                </span>{' '}
                <br />
                You may receive a bogus email that appears to come from your bank announcing your
                account has been compromised. Click on a link and you’ll be taken to a site that
                looks just like the real thing. When you enter your bank information, credit card
                numbers, or passwords, the thief steals it. It’s called phishing. Phishing emails
                look legitimate, but they’re designed to rob you of valuable information that could
                compromise your business. <br /> <br />
                <span className="font-wgt-600">How to avoid it: </span>
                Make sure your company’s firewalls and anti-virus software are up to date. If you
                have employees, train them on how to handle emails asking for confidential
                information, including spotting clues to fake emails or websites. Rather than
                clicking on a link or opening an attachment, call the institution requesting
                information from a phone number you know to be legitimate (not the one in the email)
                and validate the request. If you do fall victim to this scam, your information might
                end up for sale on the Dark Web without you knowing about it. A Dark Web Monitoring
                service, like QuickBooks Detect & Defend, scans thousands of websites, chat rooms
                and forums for your sensitive information.
              </span>
            ),
            bottomPadding: 'padding-bottom-8px',
          },
          {
            para: (
              <span>
                <span className="font-wgt-600">Criminals impersonate your employees </span> <br />
                Criminals can mimic, hack, or duplicate email addresses of employees within your
                company. They send an email requesting confidential information from you or other
                employees. Anyone duped into believing the email is legitimate may turn over
                financial information and other vital information about your business. This can lead
                to a fraudulent takeover over of your financial accounts.
                <br /> <br />
                <span className="font-wgt-600">How to avoid it: </span>
                Make it a policy to verbally confirm requests for financial information like W-2s or
                bank routing numbers. Never wire money blindly. Check with the requestor directly –
                via the phone or in person. Ask to see invoices. Monitor bank accounts daily to make
                sure no unauthorized transfers are being made. Sign up for anti-fraud alerts if your
                bank offers them. Check out business identify theft and fraud monitoring services,
                like QuickBooks Detect & Defend, which often include tools to look for suspicious
                activity for you.
              </span>
            ),
            bottomPadding: 'padding-bottom-8px',
          },
          {
            para: (
              <span>
                <span className="font-wgt-600">
                  Criminals impersonate the IRS to steal your information{' '}
                </span>
                <br />
                The IRS is one of the most feared institutions in America. A contact from them would
                get many people quaking in their boots. That’s why scammers will impersonate the IRS
                and try to intimidate you into paying for a tax bill or giving out sensitive
                business information. <br /> <br />
                <span className="font-wgt-600">How to avoid it: </span>
                Like anything else, always check the source. If an individual claims to be from the
                IRS, call the IRS at 1-800-829-1040 to check on it. The IRS is methodic. Their
                practice is to send letters in the mail, typically not call or email you.
              </span>
            ),
            bottomPadding: 'padding-bottom-8px',
          },
          {
            para: (
              <span>
                <span className="font-wgt-600">
                  Criminals pose as bill collectors from your utility company
                </span>{' '}
                <br />
                Thieves know you can’t run a business without electricity (or other utilities). You
                could get a call claiming your electric bill is overdue. If you don’t pay the bill
                immediately with a credit card, they’ll turn your power off. <br /> <br />
                <span className="font-wgt-600">How to avoid it: </span>
                Don’t panic. A legitimate utility company will send written notices and give you
                several chances to pay. Call the service provider by the number on your most recent
                bill to find out if an issue actually exists.{' '}
              </span>
            ),
            bottomPadding: 'padding-bottom-8px',
          },
          {
            para: (
              <span>
                <span className="font-wgt-600">
                  Criminals pretend to be a contractor, then disappear
                </span>{' '}
                <br />
                In this scam, someone approaches you offering services you may need – anything from
                accounting to IT help – then request a deposit or initial payment before work
                begins. Once you make payment, they disappear. <br /> <br />
                <span className="font-wgt-600">How to avoid it: </span>
                Confirm at least two references before going into business with anyone. Vet and
                crosscheck any prospective contractor’s online presence to spot scammers before you
                sign a contract with them or hand over sensitive information.
              </span>
            ),
            bottomPadding: 'padding-bottom-8px',
          },
          {
            para: (
              <span>
                Criminals have many ways of infiltrating your business. Knowledge is one of your
                best defenses in protecting your business, but it is not foolproof. It’s entirely
                possible to be vigilant and still fall victim to identity theft. In fact, most small
                businesses don’t realize their identity is stolen until their bank accounts are
                emptied, bill collectors come calling or credit is wrecked.
                <ul className="list-style-none">
                  <li className="font-16px">1. Verizon Data Breach Investigations Report, 2018.</li>
                  <li className="font-16px">
                    2. Dornbrook, James. “IRS: Business identity theft cases jump 250% so far in
                    2017,” 2017. Retrieved from:{' '}
                    <a
                      href="https://www.bizjournals.com/kansascity/news/2017/07/31/irs-business-identity-theft-cases-jump-250-so-far.html"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://www.bizjournals.com/kansascity/news/2017/07/31/irs-business-identity-theft-cases-jump-250-so-far.html
                    </a>
                  </li>
                </ul>
              </span>
            ),
          },
        ],
      },
      taxScamAvoid: {
        title: 'Small Business Tax Scams: How to Lower Your Risk',
        smallHeader: 'BUSINESS FRAUD PREVENTION',
        Topic: [
          {
            heading: (
              <span>Help protect your business from tax scams with these helpful tips.</span>
            ),
            para: (
              <span>
                To gain access to your business’ most sensitive information, criminals may use tax
                scams to contact you directly for the information they need. Once they have that
                information, criminals can file a phony tax return in your business’s name to steal
                the refund. When you go to file, the IRS will reject your tax return because they’ve
                already received one in your business’ name.
                <br /> <br />
                Criminals can also use your information to file fake personal tax returns to collect
                a refund. They cite your business as their employer by using your EIN on a fake W-2
                form they created. Later when the IRS matches their W-2 with your payroll filings,
                they find a gap in tax withholdings you “paid.” The IRS could hold your company
                liable for those withholdings that the criminal reported.
                <br /> <br />
                By learning how to spot the tax scams criminals like to use, you can help prevent
                your business from becoming a tax fraud victim.
              </span>
            ),
          },
          {
            heading: <span>Common Business IRS Tax Scams</span>,
            para: (
              <span>
                While scammers and fraudsters methods continue to evolve alongside the efforts to
                stop them, there are a few common business tax scams that you can be on the lookout
                for as a business owner.
                <br /> <br />
                <ul className="clear-margin">
                  <li>Phishing - Using email communication to impersonate the IRS</li>
                  <li>Spoofing - Using the telephone to impersonate the IRS</li>
                  <li>
                    Fake Accountants - Using false credentials to get access to IRS information{' '}
                  </li>
                </ul>
              </span>
            ),
          },
          {
            heading: <span>IRS phishing scams</span>,
            para: (
              <span>
                You may receive an email that appears to originate from the IRS asking you for your
                business’ sensitive information. Keep in mind, the IRS will not contact your
                business via email. If you receive an email that appears to be from the IRS, never
                open it.
              </span>
            ),
          },
          {
            heading: <span>IRS spoof phone scams</span>,
            para: (
              <span>
                If you receive a call from the IRS with threats of arrest, deportation, or license
                revocation, it’s a scam. Remember, the IRS will never call your business for
                tax-related information. Always be vigilant with your business’ information and be
                extremely wary of giving it away over the phone.
              </span>
            ),
          },
          {
            heading: <span>Tax return preparer scam</span>,
            para: (
              <span>
                When working with a tax preparer, make sure his or her credentials are legitimate.
                Criminals can masquerade as tax preparers and use your business’ information to
                commit fraud in the name of your business. <br /> <br />
                Before trusting your business’ information to anyone, verify their CPA status online
                at the American Institute of Certified Public Accountants (AICPA).
              </span>
            ),
          },
          {
            heading: <span>Tips for protecting your business from tax scams:</span>,
            para: (
              <span>
                <ul className="clear-margin">
                  <li>
                    Check out the dirty dozen tax scams – an annual list the IRS publishes that
                    outlines the latest tax scams.
                  </li>
                  <li>Train your employees how to spot and avoid tax scams.</li>
                  <li>Sign up for a business identity theft protection service.</li>
                </ul>
              </span>
            ),
          },
        ],
      },
    },
    checkFraud: {
      counterFeiting: {
        title: 'What Is Check Fraud & Why It Matters to Your Business',
        smallHeader: 'CHECK FRAUD PREVENTION',
        description: (
          <span>
            If you’re using checks to pay your vendors or employees like most businesses, you should
            understand how check fraud can pose serious risk for you. <br /> <br />
            Check fraud is a type of payment fraud that often involves someone intercepting your
            check and changing its information or creating fake checks using your check information.
            Modern tech makes it simple for criminals to scan a check, digitally alter its image,
            and then print it. Fortunately, high-security check features make this kind of fraud
            nearly impossible to pull off.
          </span>
        ),
        Topic: [
          {
            heading: <span>What is a “fake check”? </span>,
            para: (
              <span>
                Any check created or altered without the permission of the bank account owner is
                considered a fake check. The more security features your checks offer, like
                tamper-resistant ink and controlled paper stock, the harder they become to fake and
                the easier they become to spot as fakes.{' '}
                <a href="/secure-products">QuickBooks Secure Checks</a> have security features
                designed to help stop many of the common faking techniques, considerably reducing
                your risk of this happening to you.
              </span>
            ),
          },
          {
            heading: <span>What is check washing?</span>,
            para: (
              <span>
                Check washing, or tampering, involves altering the information on the check, usually
                the payee and amount. This can be done by “washing” or scraping the print off your
                check. To help prevent this, we’ve added features that make it apparent when someone
                has attempted to alter the check.
              </span>
            ),
          },
          {
            heading: <span>Extra protection around the most critical areas</span>,
            para: (
              <span>
                With our Secure Plus and Secure Premier checks, we feature exclusive
                tamper-resistant areas around the payee and check amount. Any attempts to alter that
                information shows up instantly.
              </span>
            ),
          },
          {
            heading: <span>Comes with a built-in security system</span>,
            para: (
              <span>
                If someone tries to use chemicals to alter your check information QuickBooks secure
                checks' security coating kicks in, showing stains, spots, or discoloration where
                changes were made.
              </span>
            ),
            quote: `"Forgers use many common household chemicals to remove, or wash ink from a check. Chemically-reactive paper has been treated to react to those chemicals, making it obvious when someone tries to alter a check."`,
            author: '- Frank Abagnale, Secure Document Consultant',
          },
          {
            heading: <span>What is check counterfeiting?</span>,
            para: (
              <span>
                Counterfeiting involves duplicating your check by photocopying or forgery. Our
                secure checks include powerful security features that make counterfeiting nearly
                impossible.
              </span>
            ),
          },
          {
            heading: <span>Passport-level protection for your checks</span>,
            para: (
              <span>
                A security hologram on our Secure Premier Checks makes your check virtually
                impossible to counterfeit and makes spotting fake checks easier. <br />
                Exclusive, controlled paper stock <br /> <br />
                Our checks use a paper stock that can't be bought in a store or online. This helps
                to make sure no one can fraudulently duplicate and or cash fake checks that your
                business is liable for.
              </span>
            ),
            quote: `"If your check stock can be bought elsewhere, then your checks can be replicated, no matter how many security features they have. By using unique check stock, you're going a long way to prevent someone from duplicating your checks."`,
            author: '- Frank Abagnale, Secure Document Consultant',
          },
          {
            heading: <span>A watermark to announce your check's authentic</span>,
            para: (
              <span>
                Hold our checks up to the light and you'll see our exclusive, custom-designed
                CheckLock™ Custom True Watermark - an instant way to confirm they're genuine.
              </span>
            ),
          },
        ],
      },
      fiveThingsToLookFor: {
        title: '5 Essential Tips on Check Security ',
        smallHeader: 'CHECK FRAUD PREVENTION',
        description: (
          <span>
            If you’re like most business owners, you’ve probably taken steps to help safeguard your
            business checking account from fraud. You’ve created a strong password for your online
            banking. You only log in to do your banking from secure, trusted devices. And you
            regularly monitor your account for suspicious activity. (By the way, if you’re not doing
            those things, start doing them immediately!)
            <br /> <br />
            But don’t forget, your business checking account has a paper component as well and it’s
            every bit as important to keep secure. If you’re not careful, your physical checks can
            become a fraudster’s key to unlocking your finances, leaving you to spend months, maybe
            even years, recouping your losses.
            <br /> <br />
            However, if you follow these 5 essential tips on check security, you’ll go a long way
            toward keeping criminals from taking advantage of your paper checks.
          </span>
        ),
        Topic: [
          {
            heading: <span>1. Lock up your checks.</span>,
            para: (
              <span>
                Criminals can only commit fraud with your checks if they can get their hands on
                them. Leaving checks out in the open and unattended leaves them – and you – open to
                theft. Always keep business checks under lock and key until you’re ready to use
                them. If you have employees with access to your checks, especially those who close
                your office, train them to lock up your business checks and to make sure they’re
                secure before leaving for the evening.
              </span>
            ),
          },
          {
            heading: <span>2. Shred old checks.</span>,
            para: (
              <span>
                Your business checks are legal, financial documents that you can’t simply toss in
                the trash once they’re negotiated. They have sensitive information on them that
                criminals find useful – like your business name, address, and account number. <br />
                <br />
                The best way to prevent anyone from using your old checks for identity theft or
                fraud is to shred them. Cross-cut shredders will quickly make confetti out of your
                checks and make them nearly impossible to reassemble in the process. If you don’t
                have a shredder of your own or don’t want to do it yourself, office supply stores
                usually offer shredding services. Your local financial institution likely has a
                powerful shredder on site. They also may be able to shred your business checks for
                you.
              </span>
            ),
          },
          {
            heading: <span>3. Avoid using counter checks.</span>,
            para: (
              <span>
                Most banks keep blank checks in stock that they can magnetically encode with your
                account number. These checks are referred to as “counter checks” or “convenience
                checks.” If you suddenly come to the horrifying realization that you’ve run out of
                business checks, picking up a few counter checks might seem like a good temporary
                solution. <br /> <br />
                However, keep in mind that counter checks come blank. They won’t have your company
                information on them. You’ll need to write all of that in by hand. For that reason,
                merchants may refuse this type of check if you try to use one, thinking it looks
                suspicious.
                <br /> <br />
                Also, counter checks typically offer minimal security features, if any at all. This
                can leave you open to check fraud and financial losses if the wrong person gets a
                hold of your checks and decides to start using them.
                <br /> <br />
                The better solution to counter checks: never run out of your own checks in the first
                place. Most business checks include reminder inserts that let you know when you’re
                getting low. If you haven’t reordered by that point, follow that reminder and put in
                an order right away. That way you’ll always have checks on hand when you need them.
              </span>
            ),
          },
          {
            heading: <span>4. Send your checks securely.</span>,
            para: (
              <span>
                When you send a check by mail, you’re sending a document loaded with sensitive
                information on it. While that value pack of 1,000 envelopes at the store might seem
                inviting, those bargain-priced envelopes could expose information on your check and
                open you up to fraud and theft.
                <br /> <br />
                With secure envelopes sized for your business checks, your checks won’t slide around
                inside the envelopes and show off information in the envelope window that you don’t
                want visible. An inked pattern on the interior can also prevent someone from holding
                up your envelope to a light and catching every detail on your check. <br /> <br />
                Additionally, make sure your check always makes it into the mailbox by walking it
                there yourself. Don’t leave it out and unattended in a mail pick-up basket at your
                office’s front desk for anyone to grab. For added security, take it to the post
                office and mail it return receipt requested. By doing that, you’ll get a tracking
                number, so you can make sure your check has safely arrived at its destination.
              </span>
            ),
          },
          {
            heading: <span>5. Get the right check security features.</span>,
            para: (
              <span>
                You’ve started locking up your checks, shredding your old checks, avoiding counter
                checks, and sending them securely in the mail. Great! But don’t forget that your
                checks themselves can often make the difference in helping you stay safe from fraud.
                The right security features in the paper, ink, and printing used to create your
                checks can help derail attempts to alter or duplicate them.
                <br />
                <br />
                Here are some great security features you’ll want to make sure your checks include:
              </span>
            ),
            subHeadings: [
              {
                subHeading: (
                  <li>
                    {' '}
                    <span className="margin-left--5px">
                      Microcapsule security coating{' '}
                      <span className="font-wgt-400">(prevents alteration)</span>
                    </span>
                  </li>
                ),
                subHeadingDetails: (
                  <span>
                    The check paper includes microcapsules of ink around the payee and amount; if
                    someone tampers with those areas, visible stains appear on the check.
                  </span>
                ),
              },
              {
                subHeading: (
                  <li>
                    <span className="margin-left--5px">
                      {' '}
                      Exclusive, controlled check stock{' '}
                      <span className="font-wgt-400">(prevents duplication)</span>
                    </span>
                  </li>
                ),
                subHeadingDetails: (
                  <span>
                    A premium-grade security paper that isn't available for sale anywhere as blank
                    stock will ensure criminals can’t get the paper right, making their copies of
                    your checks obvious fakes.
                  </span>
                ),
              },
              {
                subHeading: (
                  <li>
                    <span className="margin-left--5px">
                      Chemically reactive paper{' '}
                      <span className="font-wgt-400">(prevents alteration)</span>
                    </span>
                  </li>
                ),
                subHeadingDetails: (
                  <span>
                    This specially-designed paper will create stains, spots, or discoloration on
                    your checks if someone uses chemicals to alter or wash them.
                  </span>
                ),
              },
              {
                subHeading: (
                  <li>
                    <span className="margin-left--5px">
                      Foil hologram <span className="font-wgt-400">(prevents duplication)</span>
                    </span>
                  </li>
                ),
                subHeadingDetails: (
                  <span>
                    Passports and driver’s licenses use this type of security feature. Foil
                    holograms are easy to identify at a glance, but nearly impossible to
                    counterfeit.
                  </span>
                ),
              },
              {
                subHeading: (
                  <li>
                    <span className="margin-left--5px">
                      True watermark <span className="font-wgt-400">(prevents duplication)</span>
                    </span>
                  </li>
                ),
                subHeadingDetails: (
                  <span>
                    Embossed into the paper – as opposed to being printed on with ink - a true
                    watermark will make your business checks extremely difficult to reproduce.
                  </span>
                ),
              },
            ],
          },
        ],
      },
      securityCheckFeature: {
        title: 'The Big Three in Check Security: Paper, Ink, and Printing ',
        smallHeader: 'CHECK FRAUD PREVENTION',
        description: (
          <span>
            While no check can claim to be 100% fraud proof, the right security features can go a
            long way to keep criminals from altering or photocopying your checks and emptying your
            bank account.
            <br /> <br />
            High security checks offer fraud protection for three vital areas of the check:
            <ul>
              <li>The check paper</li>
              <li>The ink used on the check</li>
              <li>The printing and microprinting featured on the check</li>
            </ul>
            QuickBooks secure checks include powerful, built-in security designed to protect these
            critical check components. Their security features make them the most secure checks
            available on the market today.
          </span>
        ),
        noTopicSpace: true,
        Topic: [
          {
            heading: <span>Check paper security features</span>,
            subHeadings: [
              {
                subHeading: <span>Exclusive Controlled Check Stock</span>,
                subHeadingDetails: (
                  <span>
                    To reduce the risk of forgery, we issue our checks in a premium-grade security
                    paper that isn't available for sale anywhere as blank stock.
                  </span>
                ),
              },
              {
                subHeading: <span>Exclusive CheckLock™ Custom True Watermark</span>,
                subHeadingDetails: (
                  <span>
                    When held up to a light, our specially-designed watermark becomes easily visible
                    from either side of the check paper, making authentication instantaneous.
                  </span>
                ),
              },
              {
                subHeading: <span>Exclusive Tamper-Resistant Toner Area</span>,
                subHeadingDetails: (
                  <span>
                    We've designed our checks with white boxes around the payee and amount lines for
                    maximum protection against toner removal, as well as a clean background that
                    makes alteration attempts stand out.
                  </span>
                ),
              },
              {
                subHeading: <span>Chemically-Reactive Paper</span>,
                subHeadingDetails: (
                  <span>
                    Our uniquely-treated paper protects against forgery by creating stains, spots,
                    or discoloration if chemicals are used to alter or wash our checks.
                  </span>
                ),
              },
              {
                subHeading: <span>Paper/UV Dull</span>,
                subHeadingDetails: (
                  <span>
                    By reducing reflectants in the paper and making it “dull” authentication of
                    checks is easier and it hides some of the security features from would-be
                    fraudsters.
                  </span>
                ),
              },
              {
                subHeading: <span>Toner Adhesion</span>,
                subHeadingDetails: (
                  <span>
                    Our checks feature paper specially formulated to securely anchor printer toner,
                    making removal nearly impossible without causing visible damage to the paper.
                  </span>
                ),
              },
              {
                subHeading: <span>Invisible Fluorescent Fibers</span>,
                subHeadingDetails: (
                  <span>
                    Protects against counterfeiting with invisible fluorescent fibers that only
                    appear under UV light which prevents photocopying and aid in authentication.{' '}
                  </span>
                ),
              },
              {
                subHeading: <span>Visible Fibers</span>,
                subHeadingDetails: (
                  <span>
                    Intuit’s check paper stock has visible fibers placed randomly throughout the
                    background making the checks even more copy resistant.
                  </span>
                ),
              },
              {
                subHeading: <span>CheckLock™ Custom Pantograph</span>,
                subHeadingDetails: (
                  <span>
                    Our secure checks include an intricate combination of interwoven spirals forming
                    a complex check background pattern unique to protect against replication.
                  </span>
                ),
              },
            ],
          },
          {
            heading: <span>Check ink security features</span>,
            subHeadings: [
              {
                subHeading: <span>Fraud Sensitive Ink Exposes Forgery Attempts</span>,
                subHeadingDetails: (
                  <span>
                    To expose forgery attempts, our checks use ink that disappears or turns pink if
                    it's chemically altered.
                  </span>
                ),
              },
              {
                subHeading: <span>Exclusive Security Coating</span>,
                subHeadingDetails: (
                  <span>
                    QuickBooks secure checks contain microcapsules of ink around the payee and
                    amount. If someone tampers with those areas, visible stains appear on the check.
                  </span>
                ),
              },
            ],
          },
          {
            heading: <span>Printing and microprinting security features</span>,
            subHeadings: [
              {
                subHeading: <span>Microprinting on front, back and signature lines</span>,
                subHeadingDetails: (
                  <span>
                    To make counterfeiting more difficult, our checks contain the words
                    "INTUIT®CHECKLOCKTMSECURITYSYSTEM" in type too small to read with the naked eye
                    and extremely tough to reproduce using a photocopier or scanner.
                  </span>
                ),
              },
              {
                subHeading: <span>Erasure Evident</span>,
                subHeadingDetails: (
                  <span>
                    A white mark will appear if someone attempts to erase the imprint on the surface
                    of our checks.
                  </span>
                ),
              },
              {
                subHeading: <span>Prismatic Multi-colored Background</span>,
                subHeadingDetails: (
                  <span>
                    Deters check copying by having several color gradations on the check background
                    that most color copiers couldn’t reproduce.
                  </span>
                ),
              },
              {
                subHeading: <span>Security Weave</span>,
                subHeadingDetails: (
                  <span>
                    If the check paper is altered (for example, by check washing) the weave becomes
                    visibly altered exposing the counterfeiting attempt.
                  </span>
                ),
              },
              {
                subHeading: <span>Security Screen</span>,
                subHeadingDetails: (
                  <span>
                    If a would-be fraudster photocopies or scans a check text woven into the
                    security screen will distort greatly or disappear altogether, indicating an
                    attempt at counterfeiting.
                  </span>
                ),
              },
              {
                subHeading: <span>Colored Background</span>,
                subHeadingDetails: (
                  <span>
                    Just as the invisible and visible fibers make it difficult or impossible to use
                    common copying techniques, our detailed colored background helps deter check
                    copying in the first place.
                  </span>
                ),
              },
              {
                subHeading: <span>Padlock Icon</span>,
                subHeadingDetails: (
                  <span>Verifies that QuickBooks checks meet industry standards of security.</span>
                ),
              },
              {
                subHeading: <span>Intuit CheckLock™ Border</span>,
                subHeadingDetails: (
                  <span>
                    Helps in check authentication at the point of deposit or upon further
                    inspection.
                  </span>
                ),
              },
              {
                subHeading: <span>Warning Bands and Boxes</span>,
                subHeadingDetails: (
                  <span>
                    A warning at the top “CASH ONLY IF ALL Checklock™ SECURITY FEATURES LISTED ON
                    BACK INDICATE NO TAMPERING AND OR COPYING” with an accompanying list of security
                    features on the back.
                  </span>
                ),
              },
              {
                subHeading: <span>Void Indication</span>,
                subHeadingDetails: (
                  <span>
                    If the check is copied or scanned, the word "VOID" appears in the warning box on
                    the back.
                  </span>
                ),
              },
              {
                subHeading: <span>Thermochromic Heat Sensitive Icon</span>,
                subHeadingDetails: (
                  <span>
                    For fast and easy authentication, our checks include a verification icon that
                    disappears when warmed by the touch of a finger or breath.
                  </span>
                ),
              },
              {
                subHeading: <span>Security Hologram</span>,
                subHeadingDetails: (
                  <span>
                    Similar to those found on passports and drivers licenses, our security hologram
                    is easy to identify at a glance but nearly impossible to counterfeit.
                  </span>
                ),
              },
            ],
          },
        ],
      },
      itCanHappenToYou: {
        title: 'Check Fraud Case Study: Learn How Check Fraud Hit A Small Business Accountant.',
        smallHeader: 'CHECK FRAUD PREVENTION',
        description: (
          <span>
            Carole Maddux knows numbers: She's been crunching them for small businesses for more
            than 30 years. After 11 years in the accounting department at General Motors, Maddux
            founded Account Ability Accounting & Consulting Service, in part so she could work at
            home while starting her family. <br /> <br />
            While balancing the books of her 30-some clients has become second nature to a seasoned
            professional like Maddux, she had an encounter with one of the dark sides of running a
            business that took her by surprise: Check and payments fraud. <br /> <br />
            Check fraud might mean different things to different businesses. It could involve a
            customer passing off a bad or phony check to pay for goods and services. It could also
            involve any of the vast numbers of online cons that attempt to get consumers and
            businesses alike to part with their hard-earned cash. Check fraud happens to businesses
            of all shapes and sizes. Thieves just need to find a flaw in a company's financial
            protocols to exploit. <br /> <br />
            In Maddux's case, it involved a criminal cashing counterfeit checks drawn on one of her
            client's accounts - complete with Maddux's forged signature. (Maddux pays the client's
            bills and has check-signing authority on its account.) <br /> <br />
            Last June, she got a call from a bank teller at a Chase branch in Georgia. Red flag
            number one: Maddux and her client are both based in Michigan. The teller had called
            because a man was trying to deposit a check made out to him, supposedly signed by her.
            Red flag number two was a biggie: The fraudster had misspelled the company's name.
            Maddux says the business goes by a common household name. The typo tipped the teller
            that something was wrong. <br /> <br />
            While Maddux was on the phone, a teller at a different Chase branch in Georgia called
            her with a similar concern - another scammer trying to cash what also turned out to be a
            fake check bearing Maddux's John Hancock. <br /> <br />
            "My signature was forged on the check," Maddux says. "In some cases, it looked exactly
            like my signature. They had obviously seen my signature. In other cases, it didn't look
            anything like mine." <br />
            <br />
            After checking on the company's account online, Maddux realized that counterfeiters had
            successfully cashed nine checks totaling roughly $15,000. Maddux had to complete
            paperwork with the bank and file a police report to recoup her client's money. Chase
            reimbursed the stolen money after two days, Maddux says. Keep in mind, your bank's
            policies will ultimately determine if you can recoup any stolen funds. Don’t take it as
            a given that your bank will automatically reimburse you. Or that they'll call the
            authorities when the fraud’s occurring. According to Maddux, she was upset to learn the
            bank didn't call the police and let both Georgia fraudsters go free. <br /> <br />
            To this day, Maddux hasn't been able to figure out how the fraud happened, or how one of
            her client's checks signed by her ended up in a crook's hands. At least one did because
            it became the basis for the nine fake, but successfully cashed checks. It could have
            been anyone, Maddux says. Still, some common-sense prevention tips can be drawn from her
            experience: <br /> <br />
            First, be aware that this kind of thing can happen, and it can happen to anyone. <br />{' '}
            <br />
            Second, when paying with a check, only use the most secure checks available. They can
            considerably reduce your risk of fraud and forgery. Always keep your checks safe and
            shred old ones rather than just dumping them in the trash. <br /> <br />
            Third, no matter your preferred payment method, be vigilant about reviewing all your
            accounts for unfamiliar payments or other suspicious activity. If something looks out of
            place, act fast. Call your bank immediately. <br /> <br />
            Also lucky was that the client had plenty of cash on hand. The short-term theft did not
            cause them to bounce legitimate checks or make any late payments. This type of fraud
            could have easily decimated a business operating with a tighter cash flow. That business
            could miss payroll or bounce a tax payment and incur heavy penalties as a result. <br />{' '}
            <br />
            Being cash-rich could have a downside in a fraud situation, though: Had the
            counterfeiters been better spellers, "they could have kept writing checks until the cows
            came home, because there was a lot of money in that account," Maddux says, noting the
            fraudulent checks may not have been caught until the client's monthly banking
            reconciliation. "This could have gone on had an alert teller not noticed it." <br />{' '}
            <br />
            <a href="/resources/fraud-prevention/5-things-to-look-for">
              Discover more on how to avoid check fraud
            </a>
          </span>
        ),
        descriptionBottomMargin: 'margin-bottom-70px',
      },
      howToDisposeOfChecks: {
        title: 'How to Properly Dispose of Business Checks',
        smallHeader: 'CHECK FRAUD PREVENTION',
        description: (
          <span>
            Whether you’ve changed your business address, closed a business checking account, or
            ordered a new style of business checks, there are a lot of reasons you might have old or
            outdated checks lying around your office. Those checks may have old information on them,
            but they still present a significant fraud risk to you if they make it into the wrong
            hands. Canceled checks, old check registers, and check stubs can be equally as risky to
            keep around. These documents may include proprietary information, trade secrets, and
            sensitive information about your vendors and customers.
            <br /> <br />
            So, what to do with these checks and documents you no longer want to hold onto? Throw
            them out and just hope no one finds them in a dumpster? Not so fast. The better plan is
            to dispose of them in a way that ensures that if they’re found, they can’t ever be used
            – or used against you. <br /> <br />
            By choosing one of these disposal options, you can help make sure your financial and
            business information remains safe and secure.
          </span>
        ),
        noTopicSpace: true,
        Topic: [
          {
            heading: 'Shred',
            para: (
              <span>
                Cutting up old checks into confetti is one of the best and most effective ways to
                destroy them, but it can be cumbersome if you do it with a pair of scissors.
                However, an office paper shredder can make fast work of destroying a small quantity
                of business checks. If you use a shredder, you’ll want to make sure it cuts both
                vertically and horizontally. When you’re finished, dispose of the shredding in a
                secure, non-public garbage drop off. <br /> <br /> For larger quantities, consider
                renting an industrial shredder. Check your area for mobile shredding options. They
                can be scheduled ahead of time. Often, they’ll come to your office or business. They
                can even shred your old documents right in front of you, so you know it’s done
                securely. <br /> <br />
                Most big office supply stores also offer shredding services. You can schedule a
                one-time pickup of your checks and documents, or schedule weekly, bi-weekly, or
                monthly pickups, depending on how many documents you need to shred. Expect to pay
                for shredding by the pound or by the legal box.
              </span>
            ),
            bottomPadding: 'padding-bottom-0px',
          },
          {
            heading: 'Bleach',
            para: (
              <span>
                If you don’t have access to a shredder or shredding services, another option is to
                bleach your business checks. This is not to be confused with check washing, a method
                that fraudsters use to selectively remove information from your checks to commit
                fraud. In this case, you’ll be destroying them entirely.
                <br /> <br /> To bleach your business checks, add some water and bleach to a large
                bucket, chuck in your documents, then stir it up. Let it sit for a bit. The bleach
                will go to work on the ink and remove it from the documents, thereby sterilizing
                your documents of information that thieves might be able to use. Finally, make sure
                to safely dispose of the bleach water you’ve used, so it doesn’t impact the
                environment.
              </span>
            ),
            bottomPadding: 'padding-bottom-0px',
          },
          {
            heading: 'Wash',
            para: (
              <span>
                This method is great if you have a lot of business checks. Like bleaching, washing
                your business checks will also work the ink off your documents and render them
                useless to criminals. Again, this is not the same as check washing – the practice
                that criminals use to commit check fraud.
                <br /> <br /> Put your checks in a fabric bag and then wash them in a sink or
                washing machine. As the water causes the paper to deteriorate, the ink should wash
                off the checks nicely. Done properly, this method shouldn’t leave any information
                behind that thieves or criminals might use.
              </span>
            ),
            bottomPadding: 'padding-bottom-0px',
          },
          {
            heading: 'Stamp',
            para: (
              <span>
                Just have a few business checks you want to get rid of? Even one? Try an identity
                protection stamp. You can find these at many online retailers and office supply
                stores. ID protection stamps usually comes in the form of a roller that you can use
                to black out addresses and personal information, so your sensitive information
                remains safely out of view.
              </span>
            ),
          },
          {
            para: (
              <span>
                Before deciding on any of these methods, it’s a good idea to develop a document
                management system first. Decide how long you should keep the different types of
                documents you have and think about best practices for your employees. This way, when
                it’s time to do some document purging, you’ll be ready with the method (or methods)
                that’s right for your business needs.
              </span>
            ),
            bottomPadding: 'padding-bottom-25px',
          },
        ],
      },
    },
  },
};
