import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'promise-polyfill/src/polyfill';
import 'unfetch/polyfill';
import 'abortcontroller-polyfill';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import store from './js/redux/reducers/store';

// import MainComponentPage from "./js/components/MainComponent/MainComponentPage";
import RootComponent from './js/components/MainComponent/RootComponent';
import './index.css';
import * as serviceWorker from './serviceWorker';

console.log('Environment: ', process.env.REACT_APP_ENVIRONMENT);
const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  // eslint-disable-next-line react/jsx-filename-extension
  <React.StrictMode>
    <Provider store={store}>
      <RootComponent />
    </Provider>
  </React.StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
