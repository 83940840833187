export const customisationRoutes = {
  'CHECK-02': 'bvc',
  'CHECK-11': 'spvc',
  'CHECK-16': 'spvcpremier',
  'DEP-01': 'mds',
  'DEP-02': 'pds',
  'CHECK-01': 'bsc',
  'CHECK-12': 'spsc',
  'CHECK-17': 'spscpremier',
  'CHECK-09': 'bwc',
  'CHECK-03': 'bwc',
  'CHECK-14': 'spwc',
  'MCHECK-01': 'mcheck',
  'CHECK-04': 'officeaway',
  'CHECK-15': 'sppc',

  'DEP-ADV-01': 'ifrm',
  'STATE-01': 'ifrm',
  'STATE-02': 'ifrm',
  'STAMP-03': 'stamps',
  'STAMP-04': 'stamps',
  'STAMP-06': 'stamps',
  'STAMP-19': 'stamps',
  'INV-01': 'ifrm',
  'INV-02': 'ifrm',
  'INV-03': 'ifrm',
  'LME-01': 'ifrm',
  'ENV-07': 'ifrm',
  'ENV-05': 'ifrm',
  'MULTIFORM-01': 'ifrm',
};

export const ipdItemsReorderCust = {
  // ipd reorder customizable products - preview will be available only for this list
  'CHECK-02': 'ipdcustomization',
  'CHECK-11': 'ipdcustomization',
  'CHECK-16': 'ipdcustomization',
  'CHECK-01': 'ipdcustomization',
  'CHECK-12': 'ipdcustomization',
  'CHECK-17': 'ipdcustomization',
  'CHECK-15': 'ipdcustomization',
  'CHECK-09': 'ipdcustomization',
  'CHECK-03': 'ipdcustomization',
  'CHECK-14': 'ipdcustomization',
  // 'STAMP-19': 'ipdcustomization',
  'MCHECK-01': 'ipdcustomization',
  'CHECK-04': 'ipdcustomization',
};

export const imOnlyChecksRouts = {
  // ToDo - routs needs to be created to launch inside IPD

  'MCHECK-02': 'CheckReorderStart',
  'DEP-ADV-01': '3StepsReorderStart',
  'STATE-01': '3StepsReorderStart',
  'STATE-02': '3StepsReorderStart',
  'STAMP-03': '3StepsReorderStart',
  'STAMP-04': '3StepsReorderStart',
  'STAMP-06': '3StepsReorderStart',
  'STAMP-19': '3StepsReorderStart',
  'INV-01': '3StepsReorderStart',
  'INV-02': '3StepsReorderStart',
  'INV-03': '3StepsReorderStart',
  'LME-01': '3StepsReorderStart',
  'ENV-07': '3StepsReorderStart',
  'ENV-05': '3StepsReorderStart',
  'MULTIFORM-01': '3StepsReorderStart',
};

export const expiredProducts = [
  'BOX-01',
  'BOX-02',
  'BOX-03',
  'BOX-04',
  'BANHANG-01',
  'BANNER-01',
  'BANNER-02',
  'BANNER-03',
  'BANSTAND-01',
  'BIZCD-01',
  'BIZCD-02',
  'BROCH-01',
  'CAL-01',
  'CAL-02',
  'ENV-50',
  'ENV-51',
  'ENV-52',
  'ENV-53',
  'ENV-54',
  'FLYER-01',
  'HOLCD-01',
  'HOLCD-02',
  'HOLCD-03',
  'LETRHD-01',
  'LETRHD-02',
  'MAGNET-01',
  'MAGNET-02',
  'POSTCD-01',
  'POSTCD-02',
  'QBFORM-01',
  'RACK-01',
  'RAL-01',
  'RAL-02',
  'SIGN-01',
  'SIGN-02',
  'STAMP-20',
  'STAMP-21',
  'STAND-01',
  'LABEL-04',
  'LABEL-05',
  'LABEL-06',
  'LABEL-08',
  'TOF',
];

export const alternateSkus = {
  438: '738',
  151: '538',
};

// To add a discontinued product, please add a baseSku(mandatory)
// and the rest other options are optional
export const discontinuedSkus = [
  {
    baseSku: '761',
    additionalProp: null,
  },
  {
    baseSku: '101',
    additionalProp: 'prestige-blue',
  },
];
