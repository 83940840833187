import React from 'react';
import { Link } from 'react-router-dom';
import spsf from '../../images/IM/Checks/secure_plus_standard_full.png';
import spStdChk from '../../images/IM/Checks/spremier_std_chk_340.gif';
import bStdCheck from '../../images/IM/Checks/basic_standard_full.png';
import spStd120 from '../../images/IM/Checks/sp_std_chk_120.gif';
import spStd340 from '../../images/IM/Checks/sp_std_chk_340.gif';
import voucher_check_plus_full from '../../images/IM/Checks/voucher_check_plus_full.png';
import voucher_check_premier_full from '../../images/IM/Checks/voucher_check_premier_full.png';
import voucher_check_basic_full from '../../images/IM/Checks/voucher_check_basic_full.png';
import secure_plus_voucher_small_image from '../../images/IM/Checks/secure_plus_voucher_small_image.gif';
import secure_plus_voucher_340x433 from '../../images/IM/Checks/secure_plus_voucher_340x433.gif';
import sp_premiumvoucher_chk_120 from '../../images/IM/Checks/sp_premiumvoucher_chk_120.gif';
import sp_premiumvoucher_chk_340 from '../../images/IM/Checks/sp_premiumvoucher_chk_340.gif';
import chk_v21_120 from '../../images/IM/Checks/chk_v21_120.gif';
import chk_v21_340 from '../../images/IM/Checks/chk_v21_340.gif';
import chk_s21_340 from '../../images/IM/Checks/chk_s21_340.gif';
import chk_s21_120 from '../../images/IM/Checks/chk_s21_120.gif';
import wallet_check_plus_full from '../../images/IM/Checks/wallet_check_plus_full.png';
import wallet_check_basic_full from '../../images/IM/Checks/wallet_check_basic_full.png';
import sp_wallet_chk_120 from '../../images/IM/Checks/sp_wallet_chk_120.gif';
import sp_wallet_chk_340 from '../../images/IM/Checks/sp_wallet_chk_340.gif';
import chk_w21_120 from '../../images/IM/Checks/chk_w21_120.gif';
import chk_w21_340 from '../../images/IM/Checks/chk_w21_340.gif';
import chkMan120 from '../../images/IM/Checks/chk_man_120.gif';
import chkMan340 from '../../images/IM/Checks/chk_man_340.gif';
import chkMan7RingBinder from '../../images/IM/Binders/BusinessCheckBinders-7RingManual.png';
import spp_chk_188 from '../../images/IM/Checks/spp_chk_188.gif';
import spp_chk_340 from '../../images/IM/Checks/spp_chk_340.gif';
import chk_ofc_120 from '../../images/IM/Checks/chk_ofc_120.gif';
import chk_ofc_340 from '../../images/IM/Checks/chk_ofc_340.gif';
import chkBinder from '../../images/IM/POS/BusinessCheckBinders-OfficeAndAway.png';
import chk_ddf_120 from '../../images/IM/Checks/chk_ddf_120.gif';
import chk_ddf_340 from '../../images/IM/Checks/chk_ddf_340.gif';
import voucher_checks_img from '../../images/IM/Checks/CC_Image_page_vouchercheck.jpg';
import basic_voucher_all_checks from '../../images/IM/Checks/AllChecks-BasicVoucher.jpg';
import premier_voucher_all_checks from '../../images/IM/Checks/AllChecks-SecurePremier.jpg';
import plus_voucher_all_checks from '../../images/IM/Checks/AllChecks-SecurePlus.jpg';
import standard_checks_img from '../../images/IM/Checks/CC_Image_page_standardcheck.jpg';
import wallet_checks_img from '../../images/IM/Checks/C_Image_page_walletcheck.jpg';
import manual_checks_img from '../../images/IM/Checks/CC_Image_page_manualcheck.png';
import office_and_away_checks_img from '../../images/IM/Checks/CC_Image_page_officecheck.png';
import secure_plus_personal_checks_img from '../../images/IM/Checks/personal_secureplus.png';

const ChecksPageData = {
  VOUCHER_CHECKS: {
    Department: {
      DeptName: 'QuickBooks Voucher Checks',
      DepSubHeader:
        'One check and two stubs on a single sheet make it ideal for paychecks and payroll.',
      DeptDesc: (
        <span>
          QuickBooks voucher checks automatically capture payroll deductions and invoice payment
          details, saving you time and reducing data entry errors. Maximize fraud prevention efforts
          by choosing our <span className="font-wgt-600">Secure Plus</span> or{' '}
          <span className="font-wgt-600">Secure Premier Voucher Checks</span> - the most secure
          business checks we offer.
        </span>
      ),
      DeptId: '',
      FetchPricing: true,
      RequestBody: ['CHECK-11', 'CHECK-16', 'CHECK-02'],
      HasFaq: true,
      FaqDescription: {
        Header: 'QuickBooks Voucher Checks FAQ',
        LeftQuestionList: [
          {
            Header: 'Is a voucher check a payroll check?',
            Description:
              'With one check and two pay stubs on a single sheet of perforated paper, QuickBooks Voucher Checks are ideal for paychecks and vendor payments, although you can use them for any type of check payment.',
          },
          {
            Header: 'Will voucher checks work with QuickBooks Payroll?',
            Description:
              'QuickBooks Voucher Checks are designed and guaranteed to work with QuickBooks software and services, including QuickBooks Payroll.',
          },
          {
            Header: 'Can I add my company logo to voucher checks?',
            Description: (
              <span>
                Yes. Along with other customizations, you can add your company’s logo to our voucher
                checks for a more professional look.{' '}
                <Link to="/help/customization">Learn more about customizing checks.</Link>
              </span>
            ),
          },
        ],
        RightQuestionList: [
          {
            Header: 'Do I need secure checks if banks cover check fraud?',
            Description: (
              <span>
                Unfortunately, banks don’t treat losses for business check fraud the same way they
                treat personal checking account fraud. Many business owners feel shocked to find
                themselves on the hook for check fraud, with potential losses of hundreds of
                thousands of dollars.
              </span>
            ),
          },
          {
            Header: 'Which voucher check is right for my business?',
            Description: (
              <span>
                Choose the voucher check that provides the level of security you need to help
                protect your business from counterfeiting and fraud. Our{' '}
                <Link to={{ pathname: '/spvc' }} target="_blank">
                  <strong>Secure Plus Voucher Checks</strong>
                </Link>{' '}
                combine 23 security features and a competitive price, making them the most popular
                checks we sell. With a security hologram that makes them almost impossible to
                counterfeit, our{' '}
                <Link to={{ pathname: '/spvcpremier' }} target="_blank">
                  <strong>Secure Premier Voucher Checks</strong>
                </Link>{' '}
                are the most secure checks on the market.
                <sup className="vertical-align-text-top">1</sup> And all of our voucher checks are
                guaranteed to work with the latest version of QuickBooks.
              </span>
            ),
          },
        ],
      },
      Remarks: ['1. Based on feature comparison of secure checks conducted June 2021'],
      // Faqs: true,
    },
    ProductSections: [
      {
        SectionHeader: '',
        SectionDesc: '',
        Products: [
          {
            ProdDeptId: '',
            ProductId: '',
            ProductName: 'Secure Plus Voucher Checks',
            ProductDesc: (
              <span>
                Get a built-in alarm system for your checks. Our most popular and recommended
                voucher check provides our{' '}
                <span className="font-wgt-500">exclusive tamper-resistant security coating.</span>{' '}
                If the addressee or payment amount is tampered with, it produces a visible stain on
                the Secure Plus check. With{' '}
                <span className="font-wgt-500">23 security features</span>, these checks offer
                advanced protection against fraud for your business payments.
              </span>
            ),
            ImageSrc: voucher_check_plus_full,
            ImageLrg: null,
            ImageFitContent: true,
            MostPopular: true,
            ButtonText: 'Get started',
            ButtonLink: '/checks/voucher-checks/secure-plus-voucher-checks/customization',
            LearnMoreLink: '/spvc',
            LearnMoreSuffixText: null,
            ActualPrice: true,
            DiscountPrice: 0,
            Sku: '1785',
            Quantity: true,
            SpecialProcessId: 0,
            HasPricing: false,
            PFID: 'CHECK-11',
          },
          {
            ProdDeptId: '',
            ProductId: '',
            ProductName: 'Secure Premier Voucher Checks',
            ProductDesc: (
              <span>
                Maximize your protection against fraud and counterfeit activity with QuickBooks
                Secure Premier Voucher checks. Our{' '}
                <span className="font-wgt-500">security hologram</span> embedded in the check makes
                verification fast and simple and counterfeiting almost impossible.
              </span>
            ),
            ImageSrc: voucher_check_premier_full,
            ImageLrg: null,
            ImageFitContent: true,
            MostPopular: false,
            ButtonText: 'Get started',
            ButtonLink: '/checks/voucher-checks/secure-premier-voucher-checks/customization',
            LearnMoreLink: '/spvcpremier',
            LearnMoreSuffixText: null,
            ActualPrice: true,
            DiscountPrice: 0,
            Sku: '2785',
            Quantity: true,
            SpecialProcessId: 0,
            HasPricing: false,
            PFID: 'CHECK-16',
          },
          {
            ProdDeptId: '',
            ProductId: '',
            ProductName: 'Basic Voucher Checks',
            ProductDesc: (
              <span>
                Save time and maintain accuracy by printing business voucher checks designed to work
                specifically with QuickBooks on laser printers. The voucher stub conveniently tracks
                deductions and payment details for simplified record keeping.
              </span>
            ),
            ImageSrc: voucher_check_basic_full,
            ImageLrg: null,
            ImageFitContent: true,
            MostPopular: false,
            ButtonText: 'Get started',
            ButtonLink: '/checks/voucher-checks/basic-voucher-checks/customization',
            LearnMoreLink: '/bvc',
            LearnMoreSuffixText: null,
            ActualPrice: true,
            DiscountPrice: 0,
            Sku: '145',
            Quantity: true,
            SpecialProcessId: 0,
            HasPricing: false,
            PFID: 'CHECK-02',
          },
        ],
      },
    ],
  },
  SECURE_PLUS_VOUCHER_CHECKS: {
    Department: {
      DeptName: 'Secure Plus Voucher Checks',
      DeptDesc: null,
      DeptId: '',
      FetchPricing: true,
      RequestBody: ['CHECK-11'],
      Image: secure_plus_voucher_small_image,
      ImageLg: secure_plus_voucher_340x433,
      PFID: 'CHECK-11',
      ImageIsUrl: false,
      Sku: '1785',
      MaxQuantity: 2000,
      ProductInformation: {
        Desc: (
          <span>
            Business voucher checks are{' '}
            <span className="font-wgt-500">ideal for payroll and accounts payable</span>. Easily
            track payroll deductions and invoice payment details. Give one stub to your employee or
            vendor and keep the other for your files.
          </span>
        ),
        DescExtras: [
          <span>
            Offers advanced protection against check fraud with{' '}
            <a
              className="universal-link"
              href="/resources/fraud-prevention/security-check-features/SecurePlusProductFeatures.html?spvc"
              target="_blank"
            >
              23 additional security features
            </a>
            , including a heat sensitive icon that{' '}
            <span className="font-wgt-500">prevents photocopying</span>, a true watermark that{' '}
            <span className="font-wgt-500">protects against forgery and counterfeiting</span>, and
            our exclusive security coating that{' '}
            <span className="font-wgt-500">blocks tampering</span> with your check info.
          </span>,
        ],
        EachKitContains: null,
        Specifications: [
          'Sheet Size: 8.5"w x 11"l',
          'Check Size: 8.5"w x 3.5"l',
          'Compatibility: All supported versions of QuickBooks',
        ],
        Compatibility: null,
        CheckLockProtection: true,
      },
      PricingInformation: {
        Type: 'GETSTARTED',
        Link: '/checks/voucher-checks/secure-plus-voucher-checks/customization',
        LinkEnabled: true,
      },
    },
  },
  SECURE_PREMIER_VOUCHER_CHECKS: {
    Department: {
      DeptName: 'Secure Premier Voucher Checks',
      DeptDesc: null,
      DeptId: '',
      FetchPricing: true,
      RequestBody: ['CHECK-16'],
      Image: sp_premiumvoucher_chk_120,
      ImageLg: sp_premiumvoucher_chk_340,
      PFID: 'CHECK-16',
      Sku: '2785',
      ImageIsUrl: false,
      MaxQuantity: 2000,
      ProductInformation: {
        Desc: (
          <span>
            Business voucher checks are{' '}
            <span className="font-wgt-500">ideal for payroll and accounts payable</span>. Easily
            track payroll deductions and invoice payment details. Give one stub to your employee or
            vendor and keep the other for your files.
          </span>
        ),
        DescExtras: [
          <span>
            With <span className="font-wgt-500">29 security features</span> - including a Security
            hologram that makes your check{' '}
            <span className="font-wgt-500">virtually impossible to counterfeit</span> - our Secure
            Premier Checks are the{' '}
            <span className="font-wgt-500">most secure checks in the market.</span>
            <sup className="vertical-align-text-top">1</sup>
          </span>,
        ],
        EachKitContains: null,
        Specifications: [
          'Sheet Size: 8.5"w x 11"l',
          'Check Size: 8.5"w x 3.5"l',
          'Compatibility: All supported versions of QuickBooks',
        ],
        Compatibility: null,
        CheckLockProtection: true,
        Remarks: ['1. Based on feature comparison of secure checks conducted June 2021'],
      },
      PricingInformation: {
        Type: 'GETSTARTED',
        Link: '/checks/voucher-checks/secure-premier-voucher-checks/customization',
        LinkEnabled: true,
      },
    },
  },
  BASIC_VOUCHER_CHECKS: {
    Department: {
      DeptName: 'Basic Voucher Checks',
      DeptDesc: null,
      DeptId: '',
      FetchPricing: true,
      RequestBody: ['CHECK-02'],
      Image: chk_v21_120,
      ImageLg: chk_v21_340,
      PFID: 'CHECK-02',
      Sku: '145',
      ImageIsUrl: false,
      MaxQuantity: 2000,
      ProductInformation: {
        Desc: (
          <span>
            With one check and two pay stubs, voucher checks are{' '}
            <span className="font-wgt-500">ideal for payroll and accounts payable</span>: give one
            stub to your employee or vendor and keep the other for your files.
          </span>
        ),
        DescExtras: [
          <span>
            Printing from QuickBooks makes it easy to{' '}
            <span className="font-wgt-500">
              track payroll deductions and invoice payment details.
            </span>{' '}
            Save detailed records for industries that require strict documentation.
          </span>,
        ],
        EachKitContains: null,
        Specifications: [
          'Sheet Size: 8.5"w x 11"l',
          'Check Size: 8.5"w x 3.5"l',
          'Compatibility: All supported versions of QuickBooks',
        ],
        Compatibility: null,
        CheckLockProtection: true,
        Remarks: null,
      },
      PricingInformation: {
        Type: 'GETSTARTED',
        Link: '/checks/voucher-checks/basic-voucher-checks/customization',
        LinkEnabled: true,
      },
    },
  },
  STANDARD_CHECKS: {
    Department: {
      DeptName: 'QuickBooks Standard Checks',
      DepSubHeader: 'Quickly and easily print multiple checks at a time.',
      DeptDesc:
        'Print three standard checks at a time, saving time and money. Designed specifically for QuickBooks to print perfectly every time.',
      DeptId: '',
      FetchPricing: true,
      RequestBody: ['CHECK-12', 'CHECK-17', 'CHECK-01'],
      HasFaq: true,
      FaqDescription: {
        Header: 'QuickBooks Standard Business Checks FAQ',
        LeftQuestionList: [
          {
            Header: 'Why choose Standard (3-on-a-page) Checks?',
            Description:
              'With three checks per page and designed to work on almost any printer, QuickBooks Standard Checks are ideal for quickly and easily paying your vendors or other bills in large batches. This saves both time and resources for your accounts payable department.',
          },
          {
            Header: 'Are Standard Checks guaranteed to work with QuickBooks?',
            Description:
              'Yes. These are the only 3-to-a-page business checks that QuickBooks guarantees will work with all versions of QuickBooks.',
          },
        ],
        RightQuestionList: [
          {
            Header: "Can I customize Standard Checks to reflect my business' branding?",
            Description: (
              <span>
                Yes. QuickBooks Standard Checks allow you to upload your own logo or choose icons
                from our library to customize your checks for a more professional look.
                <Link to="/help/customization"> Learn more about customizing checks.</Link>
              </span>
            ),
          },
          {
            Header: 'Can I hand write these checks?',
            Description: (
              <span>
                Although they are designed to quickly print multiple checks at the same time, it is
                possible to hand write these checks. You’ll need to ensure that you don’t attempt to
                print multiple checks on a sheet that only has one or two checks remaining.
              </span>
            ),
          },
        ],
      },
    },
    ProductSections: [
      {
        SectionHeader: '',
        SectionDesc: '',
        Products: [
          {
            ProdDeptId: '',
            ProductId: '',
            ProductName: 'Secure Plus Standard Business Checks',
            ProductDesc: (
              <span>
                Help protect your business from fraud with business checks that reveal signs of
                check tampering. Secure Plus Standard Business Checks feature a{' '}
                <span className="font-wgt-500">
                  tamper-resistant security coating exclusive to QuickBooks.
                </span>{' '}
                Attempts to remove the address or the amount printed on the check result in a
                visible stain, indicating potential fraud.
              </span>
            ),
            ImageSrc: spsf,
            ImageLrg: null,
            ImageFull: true,
            ButtonText: 'Get started',
            ButtonLink:
              '/checks/standard-checks/secure-plus-standard-business-checks/customization',
            LearnMoreSuffixText: null,
            LearnMoreLink: '/spsc',
            ActualPrice: 56.99,
            DiscountPrice: 0,
            Sku: null,
            Quantity: true,
            SpecialProcessId: 0,
            HasPricing: false,
            PFID: 'CHECK-12',
          },
          {
            ProdDeptId: '',
            ProductId: '',
            ProductName: 'Secure Premier Standard Business Checks',
            ProductDesc: (
              <span>
                Go one step beyond Secure Plus Standard Business Checks with the most powerful fraud
                protection we offer. Secure Premier Business checks thwart scanners and copiers
                alike with a specially designed security hologram. Enjoy peace of mind using checks
                that are <span className="font-wgt-500">practically impossible to counterfeit</span>
                .
              </span>
            ),
            ImageSrc: spStdChk,
            ImageLrg: null,
            ImageFull: true,
            ButtonText: 'Get started',
            ButtonLink:
              '/checks/standard-checks/secure-premier-standard-business-checks/customization',
            LearnMoreSuffixText: null,
            LearnMoreLink: '/spsbc',
            ActualPrice: 61.99,
            DiscountPrice: 0,
            Sku: null,
            Quantity: true,
            SpecialProcessId: 0,
            HasPricing: false,
            PFID: 'CHECK-17',
          },
          {
            ProdDeptId: '',
            ProductId: '',
            ProductName: 'Basic Standard Business Checks',
            ProductDesc: (
              <span>
                Pay multiple bills and vendors quickly and easily with minimal fraud protection.
              </span>
            ),
            ImageSrc: bStdCheck,
            ImageLrg: null,
            ImageFull: true,
            ButtonText: 'Get started',
            ButtonLink: '/checks/standard-checks/basic-standard-business-checks/customization',
            LearnMoreSuffixText: null,
            LearnMoreLink: '/bsbc',
            ActualPrice: 102.99,
            DiscountPrice: 0,
            Sku: '339',
            Quantity: true,
            SpecialProcessId: 0,
            HasPricing: false,
            PFID: 'CHECK-01',
          },
        ],
      },
    ],
  },
  SECURE_PLUS_STANDARD_CHECKS: {
    Department: {
      DeptName: 'Secure Plus Standard Business Checks',
      DeptDesc: null,
      DeptId: '',
      FetchPricing: true,
      RequestBody: ['CHECK-12'],
      Image: spStd120,
      ImageLg: spStd340,
      PFID: 'CHECK-12',
      ProductInformation: {
        Desc: (
          <span>
            Save time and money by printing{' '}
            <span className="font-wgt-500">three personalized QuickBooks checks per page</span>,
            paying multiple bills and vendors quickly and easily. Keep better records by using data
            already stored in QuickBooks.
          </span>
        ),
        DescExtras: [
          <span>
            Offers advanced protection against check fraud, with{' '}
            <a
              className="universal-link"
              href="/resources/fraud-prevention/security-check-features/SecurePlusStandardCheckFeatures.html?spsc"
              target="_blank"
            >
              23 additional security features
            </a>
            , including a heat sensitive icon that{' '}
            <span className="font-wgt-500">prevents photocopying</span>, a CheckLock™ Custom True
            Watermark that{' '}
            <span className="font-wgt-500">protects against forgery and counterfeiting</span>, and
            our exclusive security coating that{' '}
            <span className="font-wgt-500">blocks tampering</span> with your check info.
          </span>,
        ],
        CheckLockProtection: true,
        EachKitContains: null,
        Specifications: [
          'Sheet Size: 8.5"w x 11"l',
          'Check Size: 8.5"w x 3.5"l',
          'Compatibility: QuickBooks Desktop and QuickBooks Online. Standard checks are not compatible with QuickBooks Online Payroll',
        ],
        Compatibility: null,
        SpecialNote: null,
      },
      PricingInformation: {
        Type: 'GETSTARTED',
        Link: '/checks/standard-checks/secure-plus-standard-business-checks/customization',
        LinkEnabled: true,
      },
    },
  },
  SECURE_PREMIER_STANDARD_CHECKS: {
    Department: {
      DeptName: 'Secure Premier Standard Business Checks',
      DeptDesc: null,
      DeptId: '',
      FetchPricing: true,
      RequestBody: ['CHECK-17'],
      Image: spStd120,
      ImageLg: spStd340,
      PFID: 'CHECK-17',
      ProductInformation: {
        Desc: (
          <span>
            Save time and money by printing three personalized QuickBooks checks per page,{' '}
            <span className="font-wgt-500">
              paying multiple bills and vendors quickly and easily
            </span>
            . Keep better records by using data already stored in QuickBooks.
          </span>
        ),
        DescExtras: [
          <span>
            With <span className="font-wgt-500">29 security features</span> - including a Security
            hologram that makes your check{' '}
            <span className="font-wgt-500">virtually impossible to counterfeit</span> - our Secure
            Premier Checks are the{' '}
            <span className="font-wgt-500">most secure checks in the market</span>.
            <sup className="vertical-align-text-top">1</sup>
          </span>,
        ],
        CheckLockProtection: true,
        EachKitContains: null,
        Specifications: [
          'Sheet Size: 8.5"w x 11"l',
          'Check Size: 8.5"w x 3.5"l',
          'Compatibility: QuickBooks Desktop and QuickBooks Online. Standard checks are not compatible with QuickBooks Online Payroll',
        ],
        Compatibility: null,
        SpecialNote: null,
        Remarks: ['1. Based on feature comparison of secure checks conducted June 2021'],
      },
      PricingInformation: {
        Type: 'GETSTARTED',
        Link: '/checks/standard-checks/secure-premier-standard-business-checks/customization',
        LinkEnabled: true,
      },
    },
  },
  BASIC_STANDARD_CHECKS: {
    Department: {
      DeptName: 'Basic Standard Business Checks',
      DeptDesc: null,
      DeptId: '',
      FetchPricing: true,
      RequestBody: ['CHECK-01'],
      Image: chk_s21_120,
      ImageLg: chk_s21_340,
      PFID: 'CHECK-01',
      Sku: '339',
      ProductInformation: {
        Desc: (
          <span>
            Save time and money by printing three personalized QuickBooks checks per page. Pay
            multiple bills and vendors quickly and easily and keep better records by using data
            already stored in QuickBooks.
          </span>
        ),
        DescExtras: null,
        CheckLockProtection: true,
        EachKitContains: null,
        Specifications: [
          'Sheet Size: 8.5"w x 11"l',
          'Check Size: 8.5"w x 3.5"l',
          'Compatibility: QuickBooks Desktop and QuickBooks Online. Standard checks are not compatible with QuickBooks Online Payroll',
        ],
        Compatibility: null,
        SpecialNote: null,
        Remarks: null,
      },
      PricingInformation: {
        Type: 'GETSTARTED',
        Link: '/checks/standard-checks/basic-standard-business-checks/customization',
        LinkEnabled: true,
      },
    },
  },
  BUSINESS_WALLET_CHECKS: {
    Department: {
      DeptName: 'QuickBooks Wallet Checks',
      DepSubHeader: 'Easily write checks on the go or print at home or in the office.',
      DeptDesc:
        'Exclusively designed for use with QuickBooks: printable, wallet-style checks, 3-to-a-page with register on the side.',
      DeptId: '',
      FetchPricing: true,
      RequestBody: ['CHECK-14', 'CHECK-09'],
      HasFaq: true,
      FaqDescription: {
        Header: 'QuickBooks Wallet Checks FAQ',
        LeftQuestionList: [
          {
            Header: 'What is a wallet check?',
            Description:
              'A wallet check is a business or personal check sized to fit in a standard-sized wallet. Wallet checks typically have dimensions of 6" x 2.625” and are smaller than traditional business checks.',
          },
          {
            Header:
              'Do I need a check register with wallet checks? What about duplicate or triplicate checks?',
            Description:
              'QuickBooks Wallet Checks remove the need for a check register and for duplicate or triplicate checks. If you’re printing from QuickBooks, the check information will be recorded automatically. If you choose to handwrite the payee and amount, you can write this information on the attached stub, then record it in QuickBooks later.',
          },
        ],
        RightQuestionList: [
          {
            Header: 'Why choose wallet checks?',
            Description:
              'Wallet checks work well if you have immediate and unexpected payments, if you don’t know the exact payment amount, or if you prefer the convenience of smaller, more portable checks.',
          },
          {
            Header: 'Can I customize my wallet checks?',
            Description: (
              <span>
                Yes. You can add your own company logo or choose an icon from our library.
                <Link to="/help/customization"> Learn more about customizing checks.</Link>
              </span>
            ),
          },
          {
            Header:
              'Do QuickBooks Wallet Checks allow for multiple signatures for endorsement requirements?',
            Description:
              'Yes, you can customize our Wallet Checks to include two signature lines for endorsement.',
          },
        ],
      },
    },
    ProductSections: [
      {
        SectionHeader: '',
        SectionDesc: '',
        Products: [
          {
            ProdDeptId: '',
            ProductId: '',
            ProductName: 'Secure Plus Wallet Checks',
            ProductDesc: (
              <span>
                Get a built-in alarm system for your checks. Our{' '}
                <span className="font-wgt-500">
                  tamper-resistant security coating alerts you to signs of paper tampering.
                </span>{' '}
                Quickly and accurately record expense and payment details automatically when you
                print from QuickBooks. Wallet Checks conveniently fit in a wallet and print 3-to-a
                page to save time and money.
              </span>
            ),
            ImageSrc: wallet_check_plus_full,
            ImageLrg: null,
            ImageFull: true,
            ButtonText: 'Get started',
            ButtonLink: '/checks/wallet-checks/secure-plus-wallet-checks/customization',
            LearnMoreSuffixText: null,
            LearnMoreLink: '/spwc',
            ActualPrice: 56.99,
            DiscountPrice: 0,
            Sku: null,
            Quantity: true,
            SpecialProcessId: 0,
            HasPricing: false,
            PFID: 'CHECK-14',
          },
          {
            ProdDeptId: '',
            ProductId: '',
            ProductName: 'Basic Wallet Checks',
            ProductDesc: (
              <span>
                Basic Wallet Checks have stubs for more accurate record-keeping. They offer basic
                fraud protection but still provide the convenience of a single wallet check. When
                printed through QuickBooks, transactions also record in your ledger, eliminating the
                need to keep a separate transaction register.
              </span>
            ),
            ImageSrc: wallet_check_basic_full,
            ImageLrg: null,
            ImageFull: true,
            ButtonText: 'Get started',
            ButtonLink: '/checks/wallet-checks/basic-wallet-checks/customization',
            LearnMoreSuffixText: null,
            LearnMoreLink: '/bwc',
            ActualPrice: 61.99,
            DiscountPrice: 0,
            Sku: null,
            Quantity: true,
            SpecialProcessId: 0,
            HasPricing: false,
            PFID: 'CHECK-09',
          },
        ],
      },
    ],
  },
  SECURE_PLUS_WALLET_CHECKS: {
    Department: {
      DeptName: 'Secure Plus Wallet Checks',
      DeptDesc: null,
      DeptId: '',
      FetchPricing: true,
      RequestBody: ['CHECK-14'],
      Image: sp_wallet_chk_120,
      ImageLg: sp_wallet_chk_340,
      PFID: 'CHECK-14',
      ProductInformation: {
        Desc: (
          <span>
            These printer checks help save time and money by{' '}
            <span className="font-wgt-500">printing three checks per page</span>. Use expense and
            payment details already in QuickBooks Desktop or Quicken to pay vendors quickly.
          </span>
        ),
        DescExtras: [
          <span>
            Offers advanced protection against check fraud, with{' '}
            <a
              className="universal-link"
              href="/resources/fraud-prevention/security-check-features/SecurePlusWalletCheckFeatures.html?spwc"
              target="_blank"
            >
              23 additional security features
            </a>
            , including a heat sensitive icon that{' '}
            <span className="font-wgt-500">prevents photocopying</span>, a CheckLock™ Custom True
            Watermark that{' '}
            <span className="font-wgt-500">protects against forgery and counterfeiting</span>, and
            our exclusive security coating that{' '}
            <span className="font-wgt-500">blocks tampering</span> with your check info.
          </span>,
        ],
        CheckLockProtection: true,
        EachKitContains: null,
        Specifications: [
          'Sheet Size: 8.5"w x 11"l',
          'Check Size: 8.5"w x 3.5"l',
          'Compatibility: Wallet Checks are compatible with QuickBooks Desktop 2006, QuickBooks for Mac R4, and Quicken 2006 or later. Not compatible with QuickBooks Online.',
        ],
        Compatibility: null,
        SpecialNote: null,
      },
      PricingInformation: {
        Type: 'GETSTARTED',
        Link: '/checks/wallet-checks/secure-plus-wallet-checks/customization',
        LinkEnabled: true,
      },
    },
  },
  BASIC_WALLET_CHECKS: {
    Department: {
      DeptName: 'Basic Wallet Checks',
      DeptDesc: null,
      DeptId: '',
      FetchPricing: true,
      RequestBody: ['CHECK-09'],
      Image: chk_w21_120,
      ImageLg: chk_w21_340,
      PFID: 'CHECK-09',
      ProductInformation: {
        Desc: (
          <span>
            These business checks help save time and money by printing three checks per page and
            help you keep more accurate records with check stubs. Use expense and payment details
            already in QuickBooks Desktop or Quicken to pay vendors quickly.
          </span>
        ),
        DescExtras: null,
        CheckLockProtection: true,
        EachKitContains: null,
        Specifications: [
          'Sheet Size: 8.5"w x 11"l',
          'Check Size: 6"w x 2.625"l',
          'Compatibility: Wallet Checks are compatible with QuickBooks Desktop 2006, QuickBooks for Mac R4, and Quicken 2006 or later. Not compatible with QuickBooks Online.',
        ],
        Compatibility: null,
        SpecialNote: null,
        Remarks: null,
      },
      PricingInformation: {
        Type: 'GETSTARTED',
        Link: '/checks/wallet-checks/basic-wallet-checks/customization',
        LinkEnabled: true,
      },
    },
  },
  MANUAL_CHECKS: {
    Department: {
      DeptName: '3-To-A-Page General Purpose Manual Checks',
      DepSubHeader: 'Save up to 30% on 3-to-a-page business checks compared to bank prices.',
      DeptDesc: null,
      DeptId: '',
      FetchPricing: true,
      RequestBody: ['MCHECK-01', 'MCBINDER-01'],
      Image: chkMan120,
      ImageLg: chkMan340,
      Sku: '959',
      PFID: 'MCHECK-01',
      ProductInformation: {
        Desc: [
          <span>
            Easy to carry in your pocket, purse, or briefcase and fill out anywhere. Or, keep them
            at your desk, so you always have a set of checks on hand. Side-tear check stubs make it
            easy to track your transactions for entry into QuickBooks later. Bundle them with our{' '}
            <Link to="/supplies/binders/manual-check-binder" className="learn-more font-wgt-500">
              7-Ring Check Binder
            </Link>{' '}
            to keep them safe and secure.
          </span>,
        ],
        DescExtras: null,
        CheckLockProtection: true,
        EachKitContains: null,
        Specifications: ['Sheet Size: 12.875"w x 3.042"l', 'Check Size: 8.25"w x 3.042"l'],
        Compatibility: null,
        SpecialNote: null,
      },
      PricingInformation: {
        Type: 'GETSTARTED',
        Link: '/checks/manual-checks/customization',
        LinkEnabled: true,
      },
      BroughtTogether: {
        Department: {
          Name: '7-Ring Manual Business Check Binder',
          Desc: 'Protect your checks from getting crumpled: Our stylish and professional binder perfectly holds your 3-to-a-page General Purpose Manual Checks.',
          Image: chkMan7RingBinder,
          PFID: 'MCBINDER-01',
          PriceDisplay: null,
          PricingInformation: {
            Type: 'ADDTOCART',
            HideShipInfo: true,
          },
        },
      },
    },
    HasFaq: true,
    FaqDescription: {
      Header: 'Manual Business Checks & Binder FAQ',
      LeftQuestionList: [
        {
          Header: 'Why use manual checks for business?',
          Description:
            'Keeping a set of manual checks in your desk makes check writing quick and convenient. And if you need some checks on the go, you can always tear a few out to keep in your pocket. Or, even better, get our check binder to keep them in.',
        },
        {
          Header: 'Do I need the binder for my manual checks?',
          Description:
            'No, however, we recommend using a binder with your manual business checks. Our 7-ring check binders provide safe storage for your manual checks, protecting them from getting crumpled or sitting out in plain sight. They make a professional, elegant way of keeping your checks at your desk.',
        },
      ],
      RightQuestionList: [
        {
          Header: 'Can I use manual checks for payroll and bonuses?',
          Description: (
            <span>
              Manual checks are considered “general purpose” so you can use them for any type of
              payment. While our{' '}
              <Link to="/checks/voucher-checks">
                <strong>voucher checks</strong>
              </Link>{' '}
              are the most popular option for payroll, you could use manual checks if you prefer.
              The stubs along the side of each page of checks let you record the details of the
              transaction so you can enter them in QuickBooks.
            </span>
          ),
        },
        {
          Header: 'Are Manual Checks secure?',
          Description: (
            <span>
              Although Manual Checks provide basic security features, we recommend our{' '}
              <Link to="/spwc">
                <strong>Secure Plus Wallet Checks</strong>{' '}
              </Link>
              for a similar check with more advanced protection.
            </span>
          ),
        },
      ],
    },
  },
  SECURE_PLUS_PERSONAL_CHECKS: {
    Department: {
      DeptName: 'Secure Plus Personal Checks',
      DepSubHeader:
        'Personal checks with extra security to help protect you against payment fraud.',
      DeptDesc: null,
      DeptId: '',
      FetchPricing: true,
      RequestBody: ['CHECK-15'],
      Image: spp_chk_188,
      ImageLg: spp_chk_340,
      PFID: 'CHECK-15',
      ProductInformation: {
        Desc: (
          <span>
            These carbonless duplicate checks offer advanced protection against check fraud with 23
            security features, including a heat sensitive icon that{' '}
            <span className="font-wgt-500">prevents photocopying</span>, a CheckLock™ Custom True
            Watermark that{' '}
            <span className="font-wgt-500">protects against forgery and counterfeiting.</span>
          </span>
        ),
        DescExtras: null,
        CheckLockProtection: true,
        EachKitContains: null,
        Specifications: [
          'Sheet Size: 6"w x 2.75"l',
          '2-part carbonless duplicate checks',
          'Compatibility: Not compatible with QuickBooks',
        ],
        Compatibility: null,
        SpecialNote: null,
        Remarks: null,
      },
      PricingInformation: {
        Type: 'GETSTARTED',
        Link: '/checks/personal-checks/customization',
        LinkEnabled: true,
      },
    },
    HasFaq: true,
    FaqDescription: {
      Header: 'Secure Plus Personal Checks FAQ',
      LeftQuestionList: [
        {
          Header: 'Why not just order personal checks from my bank?',
          Description:
            'While it might seem convenient to order them from your local bank, their checks may be pricier than you think. Additionally, they may lack many critical security features that help protect you from check fraud.',
        },
        {
          Header: 'Are higher security personal checks worth the extra cost?',
          Description: (
            <span>
              Our personal checks are designed with all of the security features of our{' '}
              <Link to="/spvc"> Secure Plus Voucher Checks</Link>, giving you advanced protection
              against payment fraud.
            </span>
          ),
        },
        {
          Header: 'Your variety of personal check themes seems limited. Why not offer more?',
          Description:
            'We’ve chosen to focus on simple, elegant check designs that allow us to include security features like a heat-sensitive icon and custom true watermark.',
        },
      ],
      RightQuestionList: [
        {
          Header: 'Is it worth switching to QuickBooks personal checks?',
          Description:
            'Upgrade to commercial-grade check security with our elegant, professional-looking checks. Our Secure Plus Personal checks have all the security features of our Secure Plus business checks, helping to protect you from check fraud in ways that most checks from banks and big-box retailers can’t. Enter a custom starting check number and you can pick up right where you left off with your old checks.',
        },
      ],
    },
  },
  OFFICE_AWAY_CHECKS: {
    Department: {
      DeptName: 'Office & Away Checks',
      DeptDesc: null,
      DeptId: '',
      FetchPricing: true,
      RequestBody: ['CHECK-04', 'BIN-01'],
      Image: chk_ofc_120,
      ImageLg: chk_ofc_340,
      Sku: null,
      PFID: 'CHECK-04',
      ProductInformation: {
        Desc: (
          <span>
            Print in your office directly from QuickBooks Desktop or handwrite when you're away.
            Keep your checks organized and easily carry them with you using our{' '}
            <Link to="/supplies/binders/away-check-binder" className="learn-more font-wgt-500">
              checkbook binder
            </Link>
            .
          </span>
        ),
        DescExtras: null,
        CheckLockProtection: true,
        EachKitContains: null,
        Specifications: [
          'Sheet Size: 8.5"w x 11"l',
          'Check Size: 8.5"w x 3.5"l',
          'Compatibility: Not compatible with QuickBooks Online or Intuit Online Payroll',
        ],
        Compatibility: null,
        SpecialNote: null,
      },
      PricingInformation: {
        Type: 'GETSTARTED',
        Link: '/checks/office-away-checks/customization',
        LinkEnabled: true,
      },
      BroughtTogether: {
        Department: {
          Name: 'Office & Away CheckBook Binder',
          Desc: 'Keep your Office & Away Checks organized with this handsome binder. It holds up to 75 checks and comes with a business card pocket place to store documents and a pen holder.',
          Image: chkBinder,
          PFID: 'BIN-01',
          PricingInformation: {
            Type: 'ADDTOCART',
            HideShipInfo: true,
          },
        },
      },
    },
    HasFaq: true,
    FaqDescription: {
      Header: 'Office & Away Checks & Binder FAQ',
      LeftQuestionList: [
        {
          Header: 'Are Office & Away Checks a good fit for my business?',
          Description:
            'Some business owners choose Office & Away Checks because they are among the most versatile checks they can use. Print them directly from QuickBooks or write a check by hand and record it in QuickBooks later.',
        },
      ],
      RightQuestionList: [
        {
          Header: 'Is it worth getting the CheckBook Binder for my checks?',
          Description:
            'To get the most advantage from the Office & Away Checks, use the CheckBook Binder to keep them neatly organized and ready to go.',
        },
      ],
    },
  },
  DIRECT_DEPOSIT_FORMS: {
    Department: {
      DeptName: 'Direct Deposit Forms',
      DeptDesc: null,
      DeptId: '',
      FetchPricing: true,
      RequestBody: ['DEP-ADV-01'],
      Image: chk_ddf_120,
      ImageLg: chk_ddf_340,
      Sku: null,
      PFID: 'DEP-ADV-01',
      ProductInformation: {
        Desc: (
          <ul>
            <li>
              Track direct deposit amounts and payroll deductions to vendor payment details and
              employee paycheck information simply and quickly
            </li>
            <li>
              Direct deposit forms keep records straight by providing two stubs, one for company
              records and one for employee or vendor records
            </li>
            <li>
              Reflect your business image by customizing colors, fonts and company logo options
            </li>
            <li>As a security measure account numbers do not appear on the deposit form</li>
          </ul>
        ),
        DescExtras: null,
        CheckLockProtection: false,
        EachKitContains: null,
        Specifications: [
          'Sheet Size: 8 1/2"(w) x 11"(l)',
          'Compatibility: For current QuickBooks Online Payroll Full Service subscribers',
        ],
        Compatibility: null,
        SpecialNote: null,
      },
      PricingInformation: {
        Type: 'GETSTARTED',
        Link: '/checks/direct-deposit-forms/customization',
        LinkEnabled: true,
      },
    },
  },
  ALL_CHECKS_PAGE: {
    Department: {
      RequestBody: [
        'CHECK-11',
        'CHECK-12',
        'CHECK-17',
        'CHECK-14',
        'MCHECK-01',
        'CHECK-04',
        'CHECK-15',
        'CHECK-02',
        'CHECK-04',
        'CHECK-16',
        'CHECK-01',
        'CHECK-09',
      ],
      FetchPricing: true,
    },
    ProductSections: [
      {
        Products: [
          {
            ImageSrc: voucher_checks_img,
            ProductName: 'Voucher Checks',
            ProductDesc: (
              <span>
                These checks are ideal for payroll and accounts payable. QuickBooks business checks
                are laser and inkjet printer compatible.
              </span>
            ),
            PFID: ['CHECK-11', 'CHECK-16', 'CHECK-02'],
            Quantity: true,
            Sku: '1785-00050',
            inListDesc: [
              'Check stubs with payroll deduction',
              'Print directly from QuickBooks',
              'Customize your business checks with your company logo',
            ],
            LearnMoreLink: '/checks/voucher-checks',
            LearnMoreSuffixText: 'QuickBooks Voucher Checks',
            NavName: 'Voucher Checks',
            MostPopular: true,
          },
          {
            ImageSrc: standard_checks_img,
            ProductName: 'Standard Checks',
            ProductDesc: (
              <span>
                Print up to three custom QuickBooks-compatible checks per page. Form leaders are
                available to keep the page aligned with your printer after checks have been removed.
              </span>
            ),
            PFID: ['CHECK-12', 'CHECK-17', 'CHECK-01'],
            Quantity: true,
            Sku: '1127-00050',
            inListDesc: [
              'Pay vendors and suppliers with standard custom business checks',
              'Print directly from QuickBooks',
            ],
            LearnMoreLink: '/checks/standard-checks',
            LearnMoreSuffixText: 'QuickBooks Standard Checks',
            NavName: 'Standard (3-on-a-page) Checks',
          },
          {
            ImageSrc: wallet_checks_img,
            ProductName: 'Wallet Checks',
            ProductDesc: (
              <span>Smaller business checks with a check stub for recording payment details.</span>
            ),
            PFID: ['CHECK-14', 'CHECK-09'],
            Quantity: true,
            inListDesc: ['Use to pay vendors and suppliers', 'Print directly from QuickBooks'],
            LearnMoreLink: '/checks/wallet-checks',
            LearnMoreSuffixText: 'QuickBooks Wallet Checks',
            NavName: 'Wallet Checks',
          },
          {
            ImageSrc: manual_checks_img,
            ProductName: 'Manual Checks',
            ProductDesc: (
              <span>
                These 3-to-a-page manual business checks make it easy to record transaction details
                in QuickBooks with a detachable check stub.
              </span>
            ),
            PFID: 'MCHECK-01',
            Sku: '959-00300',
            Quantity: true,
            inListDesc: [
              'Easy to carry and fill out anywhere',
              'Save up to 30% compared to some bank prices',
            ],
            LearnMoreLink: '/checks/manual-checks',
            LearnMoreSuffixText: 'QuickBooks Manual Checks',
            NavName: 'Other Checks',
          },
          {
            ImageSrc: office_and_away_checks_img,
            ProductName: 'Office and Away Checks',
            ProductDesc: (
              <span>
                Print directly from QuickBooks Desktop or handwrite when you're on the road with
                versatile computer checks for QuickBooks Desktop.
              </span>
            ),
            PFID: 'CHECK-04',
            Quantity: true,
            inListDesc: [
              'Designed for flexibility',
              'Look more professional',
              <span>
                Use an{' '}
                <Link to={{ pathname: '/supplies/binders/away-check-binder' }}>
                  optional binder
                </Link>{' '}
                to carry and store (not included)
              </span>,
            ],
            LearnMoreLink: '/checks/office-away-checks',
            LearnMoreSuffixText: 'QuickBooks Office and Away Checks',
          },
          {
            ImageSrc: secure_plus_personal_checks_img,
            ProductName: 'Secure Plus Personal Checks',
            ProductDesc: (
              <span>
                These secure personal checks help prevent check fraud with chemically reactive
                paper. Printed at a U.S. Treasury-approved facility.
              </span>
            ),
            PFID: 'CHECK-15',
            Quantity: true,
            Sku: '1990-00120',
            inListDesc: [
              'Five booklets in each box',
              '2-part carbonless duplicate checks',
              'Powerful defenses against check fraud',
            ],
            ButtonText: 'Get started',
            ButtonLink: '/checks/personal-checks/customization',
            LearnMoreLink: '/checks/personal-checks',
          },
        ],
      },
    ],
    TopProductsSection: {
      HeaderText: 'Payments fraud happens to 82% of companies¹',
      SubHeaderText: 'QuickBooks Secure Checks help make sure it doesn’t happen to yours.',
      TopProducts: [
        {
          Name: (
            <span>
              <span className="font-wgt-700">Basic</span> Voucher Checks
            </span>
          ),
          Placeholder: 'basic-voucher-checks',
          GetStartedLink: '/bvc',
          LearnMoreLink: '/bvc',
          Description: null,
          ListInDesc: [
            'Print directly from QuickBooks to save time',
            'Minimal protection against fraud',
          ],
          QuantityInListDesc: true,
          PFID: 'CHECK-02',
          Quantity: true,
          HideOtherQuanties: true,
          image: basic_voucher_all_checks,
        },
        {
          Name: (
            <span>
              <span className="font-wgt-700">Secure Plus</span> Voucher Checks
            </span>
          ),
          Placeholder: 'secure-plus-voucher-check',
          GetStartedLink: '/spvc',
          isGetStartedPrimary: true,
          LearnMoreLink: '/spvc',
          Description: (
            <span>
              Advanced protection against fraud with{' '}
              <span className="font-wgt-700">23 security features,</span> including:
            </span>
          ),
          ListInDesc: [
            <span>
              An exclusive security coating to <span className="font-wgt-700">block tampering</span>
            </span>,
            <span>
              A heat-sensitive icon to <span className="font-wgt-700">prevent photocopying</span>
            </span>,
            <span>
              A custom watermark to <span className="font-wgt-700">protect against forgery</span>
            </span>,
          ],
          QuantityInListDesc: true,
          PFID: 'CHECK-11',
          Quantity: true,
          HideOtherQuanties: true,
          image: plus_voucher_all_checks,
        },
        {
          Name: (
            <span>
              <span className="font-wgt-700">Secure Premier</span> Voucher Checks
            </span>
          ),
          Placeholder: 'secure-premier-voucher-check',
          GetStartedLink: '/spvcpremier',
          LearnMoreLink: '/spvcpremier',
          Description: (
            <span>
              <span className="font-wgt-700">
                Our most powerful protections against fraud with 29 security features,
              </span>{' '}
              including a hologram for passport-level security for yourchecks.
            </span>
          ),
          ListInDesc: [
            <span>An exclusive security coating to block tampering</span>,
            <span>A heat-sensitive icon to prevent photocopying</span>,
            <span>A custom watermark to protect against forgery</span>,
            <span>
              A security hologram that makes your checks{' '}
              <span className="font-wgt-700">virtually impossible to counterfeit</span>
            </span>,
          ],
          QuantityInListDesc: true,
          PFID: 'CHECK-16',
          Quantity: true,
          HideOtherQuanties: true,
          image: premier_voucher_all_checks,
        },
      ],
    },
    shouldDisplayYouTubeSection: false,
    YouTubeSection: {
      title:
        'Our exclusive security coating helps keep the name and check amount safe from tampering.',
      vedioId: 'a42ypxvCry0',
      vedioMessage: 'Smarter security for your business checks(0:19)',
      productAdv: {
        productText: 'Get started with our Secure Plus Voucher Checks.',
        productLink: '/checks/voucher-checks/secure-plus-voucher-checks/customization',
      },
    },
    TermsAndConditions: ['1. 2019 AFP® Payments Fraud and Control Survey'],
    HasFaq: true,
    FaqDescription: {
      Header: 'QuickBooks Checks FAQ',
      LeftQuestionList: [
        {
          Header: 'How do business checks differ from personal checks?',
          Description: (
            <span>
              Business checks are usually larger than personal checks to allow space for more
              information – like the payee's address or an additional signature. They’ll also
              typically include stronger security features.{' '}
              <Link to={{ pathname: '/resources/checks/what-is-a-business-check' }} target="_blank">
                <strong>Learn more about business checks.</strong>
              </Link>
            </span>
          ),
        },
        {
          Header: 'Am I ordering the right business check?',
          Description: (
            <span>
              There are a variety of business checks to choose from depending on your needs. The
              most popular check we sell is the{' '}
              <Link
                to={{
                  pathname: '/spvc',
                }}
                target="_blank"
              >
                <strong>Secure Plus Voucher Check</strong>
              </Link>
              , but you can learn more about all the different types of business checks and{' '}
              <Link
                to={{
                  pathname: '/resources/checks/types-of-business-checks-why-it-matters',
                }}
                target="_blank"
              >
                <strong>which one is right for you</strong>
              </Link>{' '}
              in our resource center.
            </span>
          ),
        },
        {
          Header: 'How do I print business checks?',
          Description: (
            <span>
              QuickBooks printable checks are specifically designed to work with QuickBooks and
              print easily from most printers with the simple click of a button in QuickBooks. To
              learn more, check out our{' '}
              <Link
                to={{
                  pathname:
                    'https://quickbooks.intuit.com/learn-support/en-us/help-article/print-file/print-check-quickbooks-online/L0z74QkU3_US_en_US',
                }}
                target="_blank"
              >
                <strong>guide to printing checks from QuickBooks</strong>
              </Link>
              .
            </span>
          ),
        },
      ],
      RightQuestionList: [
        {
          Header: 'Can I add a company logo or customize my checks if I order online?',
          Description: (
            <span>
              Yes. During the check customization process you can add a logo, change the font or
              color used on the checks, add a second signature line, and add text to the signature
              line.{' '}
              <Link to={{ pathname: '/help/customization' }}>
                <strong>Learn more about customizing your checks. </strong>
              </Link>{' '}
              Not all options are available for all checks.
            </span>
          ),
        },
        {
          Header: 'Why choose secure business checks; can’t I just get them from the bank?',
          Description: (
            <span>
              With payment fraud happening to 82% of companies,
              <sup className="vertical-align-text-top">1</sup> you want to ensure that you’re doing
              everything you can to help protect your business. Checks from the bank might be
              cheaper, but they often don’t provide the level of security that QuickBooks secure
              checks do. Plus, QuickBooks checks are the only checks guaranteed to work with
              QuickBooks products.
            </span>
          ),
        },
      ],
    },
  },
};

export default ChecksPageData;
