import React from 'react';
import {
  endorsementStamp,
  mdsRouteProps,
  pdsRouteProps,
  returnAddressStamp,
  returnAddressStampWithMonogram,
  singlewindowformenvelope,
  faxHeadCoverStamp,
  customerBillingStatement,
  remittanceStatement,
  businessreplyenvelope,
  largemailingenvelope,
  productInvoice,
  serviceInvoice,
  professionalInvoice,
  multiPurposeForm,
  directDepositForms,
  securePlusVoucherChecks,
  securePremierVoucherChecks,
  basicVoucherChecks,
  securePlusStandardChecks,
  securePremierStandardChecks,
  officeAwayChecks,
  personalChecks,
  basicStandardChecks,
  securePlusWalletChecks,
  basicWalletChecks,
  manualChecks,
  voucherCheckValuePackPDS,
  voucherCheckValuePackMDS,
  basicCheckValuePackPDS,
  basicCheckValuePackMDS,
  standardValuePackPDS,
  standardValuePackMDS,
  voucherPremierValuePackPDS,
  voucherPremierValuePackMDS,
  voucherPlusValuePackPDS,
  voucherPlusValuePackMDS,
} from '../components/Customization/Configuration/CustomizationConfiguration';
import { ResourceCenterData } from '../../assets/pagedata/pages/ResourceCenterData';
import { getHostedRouteSignUp } from '../components/Util/SignInUtil';

const HomePage = React.lazy(() =>
  import(/* webpackChunkName: 'HomePage' */ '../components/Home/HomePage'),
);
const ProductListPage = React.lazy(() =>
  import(/* webpackChunkName: 'ProductListPage' */ '../components/ProductList/ProductListPage'),
);
const ProductPage = React.lazy(() =>
  import(/* webpackChunkName: 'ProductPage' */ '../components/Product/ProductPage'),
);
const QBDetectAndDefend = React.lazy(() =>
  import(
    /* webpackChunkName: 'QBDetectAndDefend' */ '../components/ResourceCenter/QBDetectAndDefend'
  ),
);
const CheckSecurityResources = React.lazy(() =>
  import(
    /* webpackChunkName: 'CheckSecurityResources' */ '../components/ResourceCenter/CheckSecurityResources'
  ),
);
const OrderLookup = React.lazy(() =>
  import(/* webpackChunkName: 'OrderLookup' */ '../components/OrderInformation/OrderLookup'),
);
const OrderStatusList = React.lazy(() =>
  import(
    /* webpackChunkName: 'OrderStatusList' */ '../components/OrderInformation/OrderStatusList'
  ),
);
const OrderDetails = React.lazy(() =>
  import(/* webpackChunkName: 'OrderDetails' */ '../components/OrderInformation/OrderDetails'),
);
const RetrievePreviousOrder = React.lazy(() =>
  import(
    /* webpackChunkName: 'RetrievePreviousOrder' */ '../components/RetrievePreviousOrder/RetrievePreviousOrder'
  ),
);
const Account = React.lazy(() =>
  import(/* webpackChunkName: 'Account' */ '../components/MyAccount/Account'),
);
const OrderConfirmationPrint = React.lazy(() =>
  import(
    /* webpackChunkName: 'OrderConfirmationPrint' */ '../components/Checkout/OrderConfirmationPrint'
  ),
);
const Cart = React.lazy(() =>
  import(/* webpackChunkName: 'Cart' */ '../components/Checkout/Cart/Cart'),
);
const Customization = React.lazy(() =>
  import(/* webpackChunkName: 'Customization' */ '../components/Customization/Customization'),
);
const ValuePackCustomization = React.lazy(() =>
  import(
    /* webpackChunkName: 'ValuePackCustomization' */ '../components/Customization/ValuePackCustomization'
  ),
);
const IusAccountrecoveryHelp = React.lazy(() =>
  import(
    /* webpackChunkName: 'IusAccountrecoveryHelp' */ '../components/Auth/IusAccountrecoveryHelp'
  ),
);
const ReorderPage = React.lazy(() =>
  import(/* webpackChunkName: 'ReorderPage' */ '../components/Auth/ReorderPage'),
);
const SearchPage = React.lazy(() =>
  import(/* webpackChunkName: 'SearchPage' */ '../components/Search/SearchPage'),
);
const CartSignInPage = React.lazy(() =>
  import(/* webpackChunkName: 'CartSignInPage' */ '../components/Auth/CartSignInPage'),
);
const AllChecksPage = React.lazy(() =>
  import(/* webpackChunkName: 'AllChecksPage' */ '../components/Product/AllChecksPage'),
);
const InterruptiveXsell = React.lazy(() =>
  import(
    /* webpackChunkName: 'InterruptiveXsell' */ '../components/InterruptiveXsell/InterruptiveXsell'
  ),
);
const Shipping = React.lazy(() =>
  import(/* webpackChunkName: 'Shipping' */ '../components/Checkout/Shipping/Shipping'),
);
const Billing = React.lazy(() =>
  import(/* webpackChunkName: 'Billing' */ '../components/Checkout/Billing/Billing'),
);
const MultiProductPage = React.lazy(() =>
  import(/* webpackChunkName: 'MultiProductPage' */ '../components/Product/MultiProductPage'),
);
const AllTaxFormPage = React.lazy(() =>
  import(/* webpackChunkName: 'AllTaxFormPage' */ '../components/TaxProducts/AllTaxFormPage'),
);
const OrderConfirmation = React.lazy(() =>
  import(
    /* webpackChunkName: 'OrderConfirmation' */ '../components/Checkout/OrderConfirmation/OrderConfirmation'
  ),
);
const SignOutPage = React.lazy(() =>
  import(/* webpackChunkName: 'SignOutPage' */ '../components/Auth/SignOutPage'),
);
const WelcomeBackSignIn = React.lazy(() =>
  import(/* webpackChunkName: 'WelcomeBackSignIn' */ '../components/Auth/WelcomeBackSignIn'),
);
const ReviewOrder = React.lazy(() =>
  import(/* webpackChunkName: 'ReviewOrder' */ '../components/Checkout/ReviewOrder/ReviewOrder'),
);
const InProductLanding = React.lazy(() =>
  import(
    /* webpackChunkName: 'InProductLanding' */ '../components/InProductLanding/InProductLanding'
  ),
);
const HelpPage = React.lazy(() =>
  import(/* webpackChunkName: 'HelpPage' */ '../components/Help/HelpPage'),
);
const UploadDisclaimer = React.lazy(() =>
  import(/* webpackChunkName: 'UploadDisclaimer' */ '../components/Common/UploadDisclaimer'),
);
const DiscountPage = React.lazy(() =>
  import(/* webpackChunkName: 'DiscountPage' */ '../components/Miscellaneous/DiscountPage'),
);
const ChecksAndSuppliesResourceCenter = React.lazy(() =>
  import(
    /* webpackChunkName: 'ChecksAndSuppliesResourceCenter' */ '../components/ResourceCenter/ChecksAndSuppliesResourceCenter'
  ),
);
const ResourceCenterArticleList = React.lazy(() =>
  import(
    /* webpackChunkName: 'ResourceCenterArticleList' */ '../components/ResourceCenter/ResourceCenterArticleList'
  ),
);
const ResourceReadMoreContent = React.lazy(() =>
  import(
    /* webpackChunkName: 'ResourceReadMoreContent' */ '../components/ResourceCenter/ResourceReadMoreContent'
  ),
);

const imRoutes = [
  {
    path: '/checks/voucher-checks',
    component: ProductListPage,
    contentVariable: 'VOUCHER_CHECKS',
    exactMatch: true,
  },
  {
    path: '/spvc',
    component: ProductPage,
    contentVariable: 'SECURE_PLUS_VOUCHER_CHECKS',
    exactMatch: true,
  },
  {
    path: '/checks/manual-checks',
    component: ProductPage,
    contentVariable: 'MANUAL_CHECKS',
    exactMatch: true,
  },
  {
    path: '/spvcpremier',
    component: ProductPage,
    contentVariable: 'SECURE_PREMIER_VOUCHER_CHECKS',
  },
  {
    path: '/bvc',
    component: ProductPage,
    contentVariable: 'BASIC_VOUCHER_CHECKS',
  },
  {
    path: '/checks/standard-checks',
    component: ProductListPage,
    contentVariable: 'STANDARD_CHECKS',
    exactMatch: true,
  },
  {
    path: '/checks/personal-checks',
    component: ProductPage,
    contentVariable: 'SECURE_PLUS_PERSONAL_CHECKS',
    exactMatch: true,
  },
  {
    path: '/checks/office-away-checks',
    component: ProductPage,
    contentVariable: 'OFFICE_AWAY_CHECKS',
    exactMatch: true,
  },
  {
    path: '/spsc',
    component: ProductPage,
    contentVariable: 'SECURE_PLUS_STANDARD_CHECKS',
  },
  {
    path: '/spsbc',
    component: ProductPage,
    contentVariable: 'SECURE_PREMIER_STANDARD_CHECKS',
  },
  {
    path: '/bsbc',
    component: ProductPage,
    contentVariable: 'BASIC_STANDARD_CHECKS',
  },
  {
    path: '/checks/wallet-checks',
    component: ProductListPage,
    contentVariable: 'BUSINESS_WALLET_CHECKS',
    exactMatch: true,
  },
  {
    path: '/spwc',
    component: ProductPage,
    contentVariable: 'SECURE_PLUS_WALLET_CHECKS',
  },
  {
    path: '/bwc',
    component: ProductPage,
    contentVariable: 'BASIC_WALLET_CHECKS',
  },
  // Envelopes
  {
    path: '/envelopes',
    component: ProductListPage,
    contentVariable: 'ENVELOPES',
    exactMatch: true,
  },
  {
    path: '/envelopes/check-window',
    component: ProductListPage,
    contentVariable: 'CHECKS_ENVELOPES',
    exactMatch: true,
  },
  {
    path: '/envelopes/check-window/self-seal-voucher',
    component: ProductPage,
    contentVariable: 'SELF_SEALED_VOUCHER_CHECK_ENVELOPES',
    exactMatch: true,
  },
  {
    path: '/envelopes/check-window/voucher-checks',
    component: ProductPage,
    contentVariable: 'STANDARD_AND_COUCHER_CHECK_ENVELOPES',
    exactMatch: true,
  },
  {
    path: '/envelopes/form-envelopes',
    component: ProductListPage,
    contentVariable: 'FORM_ENVELOPES',
    exactMatch: true,
  },
  {
    path: '/envelopes/form-envelopes/self-seal-form-envelopes',
    component: ProductPage,
    contentVariable: 'SELF_SEAL_FORMS_ENVELOPES',
    exactMatch: true,
  },
  {
    path: '/envelopes/form-envelopes/forms-envelopes',
    component: ProductPage,
    contentVariable: 'FORMS_ENVELOPES_PRODUCT',
    exactMatch: true,
  },
  {
    path: '/envelopes/form-envelopes/single-window-form-envelopes',
    component: ProductPage,
    contentVariable: 'SINGLE_WINDOW_FORM_ENVELOPES',
    exactMatch: true,
  },
  {
    path: '/envelopes/custom-printed-envelopes',
    component: ProductListPage,
    contentVariable: 'CUSTOM_ENVELOPES',
    exactMatch: true,
  },
  {
    path: '/envelopes/custom-printed-envelopes/business-reply',
    component: ProductPage,
    contentVariable: 'BUSINESS_REPLY_ENVELOPES',
    exactMatch: true,
  },
  {
    path: '/envelopes/custom-printed-envelopes/large-mailing-envelopes',
    component: ProductPage,
    contentVariable: 'LARGE_MAILING_ENVELOPES',
    exactMatch: true,
  },
  {
    path: '/wce-details',
    component: ProductPage,
    contentVariable: 'WALLET_CHECK_ENVELOPES',
    exactMatch: true,
  },
  {
    path: '/wpce',
    component: ProductPage,
    contentVariable: 'WALLET_PLUS_CHECK_ENVELOPES',
    exactMatch: true,
  },
  // // Deposit Slips
  {
    path: '/deposit-slips',
    component: ProductListPage,
    contentVariable: 'DEPOSIT_SLIPS',
    exactMatch: true,
  },
  {
    path: '/deposit-slips/printable',
    component: ProductPage,
    contentVariable: 'DEPOSIT_SLIP_PRINTABLE',
    exactMatch: true,
  },
  {
    path: '/deposit-slips/manual',
    component: ProductPage,
    contentVariable: 'DEPOSIT_SLIP_BOOKED_MANUAL',
    exactMatch: true,
  },
  {
    path: '/deposit-slips/disposable-deposit-bags',
    component: ProductPage,
    contentVariable: 'DEPOSIT_BAGS',
    exactMatch: true,
  },
  // Tax Products
  {
    path: '/tax-products',
    component: ProductListPage,
    contentVariable: 'TAX_PRODUCTS',
    exactMatch: true,
  },
  {
    path: '/tax-forms/w2-forms/laser-w-2-blank-envelope-kit',
    redirect: true,
    to: '/tax-forms/w2-forms/w-2-blank-envelope-kit',
  },
  {
    path: '/tax-forms/w2-forms',
    component: MultiProductPage,
    contentVariable: 'W2_KITS_FORMS',
    exactMatch: true,
  },
  {
    path: '/tax-forms/w2-forms/w-2-blank-envelope-kit',
    component: ProductPage,
    contentVariable: 'BLANK_W2_KITS',
    exactMatch: true,
  },
  {
    path: '/tax-products/tax-filing-envelopes',
    component: ProductListPage,
    contentVariable: 'TAX_FILING_ENVELOPES',
    exactMatch: true,
  },
  {
    path: '/tax-products/organizer-envelopes',
    component: ProductListPage,
    contentVariable: 'TAX_ORGANIZER_ENVELOPES',
    exactMatch: true,
  },
  {
    path: '/tax-forms/w2-forms/pre-printed-w-2-kits',
    component: ProductPage,
    contentVariable: 'PRE_PRINT_W2_KITS',
    exactMatch: true,
  },
  {
    path: '/tax-forms/w3-forms',
    component: ProductPage,
    contentVariable: 'PRE_PRINT_W3_Form',
    exactMatch: true,
  },
  {
    path: '/tax-forms/1096-forms',
    component: ProductPage,
    contentVariable: 'PRE_PRINT_1096_Form',
    exactMatch: true,
  },
  {
    path: '/tax-products/tax-return-folders',
    component: ProductListPage,
    contentVariable: 'TAX_RETURN_FOLDERS',
    exactMatch: true,
  },
  {
    path: '/tax-products/tax-return-folders/small-window-premium-tax-return-folders',
    component: ProductPage,
    contentVariable: 'SMALL_PREMIUM_TAX_RETURN_FOLDER',
    exactMatch: true,
  },
  {
    path: '/tax-products/tax-return-folders/large-window-premium-tax-return-folders',
    component: ProductPage,
    contentVariable: 'LARGE_PREMIUM_TAX_RETURN_FOLDER',
    exactMatch: true,
  },
  {
    path: '/tax-products/tax-return-folders/standard-tax-return-folders',
    component: ProductPage,
    contentVariable: 'STANDARD_TAX_RETURN_FOLDER',
    exactMatch: true,
  },
  {
    path: '/tax-products/tax-return-folders/two-pocket-folders',
    component: ProductPage,
    contentVariable: 'TWO_POCKET_FOLDER',
    exactMatch: true,
  },
  {
    path: '/tax-forms',
    component: AllTaxFormPage,
    contentVariable: 'ALL_TAX_FORM',
    exactMatch: true,
  },
  // // Office & POS
  {
    path: '/supplies',
    component: ProductListPage,
    contentVariable: 'OFFICE_POS_SUPPLIES',
    exactMatch: true,
  },
  {
    path: '/supplies/stamps',
    component: ProductListPage,
    contentVariable: 'SELF_INKING_STAMPS',
    exactMatch: true,
  },
  {
    path: '/supplies/pos',
    component: ProductListPage,
    contentVariable: 'QB_POS_SUPPLIES',
    exactMatch: true,
  },
  {
    path: '/supplies/receipt-paper',
    component: ProductPage,
    contentVariable: 'POS_PAPER_ROLLS',
    exactMatch: true,
  },
  {
    path: '/supplies/labels/laser-labels',
    component: ProductListPage,
    contentVariable: 'PRINTABLE_LASER_AND_INKJET_LABELS',
    exactMatch: true,
  },
  {
    path: '/supplies/labels/avery-labels-5161',
    component: ProductPage,
    contentVariable: 'AVERY_LABELS_5161',
    exactMatch: true,
  },
  {
    path: '/supplies/file-folder-labels-5366-avery',
    component: ProductPage,
    contentVariable: 'AVERY_LABELS_5366',
    exactMatch: true,
  },
  {
    path: '/supplies/labels/avery-labels-5160',
    component: ProductPage,
    contentVariable: 'AVERY_LABELS_5160',
    exactMatch: true,
  },
  {
    path: '/supplies/labels/avery-labels-5167',
    component: ProductPage,
    contentVariable: 'AVERY_LABELS_5167',
    exactMatch: true,
  },
  {
    path: '/stampsandlabels/endorsement-stamps',
    component: ProductPage,
    contentVariable: 'SELFINK_CUSTOM_BANK_ENDORSEMENT_STAMPS',
    exactMatch: true,
  },
  {
    path: '/stampsandlabels/return-address-stamps',
    component: ProductPage,
    contentVariable: 'PERSONALIZED_RETURN_ADDRESS_STAMP',
    exactMatch: true,
  },
  {
    path: '/stampsandlabels/monogram-stamps',
    component: ProductPage,
    contentVariable: 'RETURN_ADDRESS_STAMP_MONOGRAM',
    exactMatch: true,
  },
  {
    path: '/stampsandlabels/fax-cover-stamps',
    component: ProductPage,
    contentVariable: 'FAX_HEADER_COVER_STAMP',
    exactMatch: true,
  },
  {
    path: '/stampsandlabels/security-stamps',
    component: ProductPage,
    contentVariable: 'SECURITY_STAMP',
    exactMatch: true,
  },
  {
    path: '/stampsandlabels/copy-stamp',
    component: ProductPage,
    contentVariable: 'COPY_STAMP',
    exactMatch: true,
  },
  {
    path: '/stampsandlabels/past-due-stamp',
    component: ProductPage,
    contentVariable: 'PAST_DUE_STAMP',
    exactMatch: true,
  },
  {
    path: '/stampsandlabels/file-copy-stamp',
    component: ProductPage,
    contentVariable: 'FILE_STAMP',
    exactMatch: true,
  },
  {
    path: '/stampsandlabels/paid-stamp',
    component: ProductPage,
    contentVariable: 'PAID_STAMP',
    exactMatch: true,
  },
  {
    path: '/stampsandlabels/void-stamp',
    component: ProductPage,
    contentVariable: 'VOID_STAMP',
    exactMatch: true,
  },
  {
    path: '/stampsandlabels/confidential-stamp',
    component: ProductPage,
    contentVariable: 'CONFIDENTIAL_STAMP',
    exactMatch: true,
  },
  {
    path: '/stampsandlabels/fixed-stamp',
    component: ProductPage,
    contentVariable: 'FAXED_STAMP',
    exactMatch: true,
  },
  {
    path: '/stampsandlabels/urgent-stamp',
    component: ProductPage,
    contentVariable: 'URGENT_STAMP',
    exactMatch: true,
  },
  {
    path: '/stampsandlabels/draft-stamp',
    component: ProductPage,
    contentVariable: 'DRAFT_STAMP',
    exactMatch: true,
  },
  {
    path: '/stampsandlabels/price-labels',
    component: ProductPage,
    contentVariable: 'PRICE_LABEL_12_085',
    exactMatch: true,
  },
  {
    path: '/stampsandlabels/large-price-labels',
    component: ProductPage,
    contentVariable: 'PRICE_LABEL_225_123',
    exactMatch: true,
  },
  {
    path: '/stampsandlabels/hang-tags-quickbooks',
    component: ProductPage,
    contentVariable: 'PRINTABLE_HANG_TAGS',
    exactMatch: true,
  },
  {
    path: '/stampsandlabels/jewelry-tags-quickbooks',
    component: ProductPage,
    contentVariable: 'JEWELRY_TAG_WITH_FLAP',
    exactMatch: true,
  },
  {
    path: '/stampsandlabels/jewelry-tags',
    component: ProductPage,
    contentVariable: 'JEWELRY_TAG_WITHOUT_FLAP',
    exactMatch: true,
  },
  {
    path: '/stampsandlabels/qb-detect-and-defend',
    component: QBDetectAndDefend,
    contentVariable: 'QB_DETECT_AND_DEFEND',
    exactMatch: true,
  },
  {
    path: '/stampsandlabelsresources',
    component: CheckSecurityResources,
    contentVariable: 'CHECK_SECURITY_RESOURCES',
    exactMatch: true,
  },
  // Binders
  {
    path: '/supplies/binders',
    component: ProductListPage,
    contentVariable: 'BUSINESS_CHECK_BINDERS',
    exactMatch: true,
  },
  {
    path: '/supplies/binders/away-check-binder',
    component: ProductPage,
    contentVariable: 'AWAY_CHECK_BINDER',
    exactMatch: true,
  },
  {
    path: '/supplies/binders/manual-check-binder',
    component: ProductPage,
    contentVariable: 'RING_7_CHECK_BINDER',
    exactMatch: true,
  },
  // Business Forms
  {
    path: '/supplies/forms',
    component: ProductListPage,
    contentVariable: 'BUSINESS_FORMS',
    exactMatch: true,
  },
  {
    path: '/supplies/forms/form-leaders',
    component: ProductPage,
    contentVariable: 'FORM_LEADER',
    exactMatch: true,
  },
  // Security Pen
  {
    path: '/supplies/security-pens',
    component: ProductPage,
    contentVariable: 'SECURITY_PEN',
    exactMatch: true,
  },
  // Resources
  // {
  //   path: '/resources',
  //   component: CheckSecurityResources,
  //   contentVariable: 'RESOURCES',
  // },
  {
    path: '/secure-products',
    component: QBDetectAndDefend,
    contentVariable: 'QBDetectAndDefend',
    exactMatch: true,
  },
  {
    path: '/resources',
    component: ChecksAndSuppliesResourceCenter,
    exactMatch: true,
  },
  {
    path: '/resources/checks',
    component: ResourceCenterArticleList,
    contentVariable: 'CHECKS_RESOURCE_CENTER',
    exactMatch: true,
  },
  {
    path: '/resources/checks/types-of-business-checks-why-it-matters',
    component: ResourceReadMoreContent,
    contentVariable: ResourceCenterData.readMoreArticleData.checks.typeofBusinessChceks,
    exactMatch: true,
  },
  {
    path: '/resources/fraud-prevention/4-questions-to-ask-when-choosing-checks',
    component: ResourceReadMoreContent,
    contentVariable: ResourceCenterData.readMoreArticleData.checks.fourBusinessChecks,
    exactMatch: true,
  },
  {
    path: '/resources/fraud-prevention/7-most-common-business-check-mistakes',
    component: ResourceReadMoreContent,
    contentVariable: ResourceCenterData.readMoreArticleData.checks.sevenBusinessChecks,
    exactMatch: true,
  },
  {
    path: '/resources/checks/what-is-a-business-check',
    component: ResourceReadMoreContent,
    contentVariable: ResourceCenterData.readMoreArticleData.checks.whatIsBusinessCheck,
    exactMatch: true,
  },
  {
    path: '/resources/checks/endorsements',
    component: ResourceReadMoreContent,
    contentVariable: ResourceCenterData.readMoreArticleData.checks.endorsements,
    exactMatch: true,
  },
  {
    path: '/resources/checks/check-21',
    component: ResourceReadMoreContent,
    contentVariable: ResourceCenterData.readMoreArticleData.checks.check21,
    exactMatch: true,
  },
  {
    path: '/resources/checks/check-printing-software',
    component: ResourceReadMoreContent,
    contentVariable: ResourceCenterData.readMoreArticleData.checks.checkPrintingSoftware,
    exactMatch: true,
  },
  {
    path: '/resources/tax-forms',
    component: ResourceCenterArticleList,
    contentVariable: 'TAX_FORMS_RESOURCE_CENTER',
    exactMatch: true,
  },
  {
    path: '/resources/tax-forms/w2-w3',
    component: ResourceReadMoreContent,
    contentVariable: ResourceCenterData.readMoreArticleData.taxForms.w2_w3,
    exactMatch: true,
  },
  {
    path: '/resources/tax-forms/1099-1096',
    component: ResourceReadMoreContent,
    contentVariable: ResourceCenterData.readMoreArticleData.taxForms.taxForms1099_1096,
    exactMatch: true,
  },
  {
    path: '/resources/fraud-prevention',
    component: ResourceCenterArticleList,
    contentVariable: 'CHECK_FRAUD_RESOURCE_CENTER',
    exactMatch: true,
  },
  {
    path: '/resources/fraud-prevention/counterfeiting',
    component: ResourceReadMoreContent,
    contentVariable: ResourceCenterData.readMoreArticleData.checkFraud.counterFeiting,
    exactMatch: true,
  },
  {
    path: '/resources/fraud-prevention/5-things-to-look-for',
    component: ResourceReadMoreContent,
    contentVariable: ResourceCenterData.readMoreArticleData.checkFraud.fiveThingsToLookFor,
    exactMatch: true,
  },
  {
    path: '/resources/fraud-prevention/security-check-features',
    component: ResourceReadMoreContent,
    contentVariable: ResourceCenterData.readMoreArticleData.checkFraud.securityCheckFeature,
    exactMatch: true,
  },
  {
    path: '/resources/fraud-prevention/it-can-happen-to-you',
    component: ResourceReadMoreContent,
    contentVariable: ResourceCenterData.readMoreArticleData.checkFraud.itCanHappenToYou,
    exactMatch: true,
  },
  {
    path: '/resources/fraud-prevention/how-to-dispose-of-checks',
    component: ResourceReadMoreContent,
    contentVariable: ResourceCenterData.readMoreArticleData.checkFraud.howToDisposeOfChecks,
    exactMatch: true,
  },
  {
    path: '/resources/business-fraud-prevention',
    component: ResourceCenterArticleList,
    contentVariable: 'BUSINESS_FRAUD_RESOURCE_CENTER',
    exactMatch: true,
  },
  {
    path: '/resources/business-fraud-prevention/10-ways-employees-stop-scams',
    component: ResourceReadMoreContent,
    contentVariable: ResourceCenterData.readMoreArticleData.businessFraud.TenWaysStopScam,
    exactMatch: true,
  },
  {
    path: '/resources/business-fraud-prevention/6-scams-to-know-and-avoid',
    component: ResourceReadMoreContent,
    contentVariable: ResourceCenterData.readMoreArticleData.businessFraud.sixScamToAvoid,
    exactMatch: true,
  },
  {
    path: '/resources/business-fraud-prevention/tax-scams-to-avoid',
    component: ResourceReadMoreContent,
    contentVariable: ResourceCenterData.readMoreArticleData.businessFraud.taxScamAvoid,
    exactMatch: true,
  },

  // My Account routes
  {
    path: '/orderhistory',
    component: Account,
    contentVariable: 'ACCOUNT',
    exactMatch: true,
    props: {
      isSelectedTab: 'orderHistory',
      authenticatedRoute: true,
      unAuthenticatedRoute: '/reorder',
    },
  },
  {
    path: '/savedworklogo',
    component: Account,
    contentVariable: 'ACCOUNT',
    exactMatch: true,
    props: {
      isSelectedTab: 'customizationOptions',
      authenticatedRoute: true,
      unAuthenticatedRoute: '/signin',
    },
  },
  {
    path: '/account',
    component: Account,
    contentVariable: 'ACCOUNT',
    exactMatch: true,
    props: {
      isSelectedTab: 'signInInformation',
      authenticatedRoute: true,
      unAuthenticatedRoute: '/signin',
    },
  },
  {
    path: '/shipbill',
    component: Account,
    contentVariable: 'ACCOUNT',
    exactMatch: true,
    props: {
      isSelectedTab: 'shippingAndBilling',
      authenticatedRoute: true,
      unAuthenticatedRoute: '/signin',
    },
  },

  {
    path: '/printreceipt',
    component: OrderConfirmationPrint,
    contentVariable: 'ORDERCONFIRMATIONPRINT',
  },

  {
    path: '/orderstatus/lookup',
    component: OrderLookup,
    contentVariable: 'ORDERSTATUS',
    exactMatch: true,
  },
  {
    path: '/orderstatus/list',
    component: OrderStatusList,
    contentVariable: 'ORDERSTATUSLIST',
  },
  {
    path: '/orderstatus/detail',
    component: OrderDetails,
    contentVariable: 'ORDERDETAILS',
  },
  // Retrieve Previous Order
  {
    path: '/retrievepreviousorder',
    component: RetrievePreviousOrder,
    contentVariable: 'RETRIEVE_PREVIOUS_ORDER',
  },
  // Cart
  {
    path: '/cart',
    component: Cart,
    contentVariable: 'CART',
    exactMatch: true,
  },
  // Customization
  {
    path: '/deposit-slips/printable/customization',
    component: Customization,
    contentVariable: 'CUSTOMIZATION_PDS',
    props: {
      additionalProps: pdsRouteProps,
    },
    exactMatch: true,
  },
  {
    path: '/deposit-slips/manual/customization',
    component: Customization,
    contentVariable: 'CUSTOMIZATION_MDS',
    props: {
      additionalProps: mdsRouteProps,
    },
    exactMatch: true,
  },
  {
    path: '/stampsandlabels/endorsement-stamps/customization',
    component: Customization,
    contentVariable: 'CUSTOMIZATION_ENDORSEMENT_STAMP',
    props: {
      additionalProps: endorsementStamp,
    },
    exactMatch: true,
  },
  {
    path: '/stampsandlabels/return-address-stamps/customization',
    component: Customization,
    contentVariable: 'CUSTOMIZATION_RETURN_ADDRESS_STAMP',
    props: {
      additionalProps: returnAddressStamp,
    },
    exactMatch: true,
  },
  {
    path: '/stampsandlabels/monogram-stamps/customization',
    component: Customization,
    contentVariable: 'CUSTOMIZATION_RETURN_ADDRESS_STAMP_MONOGRAM',
    props: {
      additionalProps: returnAddressStampWithMonogram,
    },
    exactMatch: true,
  },
  {
    path: '/stampsandlabels/fax-cover-stamps/customization',
    component: Customization,
    contentVariable: 'CUSTOMIZATION_FAX_COVER_HEAD_STAMP',
    props: {
      additionalProps: faxHeadCoverStamp,
    },
    exactMatch: true,
  },
  // Statements
  {
    path: '/supplies/forms/business-statements',
    component: ProductListPage,
    contentVariable: 'BUSINESS_STATEMENTS',
    exactMatch: true,
  },
  {
    path: '/supplies/forms/blank-remittance-statements',
    component: ProductPage,
    contentVariable: 'BLANK_REMITTANCE_STATEMENT',
    exactMatch: true,
  },
  {
    path: '/supplies/forms/business-statements/customer/customization',
    component: Customization,
    contentVariable: 'CUSTOMIZATION_CUSTOMER_BILL_STMT',
    props: {
      additionalProps: customerBillingStatement,
    },
    exactMatch: true,
  },
  {
    path: '/supplies/forms/business-statements/remittance/customization',
    component: Customization,
    contentVariable: 'CUSTOMIZATION_CUSTOMER_REMI_STMT',
    props: {
      additionalProps: remittanceStatement,
    },
    exactMatch: true,
  },
  // invoices
  {
    path: '/supplies/forms/invoices',
    component: ProductListPage,
    contentVariable: 'BUSINESS_INVOICES',
    exactMatch: true,
  },
  {
    path: '/supplies/forms/invoices/product/customization',
    component: Customization,
    contentVariable: 'CUSTOMIZATION_CUSTOMER_PRODUCT_INVOICE',
    props: {
      additionalProps: productInvoice,
    },
    exactMatch: true,
  },
  {
    path: '/supplies/forms/invoices/service/customization',
    component: Customization,
    contentVariable: 'CUSTOMIZATION_CUSTOMER_SERVICE_INVOICE',
    props: {
      additionalProps: serviceInvoice,
    },
    exactMatch: true,
  },
  {
    path: '/supplies/forms/invoices/professional/customization',
    component: Customization,
    contentVariable: 'CUSTOMIZATION_CUSTOMER_PROFESSIONAL_INVOICE',
    props: {
      additionalProps: professionalInvoice,
    },
    exactMatch: true,
  },
  // xsell path
  {
    path: '/interruptivexsell',
    component: InterruptiveXsell,
    contentVariable: 'INTERRUPTIVE_XSELL',
  },
  {
    path: '/envelopes/custom-printed-envelopes/single-window-form-envelopes/customization',
    component: Customization,
    contentVariable: 'CUSTOMIZATION_BUSINESS_REPLY_ENVELOPE',
    props: {
      additionalProps: singlewindowformenvelope,
    },
  },
  {
    path: '/envelopes/custom-printed-envelopes/business-reply-envelopes/customization',
    component: Customization,
    contentVariable: 'CUSTOMIZATION_SINGLE_WINDOW_FORM_ENVELOPE',
    props: {
      additionalProps: businessreplyenvelope,
    },
  },
  {
    path: '/envelopes/custom-printed-envelopes/large-mailing-envelopes/customization',
    component: Customization,
    contentVariable: 'CUSTOMIZATION_LARGE_MAILING_ENVELOPE',
    props: {
      additionalProps: largemailingenvelope,
    },
  },
  { path: '/shipping', component: Shipping, contentVariable: 'SHIPPING', exactMatch: true },
  {
    path: '/tax-forms/1099-forms',
    component: MultiProductPage,
    contentVariable: 'PRE_PRINT_1099_FORMS',
    exactMatch: true,
  },
  {
    path: '/billing',
    component: Billing,
    contentVariable: 'BILLING',
    exactMatch: true,
  },
  {
    path: '/confirmation',
    component: OrderConfirmation,
    contentVariable: 'CONFIRMATION',
    exactMatch: true,
  },
  {
    path: '/supplies/forms/multi-purpose-forms/multi-purpose-forms/customization',
    component: Customization,
    contentVariable: 'CUSTOMIZATION_MULTI_PURPOSE_FORM',
    props: {
      additionalProps: multiPurposeForm,
    },
  },
  {
    path: '/supplies/forms/multi-purpose-forms',
    component: ProductListPage,
    contentVariable: 'MULTI_PURPOSE_FORMS',
    exactMatch: true,
  },
  {
    path: '/supplies/forms/all-purpose-forms',
    component: ProductPage,
    contentVariable: 'ALL_PURPOSE_FORM',
    exactMatch: true,
  },
  {
    path: '/summary',
    component: ReviewOrder,
    contentVariable: 'SUMMARY',
    exactMatch: true,
  },
  {
    path: '/checks/direct-deposit-forms/customization',
    component: Customization,
    contentVariable: 'CUSTOMIZATION_DIRECT_DEPOSIT_FORMS',
    props: {
      additionalProps: directDepositForms,
    },
  },
  {
    path: '/checks/direct-deposit-forms',
    component: ProductPage,
    contentVariable: 'DIRECT_DEPOSIT_FORMS',
  },
  {
    path: '/checks/voucher-checks/secure-plus-voucher-checks/customization',
    component: Customization,
    contentVariable: 'CUSTOMIZATION_SECURE_PLUS_VOUCHER_CHECK',
    props: {
      additionalProps: securePlusVoucherChecks,
    },
  },
  {
    path: '/checks/voucher-checks/secure-premier-voucher-checks/customization',
    component: Customization,
    contentVariable: 'CUSTOMIZATION_SECURE_PREMIER_VOUCHER_CHECK',
    props: {
      additionalProps: securePremierVoucherChecks,
    },
  },
  {
    path: '/checks/voucher-checks/basic-voucher-checks/customization',
    component: Customization,
    contentVariable: 'CUSTOMIZATION_BASIC_VOUCHER_CHECK',
    props: {
      additionalProps: basicVoucherChecks,
    },
  },
  {
    path: '/checks/standard-checks/secure-plus-standard-business-checks/customization',
    component: Customization,
    contentVariable: 'CUSTOMIZATION_SECURE_PLUS_STANDARD_BUSINESS_CHECK',
    props: {
      additionalProps: securePlusStandardChecks,
    },
  },
  {
    path: '/checks/standard-checks/secure-premier-standard-business-checks/customization',
    component: Customization,
    contentVariable: 'CUSTOMIZATION_SECURE_PREMIER_STANDARD_BUSINESS_CHECK',
    props: {
      additionalProps: securePremierStandardChecks,
    },
  },
  {
    path: '/checks/standard-checks/basic-standard-business-checks/customization',
    component: Customization,
    contentVariable: 'CUSTOMIZATION_BASIC_STANDARD_BUSINESS_CHECK',
    props: {
      additionalProps: basicStandardChecks,
    },
  },
  {
    path: '/checks/wallet-checks/secure-plus-wallet-checks/customization',
    component: Customization,
    contentVariable: 'CUSTOMIZATION_SECURE_PLUS_WALLET_CHECK',
    props: {
      additionalProps: securePlusWalletChecks,
    },
  },
  {
    path: '/checks/wallet-checks/basic-wallet-checks/customization',
    component: Customization,
    contentVariable: 'CUSTOMIZATION_BASIC_WALLET_CHECK',
    props: {
      additionalProps: basicWalletChecks,
    },
  },
  {
    path: '/checks/manual-checks/customization',
    component: Customization,
    contentVariable: 'CUSTOMIZATION_MANUAL_CHECK',
    props: {
      additionalProps: manualChecks,
    },
  },
  {
    path: '/checks/office-away-checks/customization',
    component: Customization,
    contentVariable: 'CUSTOMIZATION_OFFICE_AWAY_CHECK',
    props: {
      additionalProps: officeAwayChecks,
    },
    exactMatch: true,
  },
  {
    path: '/checks/personal-checks/customization',
    component: Customization,
    contentVariable: 'CUSTOMIZATION_PERSONAL_CHECK',
    props: {
      additionalProps: personalChecks,
    },
    exactMatch: true,
  },
  {
    path: '/checks',
    component: AllChecksPage,
    contentVariable: 'ALL_CHECKS_PAGE',
    exactMatch: true,
  },
  {
    path: '/checks/voucher-check-value-pack/customization',
    component: ValuePackCustomization,
    contentVariable: 'CUSTOMIZATION_VOUCHER_CHECK_VALUE_PACK',
    props: {
      bundleProps: {
        PDS: voucherCheckValuePackPDS,
        MDS: voucherCheckValuePackMDS,
      },
    },
    exactMatch: true,
  },
  {
    path: '/checks/basic-check-value-pack/customization',
    component: ValuePackCustomization,
    contentVariable: 'CUSTOMIZATION_BASIC_CHECK_VALUE_PACK',
    props: {
      bundleProps: {
        PDS: basicCheckValuePackPDS,
        MDS: basicCheckValuePackMDS,
      },
    },
    exactMatch: true,
  },
  {
    path: '/checks/standard-value-pack/customization',
    component: ValuePackCustomization,
    contentVariable: 'CUSTOMIZATION_STANDARD_VALUE_PACK',
    props: {
      bundleProps: {
        PDS: standardValuePackPDS,
        MDS: standardValuePackMDS,
      },
    },
    exactMatch: true,
  },
  {
    path: '/checks/voucher-check-value-pack',
    component: ProductPage,
    contentVariable: 'VOUCHER_CHECK_VALUE_PACK',
    exactMatch: true,
  },
  {
    path: '/checks/basic-check-value-pack',
    component: ProductPage,
    contentVariable: 'BASIC_CHECK_VALUE_PACK',
    exactMatch: true,
  },
  {
    path: '/checks/standard-value-pack',
    component: ProductPage,
    contentVariable: 'STANDARD_VALUE_PACK',
    exactMatch: true,
  },
  {
    path: '/checks/value-packs',
    component: ProductListPage,
    contentVariable: 'VALUE_PACKS',
    exactMatch: true,
  },
  // Voucher Check Value Pack Promotion Pages
  {
    path: '/checks/voucher-check-value-packs',
    component: ProductListPage,
    contentVariable: 'VOUCHER_VALUE_PACKS',
    conditionalLoad: true,
    exactMatch: true,
  },
  {
    path: '/checks/secure-premier-check-value-pack',
    component: ProductPage,
    contentVariable: 'VOUCHER_PREMIER_VALUE_PACKS',
    conditionalLoad: true,
    exactMatch: true,
  },
  {
    path: '/checks/secure-plus-check-value-pack',
    component: ProductPage,
    contentVariable: 'VOUCHER_PLUS_VALUE_PACKS',
    conditionalLoad: true,
    exactMatch: true,
  },
  {
    path: '/checks/secure-premier-check-value-pack/customization',
    component: ValuePackCustomization,
    contentVariable: 'CUSTOMIZATION_VOUCHER_PREMIER_VALUE_PACKS',
    props: {
      bundleProps: {
        PDS: voucherPremierValuePackPDS,
        MDS: voucherPremierValuePackMDS,
      },
    },
    conditionalLoad: true,
    exactMatch: true,
  },
  {
    path: '/checks/secure-plus-check-value-pack/customization',
    component: ValuePackCustomization,
    contentVariable: 'CUSTOMIZATION_VOUCHER_PLUS_VALUE_PACKS',
    props: {
      bundleProps: {
        PDS: voucherPlusValuePackPDS,
        MDS: voucherPlusValuePackMDS,
      },
    },
    conditionalLoad: true,
    exactMatch: true,
  },
  {
    // Home page route
    path: '/',
    component: HomePage,
    contentVariable: 'HOME_PAGE',
    exactMatch: true,
  },
  {
    path: '/signout',
    component: SignOutPage,
    exactMatch: true,
  },
  {
    path: '/signin',
    component: WelcomeBackSignIn,
    contentVariable: 'HOME_PAGE',
    exactMatch: true,
  },
  {
    path: '/iussignup',
    redirectExternal: getHostedRouteSignUp('orderhistory'),
    exactMatch: true,
  },
  {
    path: '/iusupgrade',
    redirectExternal: getHostedRouteSignUp('orderhistory'),
    exactMatch: true,
  },
  {
    path: '/in-product-landing-page',
    component: InProductLanding,
    contentVariable: 'IN_PRODUCT_LANDING_PAGE',
    exactMatch: true,
    redirectWhenLoggedIn: true,
    to: '/checks',
  },
  {
    path: '/iusaccountrecoveryhelp',
    component: IusAccountrecoveryHelp,
    exactMatch: true,
  },
  {
    path: '/reorder',
    component: ReorderPage,
    contentVariable: 'HOME_PAGE',
    exactMatch: true,
    redirectWhenLoggedIn: true,
    to: '/orderhistory',
  },
  { path: '/help', component: HelpPage, exactMatch: true, contentVariable: 'HELP' },
  {
    path: '/help/customization',
    component: HelpPage,
    exactMatch: true,
    contentVariable: 'HELP',
  },
  {
    path: '/help/ordering-and-shipping',
    component: HelpPage,
    exactMatch: true,
    contentVariable: 'HELP',
  },
  {
    path: '/help/contact-intuit-market',
    component: HelpPage,
    exactMatch: true,
    contentVariable: 'HELP',
  },
  {
    path: '/help/account',
    component: HelpPage,
    exactMatch: true,
    contentVariable: 'HELP',
  },
  {
    path: '/shipping-handling-faq',
    component: HelpPage,
    exactMatch: true,
    contentVariable: 'HELP',
  },
  {
    path: '/termsofservice',
    redirect: true,
    to: '/help/termsofservice',
  },
  {
    path: '/help/termsofservice',
    component: HelpPage,
    contentVariable: 'HELP',
  },
  {
    path: '/search',
    component: SearchPage,
    exactMatch: true,
  },

  {
    path: '/cartsignin',
    component: CartSignInPage,
    contentVariable: 'HOME_PAGE',
    exactMatch: true,
    redirectWhenLoggedIn: true,
    to: '/shipping',
  },
  {
    path: '/file-upload-disclaimer',
    component: UploadDisclaimer,
    contentVariable: 'HOME_PAGE',
    exactMatch: true,
  },
  {
    path: '/discounts',
    component: DiscountPage,
    contentVariable: 'DISCOUNT',
    exactMatch: true,
  },
];

export default imRoutes;