import React from 'react';
import { Link } from 'react-router-dom';
import {
  ChecksPageData,
  EnvelopesPageData,
  DepositSlipsData,
  StampsData,
  BusinessFormsPageData,
  AllTaxPageData,
  HomePageData,
  HelpPageData,
  DiscountPageData,
} from './pages';
import w2k120 from '../images/IM/Tax Forms/tax_w2k_120.png';
import OptimizedW2k120 from '../images/IM/Tax Forms/tax_w2k_120Op.webp';
import w2k340 from '../images/IM/Tax Forms/tax_w2k_340.png';
import OptimizedW2k340 from '../images/IM/Tax Forms/tax_w2k_340Op.webp';
import taxw3120 from '../images/IM/Tax Forms/tax_w3_120.png';
import OptimizedTaxw3120 from '../images/IM/Tax Forms/tax_w3_120Op.webp';
import taxw3340 from '../images/IM/Tax Forms/tax_w3_340.png';
import OptimizedTaxw3340 from '../images/IM/Tax Forms/tax_w3_340Op.webp';
import tax96120 from '../images/IM/Tax Forms/tax_96_120.png';
import OptimizedTax96120 from '../images/IM/Tax Forms/tax_96_120Op.webp';
import tax96340 from '../images/IM/Tax Forms/tax_96_340.png';
import OptimizedTax96340 from '../images/IM/Tax Forms/tax_96_340Op.webp';
import blankW2k from '../images/IM/Tax Forms/Tax_w2B_277.png';
import blankW2kLg from '../images/IM/Tax Forms/Tax_w2B_277_lg.png';
import taxNec120 from '../images/IM/Tax Forms/tax_99_120_nec.png';
import OptimizedTaxNec120 from '../images/IM/Tax Forms/tax_99_120_necOp.webp';
import taxNec340 from '../images/IM/Tax Forms/tax_99_340_nec.png';
import OptimizedTaxNec340 from '../images/IM/Tax Forms/tax_99_340_necOp.webp';
import taxMisc120 from '../images/IM/Tax Forms/tax_99_120.png';
import OptimizedTaxMisc120 from '../images/IM/Tax Forms/tax_99_120Op.webp';
import taxMisc340 from '../images/IM/Tax Forms/tax_99_340.png';
import OptimizedTaxMisc340 from '../images/IM/Tax Forms/tax_99_340Op.webp';

// Stamps

// import frmSelfSeal from '../images/IM/Envelopes/LU_image_page_selfse6x9_ReturnFiling_sm6x9_ReturnFiling_smalforms.png';
import env911RetFil from '../images/IM/Envelopes/9-5x11-5_ReturnFiling_sm.jpg';
import env911RetFillg from '../images/IM/Envelopes/9-5x11-5_ReturnFiling_lg.png';
import env69RetFil from '../images/IM/Envelopes/6x9_ReturnFiling_sm.jpg';
import env69RetFillg from '../images/IM/Envelopes/6x9_ReturnFiling_lg.png';
import env1040RetFilSm from '../images/IM/Envelopes/1040_VES_ReturnFiling_sm.jpg';
import env1040RetFilLg from '../images/IM/Envelopes/1040_VES_ReturnFiling_lg.png';
import env69RetFilsm from '../images/IM/Envelopes/9-5x11-5_ReturnFiling_sm.jpg';
// tax organizer
import envTO911SmallWinSm from '../images/IM/Envelopes/9-5x11-5_SmallWindow_sm.jpg';
import envTO911SmallWinLg from '../images/IM/Envelopes/9-5x11-5_SmallWindow_lg.jpg';
import envTO911LrgWinSm from '../images/IM/Envelopes/9-5x11-5_LargeWindow_sm.jpg';
import envTO911LrgWinLg from '../images/IM/Envelopes/9-5x11-5_LargeWindow_lg.jpg';
import envTO911LandWinSm from '../images/IM/Envelopes/9-5x11-5_Landscape_sm.jpg';
import envTO1012LandWinLg from '../images/IM/Envelopes/10-25x12_Expandable_Landscape_lg.jpg';
import envTO911LandSSWinLg from '../images/IM/Envelopes/9-5x11_5_SelfService_Landscape_lg.jpg';
import envTO1012PortWinSm from '../images/IM/Envelopes/10-25x12_Portrait_sm.jpg';
import envTO1012PortWinLg from '../images/IM/Envelopes/10-25x12_Portrait_lg.jpg';

// POS
import selfInkStamp from '../images/IM/POS/SelfInkingStamps.png';
import OptimizedSelfInkStamp from '../images/IM/POS/SelfInkingStampsOp.webp';
import posSup from '../images/IM/POS/POSSupplies.png';
import OptimizedPosSup from '../images/IM/POS/POSSuppliesOp.webp';
import lsInkJet from '../images/IM/POS/LaserandInkjetLabels.jpg';
import OptimizedLsInkJet from '../images/IM/POS/LaserandInkjetLabelsOp.webp';
import chkBinder from '../images/IM/POS/BusinessCheckBinders-OfficeAndAway.png';
import OptimizedChkBinder from '../images/IM/POS/OfficeAwayOp.webp';
import chkBinder120 from '../images/IM/Binders/stg_ocb_120.jpg';
import chkBinder340 from '../images/IM/Binders/stg_ocb_340.jpg';
import bc7Ring from '../images/IM/Binders/BusinessCheckBinders-7RingManual.png';
import bc7Ring120 from '../images/IM/Binders/stg_7rb_120.jpg';
import bc7Ring340 from '../images/IM/Binders/stg_7rb_340.jpg';
import frmS120 from '../images/IM/POS/frm_s_120.gif';
import OptimizedFrmS120 from '../images/IM/POS/frm_s_120Op.webp';
import secPen from '../images/IM/POS/security_pens_sm.jpg';
import OptimizedSecPen from '../images/IM/POS/security_pens_smOp.webp';
import posLb285 from '../images/IM/POS/PointOfSale-Label-1-2x85.png';
import OptimizedPosLb285 from '../images/IM/POS/PointOfSale-Label-1-2x85Op.webp';
import posLb225 from '../images/IM/POS/PointOfSale-Label-2-25x1-25.png';
import OptimizedPosLb225 from '../images/IM/POS/PointOfSale-Label-2-25x1-25Op.webp';
import posPaperROll from '../images/IM/POS/PointOfSale-POS-PaperRolls.png';
import OptimizedPosPaperROll from '../images/IM/POS/PointOfSale-POS-PaperRollsOp.webp';
import posHangTags from '../images/IM/POS/PointOfSale-PrintableHangTags.png';
import OptimizedPosHangTags from '../images/IM/POS/PointOfSale-PrintableHangTagsOp.webp';  
import posJewelTagsFlag from '../images/IM/POS/PointOfSale-JewelryTagwithFlap.png';
import OptimizedPosJewelTagsFlag from '../images/IM/POS/JwlwFlapOp.webp';
import posJewelTagsWithoutFlap from '../images/IM/POS/PointOfSale-JewelryTagwithoutFlap.png';
import OptimizedPosJewelTagsWithoutFlap from '../images/IM/POS/JwlwoFlapOp.webp';
import lbRl120 from '../images/IM/POS/lbl_rl1_120.jpg';
import lbRl2120 from '../images/IM/POS/lbl_rl2_120.gif';
import lbRpt120 from '../images/IM/POS/lbl_rpt_120.jpg';
import lnHng120 from '../images/IM/POS/lbl_hng_120.gif';
import lbJwf120 from '../images/IM/POS/lbl_jwf_120.jpg';
import lbJw120 from '../images/IM/POS/lbl_jw_120.jpg';

import lbl20b120 from '../images/IM/Labels/lbl_20b_120.gif';
import lblFf120 from '../images/IM/Labels/lbl_ff_120.gif';
import lbl30b120 from '../images/IM/Labels/lbl_30b_120.gif';
import lbl80b120 from '../images/IM/Labels/lbl_80b_120.gif';
import taxw2b278 from '../images/IM/Tax Forms/tax_w2b_278.gif';
import taxw2b278lg from '../images/IM/Tax Forms/tax_w2b_278_lg.gif';

import ptpflr120 from '../images/IM/TaxReturnFolder/ptp_flr_120.gif';
import ptpfls20 from '../images/IM/TaxReturnFolder/ptp_fls_120.gif';
import ptpfsr120 from '../images/IM/TaxReturnFolder/ptp_fsr_120.gif';
import tax2pf120regal from '../images/IM/TaxReturnFolder/tax_2pf_120_regal.gif';

import txQBW2Kits from '../images/IM/Tax Forms/Tx-QBW-2-Kits.png';
import OptimizedTxQBW2Kits from '../images/IM/Tax Forms/Tx-QBW-2-KitsOp.webp';
import ptp_fsr_regal from '../images/IM/TaxReturnFolder/ptp_fsr_regal.png';
import ptp_fsr_regal_large from '../images/IM/TaxReturnFolder/ptp_fsr_regal_large.png';
import ptp_fsr_hunter from '../images/IM/TaxReturnFolder/ptp_fsr_hunter.png';
import ptp_fsr_hunter_large from '../images/IM/TaxReturnFolder/ptp_fsr_hunter_large.png';
import ptp_fsr_charcoal from '../images/IM/TaxReturnFolder/ptp_fsr_charcoal.png';
import ptp_fsr_charcoal_large from '../images/IM/TaxReturnFolder/ptp_fsr_charcoal_large.png';
import ptp_fsr_bramble from '../images/IM/TaxReturnFolder/ptp_fsr_bramble.png';
import ptp_fsr_bramble_large from '../images/IM/TaxReturnFolder/ptp_fsr_bramble_large.png';

import ptp_flr_regal from '../images/IM/TaxReturnFolder/ptp_flr_regal.png';
import ptp_flr_regal_large from '../images/IM/TaxReturnFolder/ptp_flr_regal_large.png';
import ptp_flr_hunter from '../images/IM/TaxReturnFolder/ptp_flr_hunter.png';
import ptp_flr_hunter_large from '../images/IM/TaxReturnFolder/ptp_flr_hunter_large.png';
import ptp_flr_charcoal from '../images/IM/TaxReturnFolder/ptp_flr_charcoal.png';
import ptp_flr_charcoal_large from '../images/IM/TaxReturnFolder/ptp_flr_charcoal_large.png';
import ptp_flr_bramble from '../images/IM/TaxReturnFolder/ptp_flr_bramble.png';
import ptp_flr_bramble_large from '../images/IM/TaxReturnFolder/ptp_flr_bramble_large.png';

import ptp_fls_green from '../images/IM/TaxReturnFolder/ptp_fls_green.png';
import ptp_fls_green_large from '../images/IM/TaxReturnFolder/ptp_fls_green_large.png';
import ptp_fls_blue from '../images/IM/TaxReturnFolder/ptp_fls_blue.png';
import ptp_fls_blue_large from '../images/IM/TaxReturnFolder/ptp_fls_blue_large.png';

import tax_2pf_regal from '../images/IM/TaxReturnFolder/tax_2pf_regal.jpg';
import tax_2pf_regal_large from '../images/IM/TaxReturnFolder/tax_2pf_regal_large.jpg';
import tax_2pf_hunter from '../images/IM/TaxReturnFolder/tax_2pf_hunter.jpg';
import tax_2pf_hunter_large from '../images/IM/TaxReturnFolder/tax_2pf_hunter_large.jpg';
// value packs
import chk_vvp_120 from '../images/IM/ValuePacks/chk_vvp_120.png';
import chk_vvp_340 from '../images/IM/ValuePacks/chk_vvp_340.png';
// import chk_vbp_120 from '../images/IM/ValuePacks/chk_vbp_120.png';
// import chk_vbp_340 from '../images/IM/ValuePacks/chk_vbp_340.png';
import chk_svp_120 from '../images/IM/ValuePacks/chk_svp_120.png';
import chk_svp_340 from '../images/IM/ValuePacks/chk_svp_340.png';

// Voucher check value pack
import VoucherCheckBundle_Basic_lg from '../images/IM/ValuePacks/VoucherCheckBundle_Basic_lg.png';
import VoucherCheckBundle_Basic_sm from '../images/IM/ValuePacks/VoucherCheckBundle_Basic_sm.png';
import VoucherCheckBundle_SecurePlus_lg from '../images/IM/ValuePacks/VoucherCheckBundle_SecurePlus_lg.png';
import VoucherCheckBundle_SecurePlus_sm from '../images/IM/ValuePacks/VoucherCheckBundle_SecurePlus_sm.png';
import VoucherCheckBundle_SecurePremier_lg from '../images/IM/ValuePacks/VoucherCheckBundle_SecurePremier_lg.png';
import VoucherCheckBundle_SecurePremier_sm from '../images/IM/ValuePacks/VoucherCheckBundle_SecurePremier_sm.png';

// Resource Center
import ResourceCheckImage from '../images/IM/Resources/image_checks_hero.jpg';
import ResourceTaxFormImage from '../images/IM/Resources/image_taxforms_hero.jpg';
import ResourceCheckFraudImage from '../images/IM/Resources/image_checkfraud_hero.jpg';

import { getCurrentFisicalYear } from '../../js/components/Util/CommonUtils';

const PageData = {
  ...ChecksPageData,
  ...EnvelopesPageData,
  ...DepositSlipsData,
  ...StampsData,
  ...BusinessFormsPageData,
  ...AllTaxPageData,
  ...HomePageData,
  ...HelpPageData,
  ...DiscountPageData,
  TAX_PRODUCTS: {
    Department: {
      DeptName: 'QuickBooks Tax Products',
      DeptDesc:
        'From QuickBooks-compatible tax forms to high-quality folders and convenient envelopes, QuickBooks Checks & Supplies has products that will help make doing taxes easy.',
      DeptId: '',
      FetchPricing: false,
      RequestBody: null,
    },
    ProductSections: [
      {
        SectionHeader: '',
        SectionDesc: '',
        Products: [
          {
            ProdDeptId: '',
            ProductId: '',
            ProductName: 'QuickBooks Tax Forms',
            ProductDesc:
              'IRS-approved tax forms designed to work with Quickbooks and complete tax kits that give you everything you need to easily file.',
            ImageSrc: txQBW2Kits,
            ImageSrcOptimized: OptimizedTxQBW2Kits,
            ImageHeight: '260px',
            ImageWidth: '281px',
            ImageLrg: null,
            ButtonText: 'View all',
            LearnMoreLink: '/tax-forms',
            ActualPrice: 0,
            DiscountPrice: 0,
            Sku: null,
            ButtonLink: '/tax-forms',
            Quantity: null,
            SpecialProcessId: 0,
            HasPricing: true,
            PFID: null,
          },
          {
            ProdDeptId: '',
            ProductId: '',
            ProductName: 'QuickBooks Tax Return Folders',
            ProductDesc: 'Save time and look more professional with our high-quality folders.',
            ImageSrc: ptpfls20,
            ImageLrg: null,
            ButtonText: 'View all',
            LearnMoreLink: '/tax-products/tax-return-folders',
            ActualPrice: 0,
            DiscountPrice: 0,
            Sku: null,
            ButtonLink: '/tax-products/tax-return-folders',
            Quantity: null,
            SpecialProcessId: 0,
            HasPricing: true,
            PFID: null,
          },
          {
            ProdDeptId: '',
            ProductId: '',
            ProductName: 'QuickBooks Tax Filing Envelopes',
            ProductDesc:
              'Make tax filling easier with slip sheet compatible envelopes - eliminate the need for address stamps and custom labels.',
            ImageSrc: env69RetFilsm,
            ImageLrg: null,
            ButtonText: 'View all',
            LearnMoreLink: '/tax-products/tax-filing-envelopes',
            ActualPrice: 0,
            DiscountPrice: 0,
            Sku: null,
            ButtonLink: '/tax-products/tax-filing-envelopes',
            Quantity: null,
            SpecialProcessId: 0,
          },
          {
            ProdDeptId: '',
            ProductId: '',
            ProductName: 'QuickBooks Tax Organizer Envelopes',
            ProductDesc:
              'Designed for easy mailing of organizers, tax return copies, and other documents.',
            ImageSrc: envTO911SmallWinSm,
            ImageLrg: null,
            ButtonText: 'View all',
            LearnMoreLink: '/tax-products/organizer-envelopes',
            ActualPrice: 0,
            DiscountPrice: 0,
            Sku: null,
            ButtonLink: '/tax-products/organizer-envelopes',
            Quantity: null,
            SpecialProcessId: 0,
            HasPricing: true,
            PFID: null,
          },
        ],
      },
    ],
  },
  TAX_FILING_ENVELOPES: {
    Department: {
      DeptName: 'QuickBooks Tax Filing Envelopes',
      DeptDesc:
        "Filing has never been easier. Save time by using tax filing envelopes, which are designed to work perfectly with slip sheets printed from your software. Ensure tha your clients' returns are mailed to the correct filing center, without printing multiple address labels.",
      DeptId: '43',
      FetchPricing: true,
      RequestBody: ['ENV-12', 'ENV-24', 'ENV-23', 'ENV-11', 'ENV-10'],
    },
    ProductSections: [
      {
        SectionHeader: '',
        SectionDesc: '',
        Products: [
          {
            ProdDeptId: '',
            ProductId: '',
            ProductName: "9.5'' x 11.5'' Tax Return Filing Envelopes, Self-sealing",
            ProductDesc:
              'Perfect for large returns you do not wish to fold. Features a self-seal flap for ease of mailing. Reminder instructions are printed on the back flap to ensure proper filing.',
            ProductDescExtra: true,
            ImageSrc: env911RetFil,
            ImageLrg: env911RetFillg,
            ButtonText: 'Add to cart',
            LearnMoreLink: null,
            ActualPrice: 0,
            DiscountPrice: 0,
            Sku: null,
            ButtonLink: '',
            Quantity: null,
            SpecialProcessId: 0,
            HasPricing: true,
            PFID: 'ENV-23',
          },
          {
            ProdDeptId: '',
            ProductId: '',
            ProductName: "9.5'' x 11.5'' Tax Return Filing Envelopes",
            ProductDesc:
              'Perfect for large returns you do not wish to fold. Reminder instructions are printed on the back flap to ensure proper filing.',
            ProductDescExtra: true,
            ImageSrc: env911RetFil,
            ImageLrg: env911RetFillg,
            ButtonText: 'Add to cart',
            LearnMoreLink: null,
            ActualPrice: 0,
            DiscountPrice: 0,
            Sku: null,
            ButtonLink: '',
            Quantity: null,
            SpecialProcessId: 0,
            HasPricing: true,
            PFID: 'ENV-11',
          },
          {
            ProdDeptId: '',
            ProductId: '',
            ProductName: "6'' x 9'' Tax Return Filing Envelopes, Self-sealing",
            ProductDesc:
              'A popular size for both federal and state return filings, with a self-seal flap for ease of mailing. Reminder instructions are printed on the back flap to ensure proper filing.',
            ProductDescExtra: true,
            ImageSrc: env69RetFil,
            ImageLrg: env69RetFillg,
            ButtonText: 'Add to cart',
            LearnMoreLink: null,
            ActualPrice: 0,
            DiscountPrice: 0,
            Sku: null,
            ButtonLink: '',
            Quantity: null,
            SpecialProcessId: 0,
            HasPricing: true,
            PFID: 'ENV-24',
          },
          {
            ProdDeptId: '',
            ProductId: '',
            ProductName: "6'' x 9'' Tax Return Filing Envelopes",
            ProductDesc:
              'A popular size for both federal and state return filings. Reminder instructions are printed on the back flap to ensure proper filing.',
            ProductDescExtra: true,
            ImageSrc: env69RetFilsm,
            ImageLrg: env69RetFillg,
            ButtonText: 'Add to cart',
            LearnMoreLink: null,
            ActualPrice: 0,
            DiscountPrice: 0,
            Sku: null,
            ButtonLink: '',
            Quantity: null,
            SpecialProcessId: 0,
            HasPricing: true,
            PFID: 'ENV-12',
          },
          {
            ProdDeptId: '',
            ProductId: '',
            ProductName: '1040 V/ES Tax Return Filing Envelopes',
            ProductDesc:
              'For use with federal and state estimated tax payments and Federal Form 1040 V. Reminder instructions are printed on the back flap to ensure proper filing.',
            ProductDescExtra: true,
            ImageSrc: env1040RetFilSm,
            ImageLrg: env1040RetFilLg,
            ButtonText: 'Add to cart',
            LearnMoreLink: null,
            ActualPrice: 0,
            DiscountPrice: 0,
            Sku: null,
            ButtonLink: '',
            Quantity: null,
            SpecialProcessId: 0,
            HasPricing: true,
            PFID: 'ENV-10',
          },
        ],
      },
    ],
  },
  TAX_ORGANIZER_ENVELOPES: {
    Department: {
      DeptName: 'QuickBooks Tax Organizer Envelopes',
      DeptDesc:
        'Save time mailing tax organizers and other documents to your clients. We offer a variety of envelopes that are slip-sheet compatible or pre-printed.',
      DeptId: '43',
      FetchPricing: true,
      RequestBody: ['ENV-13', 'ENV-20', 'ENV-14', 'ENV-15', 'ENV-16', 'ENV-17', 'ENV-18', 'ENV-19'],
    },
    ProductSections: [
      {
        SectionHeader: '',
        SectionDesc: '',
        Products: [
          {
            ProdDeptId: '',
            ProductId: '',
            ProductName: "9.5'' x 11.5'' Small Window Portrait Envelopes, Self-sealing",
            ProductDesc: '',
            ProductDescExtra: true,
            ImageSrc: envTO911SmallWinSm,
            ImageLrg: envTO911SmallWinLg,
            ButtonText: 'Add to cart',
            LearnMoreLink: null,
            ActualPrice: 0,
            DiscountPrice: 0,
            Sku: null,
            ButtonLink: '',
            Quantity: null,
            SpecialProcessId: 0,
            HasPricing: true,
            PFID: 'ENV-14',
          },
          {
            ProdDeptId: '',
            ProductId: '',
            ProductName: "9.5'' x 11.5'' Small Window Portrait Envelopes",
            ProductDesc: '',
            ProductDescExtra: true,
            ImageSrc: envTO911SmallWinSm,
            ImageLrg: envTO911SmallWinLg,
            ButtonText: 'Add to cart',
            LearnMoreLink: null,
            ActualPrice: 0,
            DiscountPrice: 0,
            Sku: null,
            ButtonLink: '',
            Quantity: null,
            SpecialProcessId: 0,
            HasPricing: true,
            PFID: 'ENV-13',
          },
          {
            ProdDeptId: '',
            ProductId: '',
            ProductName: "9.5'' x 11.5'' Large Window Portrait Envelopes",
            ProductDesc: '',
            ProductDescExtra: true,
            ImageSrc: envTO911LrgWinSm,
            ImageLrg: envTO911LrgWinLg,
            ButtonText: 'Add to cart',
            LearnMoreLink: null,
            ActualPrice: 0,
            DiscountPrice: 0,
            Sku: null,
            ButtonLink: '',
            Quantity: null,
            SpecialProcessId: 0,
            HasPricing: true,
            PFID: 'ENV-15',
          },
          {
            ProdDeptId: '',
            ProductId: '',
            ProductName: "9.5'' x 11.5'' Landscape Envelopes, Self-sealing",
            ProductDesc: '',
            ProductDescExtra: true,
            ImageSrc: envTO911LandWinSm,
            ImageLrg: envTO911LandSSWinLg,
            ButtonText: 'Add to cart',
            LearnMoreLink: null,
            ActualPrice: 0,
            DiscountPrice: 0,
            Sku: null,
            ButtonLink: '',
            Quantity: null,
            SpecialProcessId: 0,
            HasPricing: true,
            PFID: 'ENV-17',
          },
          {
            ProdDeptId: '',
            ProductId: '',
            ProductName: "9.5'' x 11.5'' Landscape Envelopes",
            ProductDesc: '',
            ProductDescExtra: true,
            ImageSrc: envTO911LandWinSm,
            ImageLrg: envTO911LandSSWinLg,
            ButtonText: 'Add to cart',
            LearnMoreLink: null,
            ActualPrice: 0,
            DiscountPrice: 0,
            Sku: null,
            ButtonLink: '',
            Quantity: null,
            SpecialProcessId: 0,
            HasPricing: true,
            PFID: 'ENV-16',
          },
          {
            ProdDeptId: '',
            ProductId: '',
            ProductName: "10.25'' x 12'' Portrait Envelopes",
            ProductDesc: 'Holds up to 200 pages.',
            ProductDescExtra: true,
            ImageSrc: envTO1012PortWinSm,
            ImageLrg: envTO1012PortWinLg,
            ButtonText: 'Add to cart',
            LearnMoreLink: null,
            ActualPrice: 0,
            DiscountPrice: 0,
            Sku: null,
            ButtonLink: '',
            Quantity: null,
            SpecialProcessId: 0,
            HasPricing: true,
            PFID: 'ENV-18',
          },
          {
            ProdDeptId: '',
            ProductId: '',
            ProductName: "10.25'' x 12'' Landscape Envelopes",
            ProductDesc: 'Holds up to 200 pages.',
            ProductDescExtra: true,
            ImageSrc: envTO911LandWinSm,
            ImageLrg: envTO1012LandWinLg,
            ButtonText: 'Add to cart',
            LearnMoreLink: null,
            ActualPrice: 0,
            DiscountPrice: 0,
            Sku: null,
            ButtonLink: '',
            Quantity: null,
            SpecialProcessId: 0,
            HasPricing: true,
            PFID: 'ENV-19',
          },
          {
            ProdDeptId: '',
            ProductId: '',
            ProductName: "10.25'' x 12'' Expandable Landscape Envelopes",
            ProductDesc: 'Holds up to 400 pages.',
            ProductDescExtra: true,
            ImageSrc: envTO911LandWinSm,
            ImageLrg: envTO1012LandWinLg,
            ButtonText: 'Add to cart',
            LearnMoreLink: null,
            ActualPrice: 0,
            DiscountPrice: 0,
            Sku: null,
            ButtonLink: '',
            Quantity: null,
            SpecialProcessId: 0,
            HasPricing: true,
            PFID: 'ENV-20',
          },
        ],
      },
    ],
  },
  PRE_PRINT_W2_KITS: {
    Department: {
      DeptName: 'Pre-Printed W-2 Kits',
      DeptDesc:
        'Enjoy guaranteed compatibility with QuickBooks and great value with our all-inclusive, IRS-approved W-2 Kits.',
      LearnMore: ' Learn more about the IRS e-filing requirement changes.',
      DeptId: '',
      FetchPricing: true,
      RequestBody: ['TAX-01'],
      Image: w2k120,
      ImageSrcOptimized: OptimizedW2k120,
      ImageLg: w2k340,
      ImageSrcOptimizedLg: OptimizedW2k340,
      ImageHeight: '228px',
      ImageWidth: '239px',
      ImageHeightLg: '326px',
      ImageWidthLg: '340px',
      PFID: 'TAX-01',
      ProductInformation: {
        Desc: (
          <span>
            Use for <span className="font-wgt-500">reporting employee wages and salaries</span> to
            federal, state and local agencies; each kit includes a copy for your employee.{' '}
            <span className="font-wgt-500">Easily print W-2 information</span> directly from
            QuickBooks Desktop onto the correct blank section of each tax form. Specifically{' '}
            <span className="font-wgt-500">designed for small businesses-</span> available in
            quantities as low as 10 so you don&#39;t buy forms you don&#39;t need.
          </span>
        ),
        EachKitContains: [
          <span>{getCurrentFisicalYear()} W-2 Forms (two tax forms per page)</span>,
          'four free pre-printed W-3 forms',
          'compatible double-window W-2 envelopes',
        ],
        Specifications: ['Size: 8 1/2" x 11"'],
        Compatibility: [
          <span key={1}>
            Pre-printed W-2 Kits are compatible with QuickBooks Desktop and QuickBooks Desktop Pro
            customers who are using QuickBooks Desktop Basic or Enhanced Payroll. Printable from
            laser or inkjet printers.
          </span>,
          <span key={2}>
            Not compatible with QuickBooks Online, QuickBooks Online Payroll, QuickBooks Desktop
            Assisted Payroll and QuickBooks for Mac. QuickBooks Online, QuickBooks Online Payroll,
            and QuickBooks Desktop Assisted Payroll users need to use{' '}
            <Link
              to="/tax-forms/w2-forms/w-2-blank-envelope-kit"
              style={{ textDecoration: 'none', color: '#0077C5' }}
            >
              W-2 Blank Perforated Paper Kits
            </Link>
          </span>,
        ],
      },
      PricingInformation: {
        Type: 'TAX-01',
        Variant: '4-part',
      },
    },
    HasEfileModal: true,
    HasFaq: true,
    FaqDescription: {
      Header: 'W2 Form FAQs',
      LeftQuestionList: [
        {
          Header: "What's a W-2 tax form?",
          Description:
            'A W-2 tax form is used to report employee wages to federal, state, and local agencies.',
        },
        {
          Header: 'Who should get a W-2 tax form?',
          Description: 'Any non-contractor you employ and have paid.',
        },
      ],
      RightQuestionList: [
        {
          Header: 'How do I fill out a W-2 tax form?',
          Description: (
            <span>
              We’ve put together{' '}
              <Link
                to={{ pathname: 'https://quickbooks.intuit.com/r/payroll/w2-form-explained/' }}
                target="_blank"
              >
                <strong>detailed instructions</strong>
              </Link>{' '}
              to help walk you through W-2 forms.
            </span>
          ),
        },
        {
          Header: 'What are the IRS e-filing requirements?',
          Description: (
            <span>
              Beginning in 2024, all businesses with 10 or more filings in a calendar year must file
              electronically. This 10 or more limit is now determined by all return types combined.
            </span>
          ),
          LearnMore: (
            <span className="irs-learn-more"> Learn more about the e-filing requirements.</span>
          ),
        },
      ],
    },
  },
  PRE_PRINT_W3_Form: {
    Department: {
      DeptName: 'Pre-Printed W-3 Forms',
      DeptDesc: null,
      DeptId: '',
      FetchPricing: true,
      RequestBody: ['TAX-03'],
      Image: taxw3120,
      ImageSrcOptimized: OptimizedTaxw3120,
      ImageLg: taxw3340,
      ImageSrcOptimizedLg: OptimizedTaxw3340,
      ImageHeight: '241px',
      ImageWidth: '188px',
      ImageHeightLg: '435px',
      ImageWidthLg: '340px',
      PFID: 'TAX-03',
      checkTaxProductUnavailblity: true,
      ProductInformation: {
        Desc: (
          <span>
            Use a QuickBooks Desktop compatible W-3 form to print a summary of all the W-2 forms
            your company submits. W-3 forms are also available in our{' '}
            <Link to="/tax-forms/w2-forms/pre-printed-w-2-kits" className="universal-link">
              Pre-Printed W-2 Kits.
            </Link>
          </span>
        ),
        EachKitContains: null,
        Specifications: ['Size: 8 1/2" x 11"'],
        Compatibility: [
          <span key={1}>Not compatible with QuickBooks Online or QuickBooks for Mac.</span>,
        ],
      },
      PricingInformation: {
        Type: 'ADDTOCART',
      },
    },
  },
  W2_KITS_FORMS: {
    MultiProductHeader: 'QuickBooks W-2 Kits',
    MultiProductDesp:
      'Enjoy guaranteed compatibility with QuickBooks and great value with our all-inclusive, IRS-approved W-2 Kits.',
    Department: {
      DeptName: '',
      DeptDesc: '',
      FetchPricing: true,
      RequestBody: ['TAX-01', 'PERF-01'],
    },
    ProductSections: [
      {
        Products: [
          {
            ImageSrc: w2k120,
            ImageSrcOptimized: OptimizedW2k120,
            ImageLrg: w2k340,
            ImageSrcOptimizedLrg: OptimizedW2k340,
            ImageHeight: '228px',
            ImageWidth: '239px',
            ImageHeightLrg: '326px',
            ImageWidthLrg: '340px',
            ProductName: `${getCurrentFisicalYear()} Pre-Printed W-2 Kits`,
            PFID: 'TAX-01',
            Variant: '4-part',
            ProductDesc: (
              <span>
                Use for reporting employee wages and salaries to federal, state and local agencies;
                each kit includes a copy for your employee. Easily print W-2 information directly
                from QuickBooks Desktop onto the correct blank section of each tax form.
                Specifically designed for small businesses- available in quantities as low as 10 so
                you don't buy forms you don't need.
              </span>
            ),
            EachKitContains: [
              `${getCurrentFisicalYear()} W-2 forms (two tax forms per page)`,
              'four free pre-printed W-3 forms',
              'compatible double-window W-2 envelopes',
            ],
            Specifications: ['size: 8 1/2" x 11"'],
            Compatibility: [
              <span key={1}>
                Pre-printed W-2 Kits are compatible with QuickBooks Desktop and QuickBooks Desktop
                Pro customers who are using QuickBooks Desktop Basic or Enhanced Payroll. Printable
                from laser or inkjet printers.
              </span>,
              <span key={2}>
                Not compatible with QuickBooks Online, QuickBooks Online Payroll, QuickBooks Desktop
                Assisted Payroll and QuickBooks for Mac. QuickBooks Online, QuickBooks Online
                Payroll, and QuickBooks Desktop Assisted Payroll users need to use W-2 Blank
                Perforated Paper Kits.
              </span>,
            ],
            LearnMoreLink: null,
            HasPricing: false,
            HasSkuSelection: true,
            StateLabelText: 'Select state you’re filing in',
            QuantityPlaceholderText: 'Select a state for pricing',
            StateHelperText:
              'We will select the correct number of parts based on the state you select.',
            StateHelperLink: 'See list of parts required for each state.',
            QuantityHelperText: 'Quantity will depend on the number of employees you have.',
            NavName: 'Pre-Printed W-2 Kits',
            DisplayStateHelperText: true,
          },
          {
            ImageSrc: blankW2k,
            ImageLrg: blankW2kLg,
            ProductName: 'Perforated Blank W-2 Kits',
            PFID: 'PERF-01',
            ProductDesc: (
              <span>
                Specifically designed for small businesses - available in quantities as low as 10 so
                you don't buy forms you don't need. Detailed instructions for the recipient are
                printed on the back.
              </span>
            ),
            EachKitContains: [
              'perforated W-2 Paper with either three of four blanks per sheet*',
              'compatible double-window envelopes',
            ],
            Specifications: ['size: 8 1/2" x 11"'],
            Compatibility: [
              <span key={1}>
                Blank W-2 Kits are compatible with QuickBooks Online, QuickBooks Online Payroll,
                QuickBooks Desktop Basic, Enhanced or Assisted Payroll, and QuickBooks for Mac.
                Printable from laser or inkjet printers.
              </span>,
              <span key={2}>
                *QuickBooks Desktop customers using Standard or Enhanced Payroll must use the three
                to a sheet forms. QuickBooks Desktop customers using Assisted Payroll, QuickBooks
                Online, and Online Payroll customers must use the four to a sheet forms.
              </span>,
            ],
            SpecialNote: [
              <span key={1}>
                Customers who choose Self-Print are responsible for filing with the government and
                sending copies to their employees. W-3 forms are not required when e-filing W-2
                forms.
              </span>,
              <span key={2}>
                This does not constitute tax or legal advice; please consult your own professional
                advisor.
              </span>,
            ],
            HasPricingInformation: true,
            IsMultiProductW2Blank: true,
            PricingInformation: {
              Type: 'PERF01',
              Variants: [
                {
                  QuickBooksVersion: 'Not Applicable',
                  PayrollVersions: [
                    {
                      Version: 'Online Payroll',
                      Part: '6-part',
                      Sku: '2778',
                      SmallImageUrl: taxw2b278,
                      SmallImageAlternateText: 'Laser W-2 Blank Perforated Paper and Envelope Kit',
                      LargeImageUrl: taxw2b278lg,
                      LargeImageAlternateText: 'Laser W-2 Blank Perforated Paper and Envelope Kit',
                    },
                  ],
                },
                {
                  QuickBooksVersion: 'QuickBooks Desktop 2013 \u0026 higher',
                  PayrollVersions: [
                    {
                      Version: 'Assisted Payroll',
                      Part: '8-part',
                      Sku: '278',
                      SmallImageUrl: taxw2b278,
                      SmallImageAlternateText: 'Laser W-2 Blank Perforated Paper and Envelope Kit',
                      LargeImageUrl: taxw2b278lg,
                      LargeImageAlternateText: 'Laser W-2 Blank Perforated Paper and Envelope Kit',
                    },
                    {
                      Version: 'Standard or Enhanced Payroll',
                      Part: '4-part',
                      Sku: '277',
                      SmallImageUrl: blankW2k,
                      SmallImageAlternateText: 'Laser W-2 Blank Perforated Paper and Envelope Kit',
                      LargeImageUrl: blankW2kLg,
                      LargeImageAlternateText: 'Laser W-2 Blank Perforated Paper and Envelope Kit',
                    },
                  ],
                },
                {
                  QuickBooksVersion: 'QuickBooks Online',
                  PayrollVersions: [
                    {
                      Version: 'QuickBooks Online Payroll',
                      Part: '6-part',
                      Sku: '2777',
                      SmallImageUrl: taxw2b278,
                      SmallImageAlternateText: 'Laser W-2 Blank Perforated Paper and Envelope Kit',
                      LargeImageUrl: taxw2b278lg,
                      LargeImageAlternateText: 'Laser W-2 Blank Perforated Paper and Envelope Kit',
                    },
                  ],
                },
              ],
            },
            LearnMoreLink: null,
            HasPricing: false,
            HasSkuSelection: false,
            NavName: 'Blank W-2 Kits',
            ButtonText: null,
          },
        ],
      },
    ],
  },
  PRE_PRINT_1099_FORMS: {
    MultiProductHeader: 'QuickBooks Pre-Printed 1099 Kits',
    MultiProductDesp: (
      <>
        <p>
          Designed to print directly from QuickBooks, putting information in the correct section of
          each form. Available in quantities as low as 10, so small businesses can buy only the
          forms they need, these new “continuous use” forms will no longer include the tax year:
          QuickBooks will print the year for you.
        </p>
      </>
    ),
     TaxHelperLink: true,
    Department: {
      DeptName: '',
      DeptDesc: '',
      FetchPricing: true,
      RequestBody: ['TAX-72', 'TAX-02'],
    },
    ProductSections: [
      {
        Products: [
          {
            ImageSrc: taxNec120,
            ImageSrcOptimized: OptimizedTaxNec120,
            ImageLrg: taxNec340,
            ImageSrcOptimizedLrg: OptimizedTaxNec340,
            ImageHeight: '228px',
            ImageWidth: '239px',
            ImageHeightLrg: '326px',
            ImageWidthLrg: '340px',
            ProductName: `${getCurrentFisicalYear()} Pre-Printed 1099-NEC Kits`,
            PFID: 'TAX-72',
            Variant: '4-part',
            ProductTaxDescExtra: true,
            ProductDesc: (
              <>
                <p>
                  Use federal 1099-NEC tax forms to report payments of $600 or more to non-employees
                  (contractors).
                </p>
                <br />
                <span>
                  These “continuous use” forms no longer include the tax year. QuickBooks will print
                  the year on the forms for you.
                </span>
              </>
            ),
            EachKitContains: [
              `${getCurrentFisicalYear()} 1099-NEC forms (three tax forms per page)`,
              'four free 1096 forms',
              'compatible double-window envelopes',
            ],
            Specifications: ['Size: 8 1/2" x 11"'],
            Compatibility: [
              <span key={1}>
                Pre-printed 1099-NEC kits are compatible with QuickBooks Online, QuickBooks Online
                Payroll, QuickBooks Desktop 2020 or later, and QuickBooks for Mac 2020 or later.
                Printable from laser and inkjet printers.
              </span>,
            ],
            additionalDesc: null,
            LearnMoreLink: null,
            HasPricing: false,
            HasSkuSelection: true,
            DisplayStateHelperText: true,
            StateLabelText: 'Select state you’re filing in',
            NavName: '1099-NEC',
          },
          {
            ImageSrc: taxMisc120,
            ImageSrcOptimized: OptimizedTaxMisc120,
            ImageLrg: taxMisc340,
            ImageSrcOptimizedLrg: OptimizedTaxMisc340,
            ImageHeight: '228px', 
            ImageWidth: '239px',
            ImageHeightLrg: '326px',
            ImageWidthLrg: '340px',
            ProductName: `${getCurrentFisicalYear()} Pre-Printed 1099-MISC Kits`,
            PFID: 'TAX-02',
            Variant: '4-part',
            ProductTaxDescExtra: true,
            ProductDesc: (
              <>
                <p>
                  Use federal 1099-MISC tax forms to report payments of $600 or more for rents,
                  royalties, medical and health care payments, and gross proceeds paid to attorneys.
                </p>
                <br />
                <span>
                  These “continuous use” forms no longer include the tax year. QuickBooks will print
                  the year on the forms for you.
                </span>
              </>
            ),
            EachKitContains: [
              `${getCurrentFisicalYear()} 1099-MISC forms (two tax forms per page)`,
              'four free 1096 forms',
              'compatible double-window envelopes',
            ],
            Specifications: ['Size: 8 1/2" x 11"'],
            Compatibility: [
              <span key={1}>
                Pre-printed 1099-MISC kits are compatible with QuickBooks Online, QuickBooks Online
                Payroll, QuickBooks Desktop 2020 or later, and QuickBooks for Mac 2020 or later.
                Printable from laser and inkjet printers.
              </span>,
            ],
            additionalDesc: null,
            LearnMoreLink: null,
            HasPricing: false,
            HasSkuSelection: true,
            DisplayStateHelperText: true,
            StateLabelText: 'Select state you’re filing in',
            NavName: '1099-MISC',
          },
        ],
      },
    ],
    HasEfileModal: true,
    HasFaq: true,
    FaqDescription: {
      Header: '1099 NEC & MISC Forms FAQs',
      LeftQuestionList: [
        {
          Header: "What's a 1099 form?",
          Description:
            'As of 2020, there are two 1099 tax forms – the 1099-NEC and the 1099-MISC. Form 1099-NEC is used to report any payments you make of $600 or more to non-employees (contractors). Form 1099-MISC applies to any payments of $600 or more for rents, royalties, medical and health care payments, and gross proceeds paid to attorneys.',
        },
        {
          Header: 'Why do these 1099 forms include four free 1096 forms?',
          Description:
            'Whenever you send a 1099-NEC or MISC you must also provide a 1096 to the IRS as a summary of those forms.',
        },
      ],
      RightQuestionList: [
        {
          Header: 'Where do I get my 1099?',
          Description:
            'You can file online with QuickBooks or buy physical copies to print from QuickBooks and mail them.',
        },
        {
          Header: 'How do I file 1099 tax forms?',
          Description:
            "Depending on what's best for you and your business, you can take advantage of QuickBooks software and easily file a 1099 online or you can buy 1099 forms to print from QuickBooks.",
        },
        {
          Header: 'What are the IRS e-filing requirements?',
          Description: (
            <span>
              Beginning in 2024, all businesses with 10 or more filings in a calendar year must file
              electronically. This 10 or more limit is now determined by all return types combined.
            </span>
          ),
          LearnMore: (
            <span className="irs-learn-more"> Learn more about the e-filing requirements.</span>
          ),
        },
      ],
    },
    navBodyClass: 'product-page-type1',
  },
  PRE_PRINT_1096_Form: {
    Department: {
      DeptName: 'Pre-Printed 1096 Forms',
      DeptDesc: null,
      DeptId: '',
      FetchPricing: true,
      RequestBody: ['TAX-04'],
      Image: tax96120,
      ImageSrcOptimized: OptimizedTax96120,
      ImageLg: tax96340,
      ImageSrcOptimizedLg: OptimizedTax96340,
      ImageHeight: '241px',
      ImageWidth: '188px',
      ImageHeightLg: '435px',
      ImageWidthLg: '340px',
      PFID: 'TAX-04',
      checkTaxProductUnavailblity: true,
      ProductInformation: {
        Desc: (
          <span>
            Print a summary of the 1099-NEC and 1099-MISC forms to be filed directly from
            QuickBooks. 1096 forms are also available in our{' '}
            <a
              className="universal-link"
              href="/tax-forms/1099-forms"
              target="_self"
              rel="noopener noreferrer"
            >
              Pre-Printed 1099-NEC Kits
            </a>{' '}
            and{' '}
            <a
              className="universal-link"
              href="/tax-forms/1099-forms"
              target="_self"
              rel="noopener noreferrer"
            >
              Pre-Printed 1099-MISC Kits.
            </a>
          </span>
        ),
        EachKitContains: null,
        Specifications: ['Size: 8 1/2" x 11"'],
        Compatibility: [
          <span key={1}>
            Compatible with QuickBooks Desktop; <br /> QuickBooks Online Plus; and QuickBooks for
            Mac 2016 and later.
          </span>,
        ],
      },
      PricingInformation: {
        Type: 'ADDTOCART',
      },
    },
    HasFaq: true,
    FaqDescription: {
      Header: '1096 Form FAQs',
      LeftQuestionList: [
        {
          Header: 'What is a 1096 tax form?',
          Description: `IRS Form 1096 is officially named the "Annual Summary and Transmittal of U.S. Information Returns" by the IRS. It's used to summarize details about other tax forms that you are submitting, but only if you are submitting them in paper form.`,
        },
        {
          Header: '',
          Description:
            'A 1096 tax form is used to summarize the details of any of the following tax forms you file: 1099; 1098; 1097; 3921; 3922; 5498; W-2G.',
        },
      ],
      RightQuestionList: [
        {
          Header: 'Do I receive a 1096 tax form if I order 1099-NEC or 1099-MISC Tax Kit?',
          Description:
            'Yes! Whenever you order 1099-NEC or 1099-MISC Tax Kits for QuickBooks we include four free 1096 tax forms.',
        },
      ],
    },
  },
  BLANK_W2_KITS: {
    Department: {
      DeptName: 'W-2 Blank Perforated Paper and Envelope Kit',
      DeptDesc: (
        <span>Print your employees' W-2 forms on IRS-approved blank, perforated paper.</span>
      ),
      DeptId: '',
      FetchPricing: true,
      RequestBody: ['PERF-01'],
      Image: blankW2k,
      ImageLg: blankW2kLg,
      PFID: 'PERF-01',
      ProductInformation: {
        Desc: (
          <span>
            Specifically designed for small businesses - available in quantities as low as 10 so you
            don&#39;t buy forms you don&#39;t need. Detailed instructions for the recipient are
            printed on the back.
          </span>
        ),
        EachKitContains: [
          'perforated W-2 paper with either three or four blanks per sheet*',
          'compatible double-window envelopes',
        ],
        Specifications: ['Size: 8 ½"(w) x 11"(l)'],
        Compatibility: [
          <span key={1}>
            Blank W-2 Kits are compatible with QuickBooks Online, QuickBooks Online Payroll,
            QuickBooks Desktop Basic, Enhanced or Assisted Payroll, and QuickBooks for Mac.
            Printable from laser or inkjet printers.
          </span>,
          <span key={2}>
            *QuickBooks Desktop customers using Standard or Enhanced Payroll must use the three to a
            sheet forms. QuickBooks Desktop customers using Assisted Payroll, QuickBooks Online, and
            Online Payroll customers must use the four to a sheet forms.
          </span>,
        ],
        SpecialNote: [
          <span key={1}>
            Customers who choose Self-Print are responsible for filing with the government and
            sending copies to their employees. W-3 forms are not required when e-filing W-2 forms.
          </span>,
          <span key={2}>
            This does not constitute tax or legal advice; please consult your own professional
            advisor.
          </span>,
        ],
      },
      PricingInformation: {
        Type: 'PERF01',
        Variants: [
          {
            QuickBooksVersion: 'Not Applicable',
            PayrollVersions: [
              {
                Version: 'Online Payroll',
                Part: '6-part',
                Sku: '2778',
                SmallImageUrl: taxw2b278,
                SmallImageAlternateText: 'Laser W-2 Blank Perforated Paper and Envelope Kit',
                LargeImageUrl: taxw2b278lg,
                LargeImageAlternateText: 'Laser W-2 Blank Perforated Paper and Envelope Kit',
              },
            ],
          },
          {
            QuickBooksVersion: 'QuickBooks Desktop 2013 \u0026 higher',
            PayrollVersions: [
              {
                Version: 'Assisted Payroll',
                Part: '8-part',
                Sku: '278',
                SmallImageUrl: taxw2b278,
                SmallImageAlternateText: 'Laser W-2 Blank Perforated Paper and Envelope Kit',
                LargeImageUrl: taxw2b278lg,
                LargeImageAlternateText: 'Laser W-2 Blank Perforated Paper and Envelope Kit',
              },
              {
                Version: 'Standard or Enhanced Payroll',
                Part: '4-part',
                Sku: '277',
                SmallImageUrl: blankW2k,
                SmallImageAlternateText: 'Laser W-2 Blank Perforated Paper and Envelope Kit',
                LargeImageUrl: blankW2kLg,
                LargeImageAlternateText: 'Laser W-2 Blank Perforated Paper and Envelope Kit',
              },
            ],
          },
          {
            QuickBooksVersion: 'QuickBooks Online',
            PayrollVersions: [
              {
                Version: 'QuickBooks Online Payroll',
                Part: '6-part',
                Sku: '2777',
                SmallImageUrl: taxw2b278,
                SmallImageAlternateText: 'Laser W-2 Blank Perforated Paper and Envelope Kit',
                LargeImageUrl: taxw2b278lg,
                LargeImageAlternateText: 'Laser W-2 Blank Perforated Paper and Envelope Kit',
              },
            ],
          },
        ],
      },
    },
    HasEfileModal: true,
  },
  TAX_RETURN_FOLDERS: {
    Department: {
      DeptName: 'Tax Return Folders',
      DeptDesc:
        'Make a great impression without spending a lot of money. Slip-sheet compatible folders eliminate the need for custom printing. We offer a variety of folder options - all backed by our satisfaction guarantee.',
      DeptId: '',
      FetchPricing: true,
      RequestBody: ['FOLDER-01', 'FOLDER-02', 'FOLDER-03', 'FOLDER-04'],
    },
    ProductSections: [
      {
        SectionHeader: '',
        SectionDesc: '',
        Products: [
          {
            ProdDeptId: '',
            ProductId: '',
            ProductName: 'Small Window Premium Tax Return Presentation Folders',
            ProductDesc:
              'Designed to create the most professional-looking presentation through the highest-quality materials and design.',
            ImageSrc: ptpfsr120,
            ImageLrg: null,
            ButtonText: 'Get started',
            ButtonLink: '/tax-products/tax-return-folders/small-window-premium-tax-return-folders',
            LearnMoreLink:
              '/tax-products/tax-return-folders/small-window-premium-tax-return-folders',
            LearnMoreSuffixText: 'Small Window Premium Tax Return Presentation Folders',
            ActualPrice: 0,
            DiscountPrice: 0,
            Sku: null,
            Quantity: null,
            SpecialProcessId: 0,
            HasPricing: false,
            PFID: 'FOLDER-01',
          },
          {
            ProdDeptId: '',
            ProductId: '',
            ProductName: 'Large Window Premium Tax Return Presentation Folders',
            ProductDesc:
              'Designed to create the most professional-looking presentation through the highest-quality materials and design.',
            ImageSrc: ptpflr120,
            ImageLrg: null,
            ButtonText: 'Get started',
            ButtonLink: '/tax-products/tax-return-folders/large-window-premium-tax-return-folders',
            LearnMoreLink:
              '/tax-products/tax-return-folders/large-window-premium-tax-return-folders',
            LearnMoreSuffixText: 'Large Window Premium Tax Return Presentation Folders',
            ActualPrice: 0,
            DiscountPrice: 0,
            Sku: null,
            Quantity: null,
            SpecialProcessId: 0,
            HasPricing: false,
            PFID: 'FOLDER-02',
          },
          {
            ProdDeptId: '',
            ProductId: '',
            ProductName: 'Standard Tax Return Folders',
            ProductDesc:
              'Clients with less complex tax returns will be impressed by the polished look of these economical, slip-sheet compatible folders.',
            ImageSrc: ptpfls20,
            ImageLrg: null,
            ButtonText: 'Get started',
            ButtonLink: '/tax-products/tax-return-folders/standard-tax-return-folders',
            LearnMoreLink: '/tax-products/tax-return-folders/standard-tax-return-folders',
            LearnMoreSuffixText: 'Standard Tax Return Folders',
            ActualPrice: 0,
            DiscountPrice: 0,
            Sku: null,
            Quantity: null,
            SpecialProcessId: 0,
            HasPricing: false,
            PFID: 'FOLDER-03',
          },
          {
            ProdDeptId: '',
            ProductId: '',
            ProductName: 'Two Pocket Folders',
            ProductDesc:
              'Present important documents to clients or prospects in these high-quality, attractive two-pocket folders.',
            ImageSrc: tax2pf120regal,
            ImageLrg: null,
            ButtonText: 'Get started',
            ButtonLink: '/tax-products/tax-return-folders/two-pocket-folders',
            LearnMoreLink: '/tax-products/tax-return-folders/two-pocket-folders',
            LearnMoreSuffixText: 'Two Pocket Folders',
            ActualPrice: 0,
            DiscountPrice: 0,
            Sku: null,
            Quantity: null,
            SpecialProcessId: 0,
            HasPricing: false,
            PFID: 'FOLDER-04',
          },
        ],
      },
    ],
  },
  SMALL_PREMIUM_TAX_RETURN_FOLDER: {
    Department: {
      DeptName: 'Small Window Premium Tax Return Presentation Folder',
      DeptDesc:
        'Designed to create the most professional-looking presentation through the highest-quality materials and design.',
      DeptId: '',
      FetchPricing: true,
      RequestBody: ['FOLDER-01'],
      Image: ptp_fsr_regal,
      ImageLg: ptp_fsr_regal_large,
      PFID: 'FOLDER-01',
      ImageIsUrl: false,
      Images: {
        Regal: {
          Small: ptp_fsr_regal,
          Large: ptp_fsr_regal_large,
        },
        Hunter: {
          Small: ptp_fsr_hunter,
          Large: ptp_fsr_hunter_large,
        },
        Charcoal: {
          Small: ptp_fsr_charcoal,
          Large: ptp_fsr_charcoal_large,
        },
        Bramble: {
          Small: ptp_fsr_bramble,
          Large: ptp_fsr_bramble_large,
        },
      },
      ProductInformation: {
        Desc: (
          <span>
            Each folder is constructed of thick, classically textured, 88lb. paper stock and is
            available in four presentation colors.
          </span>
        ),
        DescExtras: [
          <span>
            These folders hold up to 50 pages and feature side staple binding, a right-hand pocket,
            and a business card slot. Slip sheet compatible.
          </span>,
        ],
        EachKitContains: null,
        Specifications: null,
        Compatibility: null,
      },
      PricingInformation: {
        Type: 'ADDTOCARTCOLORPICKER',
        Colors: ['Regal', 'Hunter', 'Charcoal', 'Bramble'],
        DefaultColor: 'Regal',
      },
      DepFooter:
        '1. Due to supply chain shortages, the color may be slightly different on the actual product.',
    },
  },
  LARGE_PREMIUM_TAX_RETURN_FOLDER: {
    Department: {
      DeptName: 'Large Window Premium Tax Return Presentation Folder',
      DeptDesc:
        'Designed to create the most professional-looking presentation through the highest-quality materials and design.',
      DeptId: '',
      FetchPricing: true,
      RequestBody: ['FOLDER-02'],
      Image: ptp_flr_regal,
      ImageLg: ptp_flr_regal_large,
      PFID: 'FOLDER-02',
      ImageIsUrl: false,
      Images: {
        Regal: {
          Small: ptp_flr_regal,
          Large: ptp_flr_regal_large,
        },
        Hunter: {
          Small: ptp_flr_hunter,
          Large: ptp_flr_hunter_large,
        },
        Charcoal: {
          Small: ptp_flr_charcoal,
          Large: ptp_flr_charcoal_large,
        },
        Bramble: {
          Small: ptp_flr_bramble,
          Large: ptp_flr_bramble_large,
        },
      },
      ProductInformation: {
        Desc: (
          <span>
            Each folder is constructed of thick, classically textured, 88lb. paper stock and is
            available in four presentation colors.
          </span>
        ),
        DescExtras: [
          <span>
            These folders hold up to 50 pages and feature side staple binding, a right-hand pocket,
            and a business card slot. Slip sheet compatible.
          </span>,
        ],
        EachKitContains: null,
        Specifications: null,
        Compatibility: null,
      },
      PricingInformation: {
        Type: 'ADDTOCARTCOLORPICKER',
        Colors: ['Regal', 'Hunter', 'Charcoal', 'Bramble'],
        DefaultColor: 'Regal',
      },
      DepFooter:
        '1. Due to supply chain shortages, the color may be slightly different on the actual product.',
    },
  },
  STANDARD_TAX_RETURN_FOLDER: {
    Department: {
      DeptName: 'Standard Tax Return Folder',
      DeptDesc: 'An affordable way to enhance your professional image.',
      DeptId: '',
      FetchPricing: true,
      RequestBody: ['FOLDER-03'],
      Image: ptp_fls_green,
      ImageLg: ptp_fls_green_large,
      PFID: 'FOLDER-03',
      ImageIsUrl: false,
      Images: {
        Green: {
          Small: ptp_fls_green,
          Large: ptp_fls_green_large,
        },
        Blue: {
          Small: ptp_fls_blue,
          Large: ptp_fls_blue_large,
        },
      },
      ProductInformation: {
        Desc: (
          <span>
            Constructed in basic, folder weight stock with an easy-to-use binding - simply insert
            documents, fold over the top flap and staple.
          </span>
        ),
        DescExtras: [],
        EachKitContains: null,
        Specifications: null,
        Compatibility: null,
      },
      PricingInformation: {
        Type: 'ADDTOCARTCOLORPICKER',
        Colors: ['Green', 'Blue'],
        DefaultColor: 'Green',
      },
      DepFooter:
        '1. Due to supply chain shortages, the color may be slightly different on the actual product.',
    },
  },
  TWO_POCKET_FOLDER: {
    Department: {
      DeptName: 'Two Pocket Folder',
      DeptDesc:
        'Present reports, plans, proposals, tax returns or financial statements in a versatile, multi-purpose, two-pocket folder.',
      DeptId: '',
      FetchPricing: true,
      RequestBody: ['FOLDER-04'],
      Image: tax_2pf_regal,
      ImageLg: tax_2pf_regal_large,
      PFID: 'FOLDER-04',
      ImageIsUrl: false,
      Images: {
        Regal: {
          Small: tax_2pf_regal,
          Large: tax_2pf_regal_large,
        },
        Hunter: {
          Small: tax_2pf_hunter,
          Large: tax_2pf_hunter_large,
        },
      },
      ProductInformation: {
        Desc: (
          <span>
            These high quality folders are made from premium 88lb textured paper and conveniently
            hold up to 50 pages.
          </span>
        ),
        DescExtras: [
          <span>
            The cover is custom-embossed with “Important Documents Enclosed” and includes a pre-cut
            business card slit.
          </span>,
        ],
        EachKitContains: null,
        Specifications: ['Size: 9" x 11.5"'],
        Compatibility: null,
      },
      PricingInformation: {
        Type: 'ADDTOCARTCOLORPICKER',
        Colors: ['Regal', 'Hunter'],
        DefaultColor: 'Regal',
      },
      DepFooter:
        '1. Due to supply chain shortages, the color may be slightly different on the actual product.',
    },
  },
  OFFICE_POS_SUPPLIES: {
    Department: {
      DeptName: 'Office & point of sale supplies',
      DeptDesc: null,
      DeptId: '',
      FetchPricing: true,
      RequestBody: ['SPEN-01'],
    },
    ProductSections: [
      {
        SectionHeader: '',
        SectionDesc: '',
        Products: [
          {
            ProdDeptId: '',
            ProductId: '',
            ProductName: 'Self-inking stamps',
            ProductDesc:
              'QuickBooks self-inking stamps give you better quality impressions and save you money with easily-replaceable ink refill pads.',
            ImageSrc: selfInkStamp,
            ImageSrcOptimized: OptimizedSelfInkStamp,
            ImageLrg: null,
            ImageHeight: '257px',
            ImageWidth: '220px',
            ButtonText: 'View all',
            ButtonLink: '/supplies/stamps',
            LearnMoreLink: null,
            LearnMoreSuffixText: null,
            ActualPrice: 0,
            DiscountPrice: 0,
            Sku: null,
            Quantity: null,
            SpecialProcessId: 0,
            HasPricing: false,
            PFID: '',
          },
          {
            ProdDeptId: '',
            ProductId: '',
            ProductName: 'Point of sale supplies',
            ProductDesc:
              'QuickBooks Point of Sale software and hardware work perfectly with these labels, tags, and receipt paper.',
            ImageSrc: posSup,
            ImageSrcOptimized: OptimizedPosSup,
            ImageLrg: null,
            ImageHeight: '164px',
            ImageWidth: '220px',
            ButtonText: 'View all',
            ButtonLink: '/supplies/pos',
            LearnMoreLink: null,
            LearnMoreSuffixText: null,
            ActualPrice: 0,
            DiscountPrice: 0,
            Sku: null,
            Quantity: null,
            SpecialProcessId: 0,
            HasPricing: false,
            PFID: '',
          },
          {
            ProdDeptId: '',
            ProductId: '',
            ProductName: 'Laser & inkjet labels',
            ProductDesc:
              'Save time and ensure accuracy with printable labels that integrate with QuickBooks software. Print directly from QuickBooks to your laser or inkjet printer. Our laser label sheets are Avery compatible.',
            ImageSrc: lsInkJet,
            ImageSrcOptimized: OptimizedLsInkJet,
            ImageLrg: null,
            ImageHeight: '260px',
            ImageWidth: '203px',
            ButtonText: 'View all',
            ButtonLink: '/supplies/labels/laser-labels',
            LearnMoreLink: null,
            LearnMoreSuffixText: null,
            ActualPrice: 0,
            DiscountPrice: 0,
            Sku: null,
            Quantity: null,
            SpecialProcessId: 0,
            HasPricing: false,
            PFID: '',
          },
          {
            ProdDeptId: '',
            ProductId: '',
            ProductName: 'Check binders',
            ProductDesc:
              'Attractive QuickBooks binders keep your Office & Away and 3-To-A-Page General Purpose Manual Checks ready and organized no matter where your business takes you.',
            ImageSrc: chkBinder,
            ImageSrcOptimized: OptimizedChkBinder,
            ImageLrg: null,
            ImageHeight: '180px',
            ImageWidth: '220px',
            ButtonText: 'View all',
            ButtonLink: '/supplies/binders',
            LearnMoreLink: null,
            LearnMoreSuffixText: null,
            ActualPrice: 0,
            DiscountPrice: 0,
            Sku: null,
            Quantity: null,
            SpecialProcessId: 0,
            HasPricing: false,
            PFID: '',
          },
          {
            ProdDeptId: '',
            ProductId: '',
            ProductName: 'QuickBooks printable business forms',
            ProductDesc:
              'Use QuickBooks forms to save time and display a consistent, professional image. Our printable forms are produced on a thick stock, high quality paper designed specifically for QuickBooks Desktop compatibility.',
            ImageSrc: frmS120,
            ImageSrcOptimized: OptimizedFrmS120,
            ImageLrg: null,
            ImageHeight: '241px',
            ImageWidth: '188px',
            ButtonText: 'View all',
            ButtonLink: '/supplies/forms',
            LearnMoreLink: null,
            LearnMoreSuffixText: null,
            ActualPrice: 0,
            DiscountPrice: 0,
            Sku: null,
            Quantity: null,
            SpecialProcessId: 0,
            HasPricing: false,
            PFID: '',
          },
          {
            ProdDeptId: '',
            ProductId: '',
            ProductName: 'Security pens',
            ProductDesc:
              'Protect your business against check washing forgery with our Super Ink ™ Security Pen',
            ImageSrc: secPen,
            ImageSrcOptimized: OptimizedSecPen,
            ImageLrg: null,
            ImageHeight: '156px',
            ImageWidth: '216px',
            ButtonText: 'Order now',
            ButtonLink: '/supplies/security-pens',
            LearnMoreLink: null,
            LearnMoreSuffixText: null,
            ActualPrice: true,
            DiscountPrice: 0,
            Sku: null,
            Quantity: null,
            SpecialProcessId: 0,
            HasPricing: false,
            PFID: 'SPEN-01',
          },
        ],
      },
    ],
  },
  QB_POS_SUPPLIES: {
    Department: {
      DeptName: 'QuickBooks point of sale supplies',
      DeptDesc:
        'QuickBooks point of sale supplies are specifically designed to work with QuickBooks Point-of-Sale software and hardware for guaranteed compatibility.',
      DeptId: '',
      FetchPricing: true,
      RequestBody: [
        'THERMAL-05',
        'THERMAL-01',
        'RECEIPT-01',
        'THERMAL-02',
        'THERMAL-03',
        'THERMAL-04',
      ],
    },
    ProductSections: [
      {
        SectionHeader: '',
        SectionDesc: '',
        Products: [
          {
            ProdDeptId: '',
            ProductId: '',
            ProductName: 'Price label 1.2" x 0.85"',
            ProductDesc:
              'Smaller items need smaller labels. These self-adhesive labels are the right size for the job.',
            ImageSrc: posLb285,
            ImageSrcOptimized: OptimizedPosLb285,
            ImageHeight: '220px',
            ImageWidth: '220px',
            ImageLrg: null,
            ButtonText: 'Order now',
            ButtonLink: '/stampsandlabels/price-labels',
            LearnMoreLink: null,
            LearnMoreSuffixText: null,
            ActualPrice: true,
            DiscountPrice: 0,
            Sku: null,
            Quantity: null,
            SpecialProcessId: 0,
            HasPricing: false,
            PFID: 'THERMAL-05',
          },
          {
            ProdDeptId: '',
            ProductId: '',
            ProductName: 'Price label 2.25" x 1.25"',
            ProductDesc: 'These labels are an ideal size for pricing merchandise of all sizes.',
            ImageSrc: posLb225,
            ImageSrcOptimized: OptimizedPosLb225,
            ImageHeight: '220px',
            ImageWidth: '220px',
            ImageLrg: null,
            ButtonText: 'Order now',
            ButtonLink: '/stampsandlabels/large-price-labels',
            LearnMoreLink: null,
            LearnMoreSuffixText: null,
            ActualPrice: true,
            DiscountPrice: 0,
            Sku: null,
            Quantity: null,
            SpecialProcessId: 0,
            HasPricing: false,
            PFID: 'THERMAL-01',
          },
          {
            ProdDeptId: '',
            ProductId: '',
            ProductName: 'Point of sale paper rolls',
            ProductDesc: 'Keep your receipt paper well stocked.',
            ImageSrc: posPaperROll,
            ImageSrcOptimized: OptimizedPosPaperROll,
            ImageHeight: '220px',
            ImageWidth: '220px',
            ImageLrg: null,
            ButtonText: 'Order now',
            ButtonLink: '/supplies/receipt-paper',
            LearnMoreLink: null,
            LearnMoreSuffixText: null,
            ActualPrice: true,
            DiscountPrice: 0,
            Sku: null,
            Quantity: null,
            SpecialProcessId: 0,
            HasPricing: false,
            PFID: 'RECEIPT-01',
          },
          {
            ProdDeptId: '',
            ProductId: '',
            ProductName: 'Printable hang tags',
            ProductDesc:
              'These professional thermal tags are ideal for tagging purses, clothing, and other fabric items. Easily attached to merchandise using any standard tagging gun.',
            ImageSrc: posHangTags,
            ImageSrcOptimized: OptimizedPosHangTags,
            ImageHeight: '220px',
            ImageWidth: '220px',
            ImageLrg: null,
            ButtonText: 'Order now',
            ButtonLink: '/stampsandlabels/hang-tags-quickbooks',
            LearnMoreLink: null,
            LearnMoreSuffixText: null,
            ActualPrice: true,
            DiscountPrice: 0,
            Sku: null,
            Quantity: null,
            SpecialProcessId: 0,
            HasPricing: false,
            PFID: 'THERMAL-02',
          },
          {
            ProdDeptId: '',
            ProductId: '',
            ProductName: 'Jewelry tags with flap',
            ProductDesc:
              'Waterproof jewelry tags with a specially designed flap that keeps adhesive away from your precious merchandise. The flap folds in over the middle of the tag, so they will not leave a sticky residue on products. Less time spent cleaning adhesive translates into more time for customer service.',
            ImageSrc: posJewelTagsFlag,
            ImageSrcOptimized: OptimizedPosJewelTagsFlag,
            ImageHeight: '220px',
            ImageWidth: '220px',
            ImageLrg: null,
            ButtonText: 'Order now',
            ButtonLink: '/stampsandlabels/jewelry-tags-quickbooks',
            LearnMoreLink: null,
            LearnMoreSuffixText: null,
            ActualPrice: true,
            DiscountPrice: 0,
            Sku: null,
            Quantity: null,
            SpecialProcessId: 0,
            HasPricing: false,
            PFID: 'THERMAL-03',
          },
          {
            ProdDeptId: '',
            ProductId: '',
            ProductName: 'Jewelry tags without flap',
            ProductDesc: 'These waterproof tags are an easy way to price small items like jewelry',
            ImageSrc: posJewelTagsWithoutFlap,
            ImageSrcOptimized: OptimizedPosJewelTagsWithoutFlap,
            ImageHeight: '220px',
            ImageWidth: '220px',
            ImageLrg: null,
            ButtonText: 'Order now',
            ButtonLink: '/stampsandlabels/jewelry-tags',
            LearnMoreLink: null,
            LearnMoreSuffixText: null,
            ActualPrice: true,
            DiscountPrice: 0,
            Sku: null,
            Quantity: null,
            SpecialProcessId: 0,
            HasPricing: false,
            PFID: 'THERMAL-04',
          },
        ],
      },
    ],
  },
  PRICE_LABEL_12_085: {
    Department: {
      DeptName: 'Price label 1.2" x 0.85"',
      DeptDesc: null,
      DeptId: '',
      FetchPricing: true,
      RequestBody: ['THERMAL-05'],
      Image: lbRl120,
      ImageLg: lbRl120,
      PFID: 'THERMAL-05',
      PriceDisplay: 'case',
      ProductInformation: {
        Desc: null,
        DescExtras: null,
        DescPoints: {
          Header: null,
          List: [
            'Perfect for pricing smaller items, these self-adhesive labels are a great option',
          ],
        },
        CheckLockProtection: false,
        EachKitContains: null,
        Specifications: [
          'Label Size: 1.1875"(w) x .84375"(l), 1500 labels per roll',
          'Each order contains 12 rolls per case',
          'Compatibility: Supported versions of QuickBooks Point of Sale along with the QuickBooks Point of Sale Tag Printer',
        ],
        Compatibility: null,
        SpecialNote: [<span>Tagged merchandise not included</span>],
        Remarks: null,
      },
      PricingInformation: {
        Type: 'ADDTOCART',
      },
    },
  },
  PRICE_LABEL_225_123: {
    Department: {
      DeptName: 'Price label 2.25" x 1.25"',
      DeptDesc: null,
      DeptId: '',
      FetchPricing: true,
      RequestBody: ['THERMAL-01'],
      Image: lbRl2120,
      ImageLg: lbRl2120,
      PFID: 'THERMAL-01',
      PriceDisplay: 'case',
      ProductInformation: {
        Desc: null,
        DescExtras: null,
        DescPoints: {
          Header: null,
          List: ['These self-adhesive labels are a great all-around choice for price tags'],
        },
        CheckLockProtection: false,
        EachKitContains: null,
        Specifications: [
          'Label Size: 2.25"(w) x 1.25"(l), 1135 labels per roll',
          'Each order contains 12 rolls per case',
          'Compatibility: Supported versions of QuickBooks Point of Sale along with the QuickBooks Point of Sale Tag Printer',
        ],
        Compatibility: null,
        SpecialNote: [<span>Tagged merchandise not included</span>],
        Remarks: null,
      },
      PricingInformation: {
        Type: 'ADDTOCART',
      },
    },
  },
  POS_PAPER_ROLLS: {
    Department: {
      DeptName: 'Point of sale paper rolls',
      DeptDesc: null,
      DeptId: '',
      FetchPricing: true,
      RequestBody: ['RECEIPT-01'],
      Image: lbRpt120,
      ImageLg: lbRpt120,
      PFID: 'RECEIPT-01',
      ProductInformation: {
        Desc: null,
        DescExtras: null,
        DescPoints: {
          Header: null,
          List: [
            'Reduce your chance of running out of POS receipt paper',
            'Works perfectly with QuickBooks POS printers: the Star TSP143 and Star TSP613',
            'Receipt printer paper available for order in packages of 10 or 50 rolls',
            'Each roll of printer receipt paper is 3.15 inches wide by 220 feet long',
          ],
        },
        CheckLockProtection: false,
        EachKitContains: null,
        Specifications: [
          'Roll Size: 3.15" wide and 220\' long',
          'Compatibility with QuickBooks Point-of-Sale receipt printers Star TSP143 and Star TSP613',
        ],
        Compatibility: null,
        Remarks: null,
      },
      PricingInformation: {
        Type: 'ADDTOCART',
      },
    },
    Warning: true,
  },
  PRINTABLE_HANG_TAGS: {
    Department: {
      DeptName: 'Printable hang tags',
      DeptDesc:
        'Hang tags and labels add a professional touch and help your product line stand out from .',
      DeptId: '',
      FetchPricing: true,
      RequestBody: ['THERMAL-02'],
      Image: lnHng120,
      ImageLg: lnHng120,
      PFID: 'THERMAL-02',
      PriceDisplay: 'case',
      ProductInformation: {
        Desc: 'Tag purses, clothing, and other fabric items with our thermal printed hang tags',
        DescExtras: null,
        DescPoints: {
          Header: null,
          List: [
            'Print hang tags directly from QuickBooks Point of Sale software',
            'Attach with any standard tagging gun (not included)',
            '12 rolls per case; 967 labels per roll',
            'Tag dimensions: 2.25" x 1.35"',
            'Compatible with supported versions of QuickBooks Point of Sale software and Point of Sale tag printer',
          ],
        },
        CheckLockProtection: false,
        EachKitContains: null,
        Specifications: null,
        Compatibility: null,
        Remarks: null,
      },
      PricingInformation: {
        Type: 'ADDTOCART',
      },
    },
    Warning: false,
  },
  JEWELRY_TAG_WITH_FLAP: {
    Department: {
      DeptName: 'Jewelry tags with flap',
      DeptDesc:
        'Clearly display jewelry item prices without tarnishing products with adhesive.These waterproof jewelry tags feature a flap in the middle of the tag that folds over to create an adhesive-free loop for attaching tags to inventory.',
      DeptId: '',
      FetchPricing: true,
      RequestBody: ['THERMAL-03'],
      Image: lbJwf120,
      ImageLg: lbJwf120,
      PFID: 'THERMAL-03',
      PriceDisplay: 'rolls',
      ProductInformation: {
        Desc: null,
        DescExtras: null,
        DescPoints: null,
        CheckLockProtection: false,
        EachKitContains: null,
        Specifications: [
          'Jewelry Label Size: 2 3/16"(w) x 1/2"(l), 2490 labels per roll',
          'Available in cases of 4 rolls or 12 rolls of jewelry hang tags',
          'Jewelry Tag Compatibility: Supported versions of QuickBooks Point of Sale along with the QuickBooks Point of Sale Tag Printer',
        ],
        Compatibility: null,
        SpecialNote: ['Tagged merchandise not included'],
        Remarks: null,
      },
      PricingInformation: {
        Type: 'ADDTOCART',
      },
    },
    Warning: false,
  },
  JEWELRY_TAG_WITHOUT_FLAP: {
    Department: {
      DeptName: 'Jewelry tags without flap',
      DeptDesc: null,
      DeptId: '',
      FetchPricing: true,
      RequestBody: ['THERMAL-04'],
      Image: lbJw120,
      ImageLg: lbJw120,
      PFID: 'THERMAL-04',
      PriceDisplay: 'rolls',
      ProductInformation: {
        Desc: null,
        DescExtras: null,
        DescPoints: {
          Header: null,
          List: [
            'These waterproof tags provide a fast, easy way to price jewelry and other small items',
          ],
        },
        CheckLockProtection: false,
        EachKitContains: null,
        Specifications: [
          'Label Size: 2 3/16"(w) x 1/2"(l), 2490 labels per roll',
          'Available in cases of 4 rolls or 12 rolls',
          'Compatibility: Supported versions of QuickBooks Point of Sale along with the QuickBooks Point of Sale Tag Printer',
        ],
        Compatibility: null,
        SpecialNote: ['Tagged merchandise not included'],
        Remarks: null,
      },
      PricingInformation: {
        Type: 'ADDTOCART',
      },
    },
    Warning: false,
  },
  PRINTABLE_LASER_AND_INKJET_LABELS: {
    Department: {
      DeptName: 'Printable laser and inkjet labels',
      DeptDesc:
        'Save time and ensure accuracy with printable labels that integrate with QuickBooks software. Print directly from QuickBooks to your laser or inkjet printer. Our laser label sheets are Avery compatible.',
      DeptId: '',
      FetchPricing: true,
      RequestBody: ['LABEL-03', 'LABEL-10', 'LABEL-02', 'LABEL-07'],
    },
    ProductSections: [
      {
        SectionHeader: '',
        SectionDesc: '',
        Products: [
          {
            ProdDeptId: '',
            ProductId: '',
            ProductName: '1" x 4" labels',
            ProductDesc: '20 labels each sheet. Avery #5161 compatible.',
            ImageSrc: lbl20b120,
            ImageLrg: null,
            ButtonText: 'Order now',
            ButtonLink: '/supplies/labels/avery-labels-5161',
            LearnMoreLink: null,
            LearnMoreSuffixText: null,
            ActualPrice: 40.99,
            DiscountPrice: 0,
            Sku: null,
            Quantity: null,
            SpecialProcessId: 0,
            HasPricing: false,
            PFID: 'LABEL-03',
          },
          {
            ProdDeptId: '',
            ProductId: '',
            ProductName: '2/3" x 3 7/16" file folder labels',
            ProductDesc: '30 labels each sheet. Avery #5366 compatible.',
            ImageSrc: lblFf120,
            ImageLrg: null,
            ButtonText: 'Order now',
            ButtonLink: '/supplies/file-folder-labels-5366-avery',
            LearnMoreLink: null,
            LearnMoreSuffixText: null,
            ActualPrice: 40.99,
            DiscountPrice: 0,
            Sku: null,
            Quantity: null,
            SpecialProcessId: 0,
            HasPricing: false,
            PFID: 'LABEL-10',
          },
          {
            ProdDeptId: '',
            ProductId: '',
            ProductName: '1" x 2 5/8" labels',
            ProductDesc: '30 labels each sheet. Avery #5160 compatible.',
            ImageSrc: lbl30b120,
            ImageLrg: null,
            ButtonText: 'Order now',
            ButtonLink: '/supplies/labels/avery-labels-5160',
            LearnMoreLink: null,
            LearnMoreSuffixText: null,
            ActualPrice: 40.99,
            DiscountPrice: 0,
            Sku: null,
            Quantity: null,
            SpecialProcessId: 0,
            HasPricing: false,
            PFID: 'LABEL-02',
          },
          {
            ProdDeptId: '',
            ProductId: '',
            ProductName: '1/2" x 1 3/4" labels',
            ProductDesc: '80 labels each sheet. Avery #5167 compatible.',
            ImageSrc: lbl80b120,
            ImageLrg: null,
            ButtonText: 'Order now',
            ButtonLink: '/supplies/labels/avery-labels-5167',
            LearnMoreLink: null,
            LearnMoreSuffixText: null,
            ActualPrice: 40.99,
            DiscountPrice: 0,
            Sku: null,
            Quantity: null,
            SpecialProcessId: 0,
            HasPricing: false,
            PFID: 'LABEL-07',
          },
        ],
      },
    ],
  },
  AVERY_LABELS_5161: {
    Department: {
      DeptName: '1" x 4" labels',
      DeptDesc: null,
      DeptId: '',
      FetchPricing: true,
      RequestBody: ['LABEL-03'],
      Image: lbl20b120,
      ImageLg: null,
      PFID: 'LABEL-03',
      ProductInformation: {
        Desc: null,
        DescExtras: null,
        DescPoints: {
          Header: null,
          List: ['Works with laser/inkjet printers', 'Compatible with Avery #5161 and #8161'],
        },
        CheckLockProtection: false,
        EachKitContains: null,
        Specifications: [
          'Sheet Size: 8 ½" x 11", 20 labels per sheet, 2000 labels per package of 100 sheets',
          'Not compatible with QuickBooks Online',
        ],
        Compatibility: null,
        SpecialNote: null,
        Remarks: null,
      },
      PricingInformation: {
        Type: 'ADDTOCART',
      },
    },
    Warning: false,
  },
  AVERY_LABELS_5366: {
    Department: {
      DeptName: '2/3" x 3 7/16" file folder labels',
      DeptDesc: null,
      DeptId: '',
      FetchPricing: true,
      RequestBody: ['LABEL-10'],
      Image: lblFf120,
      ImageLg: null,
      PFID: 'LABEL-10',
      ProductInformation: {
        Desc: null,
        DescExtras: null,
        DescPoints: {
          Header: null,
          List: ['Works with laser/inkjet printers', 'Compatible with Avery #5366'],
        },
        CheckLockProtection: false,
        EachKitContains: null,
        Specifications: [
          'Sheet Size: 8 ½"(w) x 11"(L), 30 labels per sheet, 3000 labels per package of 100 sheets',
          'Not compatible with QuickBooks Online',
        ],
        Compatibility: null,
        SpecialNote: null,
        Remarks: null,
      },
      PricingInformation: {
        Type: 'ADDTOCART',
      },
    },
    Warning: false,
  },
  AVERY_LABELS_5160: {
    Department: {
      DeptName: '1" x 2 5/8" labels',
      DeptDesc: null,
      DeptId: '',
      FetchPricing: true,
      RequestBody: ['LABEL-02'],
      Image: lbl30b120,
      ImageLg: null,
      PFID: 'LABEL-02',
      ProductInformation: {
        Desc: null,
        DescExtras: null,
        DescPoints: {
          Header: null,
          List: ['Works with laser/inkjet printers', 'Compatible with Avery #5160 and #8160'],
        },
        CheckLockProtection: false,
        EachKitContains: null,
        Specifications: [
          'Sheet Size: 8 ½" x 11", 30 labels per sheet, 3000 labels per package of 100 sheets',
          'Not compatible with QuickBooks Online',
        ],
        Compatibility: null,
        SpecialNote: null,
        Remarks: null,
      },
      PricingInformation: {
        Type: 'ADDTOCART',
      },
    },
    Warning: false,
  },
  AVERY_LABELS_5167: {
    Department: {
      DeptName: '1/2" x 1 3/4" labels',
      DeptDesc: null,
      DeptId: '',
      FetchPricing: true,
      RequestBody: ['LABEL-07'],
      Image: lbl30b120,
      ImageLg: null,
      PFID: 'LABEL-07',
      ProductInformation: {
        Desc: null,
        DescExtras: null,
        DescPoints: {
          Header: null,
          List: ['Works with laser/inkjet printers', 'Compatible with Avery #5167'],
        },
        CheckLockProtection: false,
        EachKitContains: null,
        Specifications: [
          'Sheet Size: 8 ½"(w) x 11"(l), 80 labels per sheet, 8000 labels per package of 100 sheets',
          'Not compatible with QuickBooks Online',
        ],
        Compatibility: null,
        SpecialNote: null,
        Remarks: null,
      },
      PricingInformation: {
        Type: 'ADDTOCART',
      },
    },
    Warning: false,
  },
  BUSINESS_CHECK_BINDERS: {
    Department: {
      DeptName: 'Business check binders',
      DeptDesc:
        'Carry your business checks wherever your busy day takes you. Our handsome checkbook binders keep your 3-To-A-Page General Purpose Manual Checks or Office & Away Checks organized and handy throughout your travels.',
      DeptId: '',
      FetchPricing: true,
      RequestBody: ['MCBINDER-01', 'BIN-01'],
    },
    ProductSections: [
      {
        SectionHeader: '',
        SectionDesc: '',
        Products: [
          {
            ProdDeptId: '',
            ProductId: '',
            ProductName: 'Office and away checkbook binder',
            ProductDesc:
              'Carry your checks in organized style. Transport up to 75 Office & Away Checks with our handsome, rugged check book binder. Other binder features include a business card pocket, a place to store important documents and a convenient pen holder.',
            ImageSrc: chkBinder,
            ImageLrg: null,
            ButtonText: 'Order now',
            ButtonLink: '/supplies/binders/away-check-binder',
            LearnMoreLink: null,
            LearnMoreSuffixText: null,
            ActualPrice: true,
            DiscountPrice: 0,
            Sku: null,
            Quantity: null,
            SpecialProcessId: 0,
            HasPricing: false,
            PFID: 'BIN-01',
          },
          {
            ProdDeptId: '',
            ProductId: '',
            ProductName: '7-ring manual business check binder',
            ProductDesc:
              'Designed to work with our 3-To-A-Page General Purpose Manual Checks, this 7-ring check binder keeps your business checks handy wherever you go!',
            ImageSrc: bc7Ring,
            ImageLrg: null,
            ButtonText: 'Order now',
            ButtonLink: '/supplies/binders/manual-check-binder',
            LearnMoreLink: null,
            LearnMoreSuffixText: null,
            ActualPrice: true,
            DiscountPrice: 0,
            Sku: null,
            Quantity: null,
            SpecialProcessId: 0,
            HasPricing: false,
            PFID: 'MCBINDER-01',
          },
        ],
      },
    ],
  },
  AWAY_CHECK_BINDER: {
    Department: {
      DeptName: 'Office and away checkbook binder',
      DeptDesc: null,
      DeptId: '',
      FetchPricing: true,
      RequestBody: ['BIN-01'],
      Image: chkBinder120,
      ImageLg: chkBinder340,
      PFID: 'BIN-01',
      ProductInformation: {
        Desc: null,
        DescExtras: null,
        DescPoints: {
          Header: null,
          List: [
            'Helps you to stay organized with a handsome binder',
            'Holds up to 75 checks and comes with a business card pocket, place to store important documents and convenient pen holder',
          ],
        },
        CheckLockProtection: false,
        EachKitContains: null,
        Specifications: [
          <span>
            Designed for use with our{' '}
            <a
              href="/checks/office-away-checks"
              className="universal-link"
              target="_self"
              rel="noopener noreferrer"
            >
              Office &amp; Away Checks
            </a>
          </span>,
        ],
        Compatibility: null,
        SpecialNote: null,
        Remarks: null,
      },
      PricingInformation: {
        Type: 'ADDTOCART',
      },
    },
    Warning: false,
  },
  RING_7_CHECK_BINDER: {
    Department: {
      DeptName: ' 7-ring manual business check binder',
      DeptDesc: null,
      DeptId: '',
      FetchPricing: true,
      RequestBody: ['MCBINDER-01'],
      Image: bc7Ring120,
      ImageLg: bc7Ring340,
      PFID: 'MCBINDER-01',
      PriceDisplay: null,
      ProductInformation: {
        Desc: (
          <span>
            This 7-ring binder works perfectly with our{' '}
            <Link
              to="/checks/manual-checks"
              className="universal-link"
              target="_self"
              rel="noopener noreferrer"
            >
              3-To-A-Page General Purpose Manual Checks
            </Link>
          </span>
        ),
        DescExtras: null,
        DescPoints: null,
        CheckLockProtection: false,
        EachKitContains: null,
        Specifications: null,
        Compatibility: null,
        SpecialNote: null,
        Remarks: null,
      },
      PricingInformation: {
        Type: 'ADDTOCART',
      },
    },
    Warning: false,
  },
  VOUCHER_CHECK_VALUE_PACK: {
    Department: {
      DeptName: 'Basic Voucher Check Value Pack with Stamp',
      DeptDesc: null,
      DeptId: '',
      FetchPricing: false,
      RequestBody: null,
      Image: chk_vvp_120,
      ImageLg: chk_vvp_340,
      PFID: 'BUNDLE-01',
      ProductInformation: {
        Desc: (
          <span>
            Save up to $100 on this complete, time-saving solution. Everything you need for bill
            payment and banking, including an endorsement stamp. Voucher checks help track payroll
            deductions and invoice payment details - ideal for payroll and accounts payable.
          </span>
        ),
        EachKitContains: [
          '250, 500, or 1000 Basic Voucher Checks',
          '250 or 500 matching Self-Seal Envelopes',
          '250 Printable or 200 Booked Manual Deposit Slips',
          'One endorsement Stamp',
        ],
        Compatibility: [
          <span key={1}>
            Basic Voucher checks are compatible with all supported versions of QuickBooks.
          </span>,
          <span key={2}>
            Printable Deposit Slips are compatible with all supported versions of QuickBooks Online,
            QuickBooks Desktop, and QuickBooks for Macintosh 2007 and above.
          </span>,
        ],
      },
      PricingInformation: {
        Type: 'BUNDLE',
        Link: '/checks/voucher-check-value-pack/customization',
        LinkEnabled: true,
        depositSlipsParts: [
          { Label: 'Printable, 1 part', Value: '3' },
          { Label: 'Booked Manual, 1 part', Value: '1' },
          { Label: 'Booked Manual, 2 parts', Value: '2' },
        ],
      },
    },
  },
  BASIC_CHECK_VALUE_PACK: {
    Department: {
      DeptName: 'Basic Voucher Check Value Pack',
      DeptDesc: null,
      DeptId: '',
      FetchPricing: false,
      RequestBody: null,
      Image: VoucherCheckBundle_Basic_sm,
      ImageLg: VoucherCheckBundle_Basic_lg,
      PFID: 'BUNDLE-02',
      ProductInformation: {
        Desc: (
          <span>
            Save up to $100 on your bill payment and banking basics. Use voucher checks to track
            payroll deductions and invoice payment details - ideal for payroll and accounts payable.
          </span>
        ),
        EachKitContains: [
          '250, 500, or 1000 Basic Voucher Checks',
          '250 or 500 matching Self-Seal Envelopes',
          '250 Printable or 200 Booked Manual Deposit Slips',
        ],
        Compatibility: [
          <span key={1}>
            Basic Voucher checks are compatible with all supported versions of QuickBooks.
          </span>,
          <span key={2}>
            Printable Deposit Slips are compatible with all supported versions of QuickBooks Online,
            QuickBooks Desktop, and QuickBooks for Macintosh 2007 and above.
          </span>,
        ],
      },
      PricingInformation: {
        Type: 'BUNDLE',
        Link: '/checks/basic-check-value-pack/customization',
        LinkEnabled: true,
        depositSlipsParts: [
          { Label: 'Printable, 1 part', Value: '3' },
          { Label: 'Booked Manual, 1 part', Value: '1' },
          { Label: 'Booked Manual, 2 parts', Value: '2' },
        ],
      },
    },
  },
  STANDARD_VALUE_PACK: {
    Department: {
      DeptName: 'Basic Standard Check Value Pack with Stamp',
      DeptDesc: null,
      DeptId: '',
      FetchPricing: false,
      RequestBody: null,
      Image: chk_svp_120,
      ImageLg: chk_svp_340,
      PFID: 'BUNDLE-03',
      ProductInformation: {
        Desc: (
          <span>
            Save up to $80 and and get everything you need for bill payment and banking in one pack
            - including an endorsement stamp. Includes three checks per page for fast, economical
            printing.
          </span>
        ),
        EachKitContains: [
          '250, 500, or 1000 Basic Standard Checks',
          '250 or 500 matching Self-Seal Envelopes',
          '250 Printable or 200 Booked Manual Deposit Slips',
          'One endorsement Stamp',
        ],
        Compatibility: [
          <span key={1}>
            Basic Standard Checks are compatible with QuickBooks Desktop and QuickBooks Online.
            Standard checks are not compatible with QuickBooks Online Payroll.
          </span>,
          <span key={2}>
            Printable Deposit Slips are compatible with all supported versions of QuickBooks Online,
            QuickBooks Desktop, and QuickBooks for Macintosh 2007 and above.
          </span>,
        ],
      },
      PricingInformation: {
        Type: 'BUNDLE',
        Link: '/checks/standard-value-pack/customization',
        LinkEnabled: true,
        depositSlipsParts: [
          { Label: 'Printable, 1 part', Value: '3' },
          { Label: 'Booked Manual, 1 part', Value: '1' },
          { Label: 'Booked Manual, 2 parts', Value: '2' },
        ],
      },
    },
  },
  VALUE_PACKS: {
    Department: {
      DeptName: 'QuickBooks Business Check Value Packs',
      DeptDesc: (
        <span>
          <span className="font-wgt-500">Save up to $100! </span>
          Our Check Value Packs include the essentials for printing checks and simplifying bill
          payment and business banking. Print professional checks and deposit slips directly from
          QuickBooks. Save time addressing envelopes and deposit checks faster while practically
          eliminating double entry. Save money and get your business checks at a bundled discount.
        </span>
      ),
      DeptId: '',
      FetchPricing: false,
      RequestBody: null,
    },
    ProductSections: [
      {
        SectionHeader: '',
        SectionDesc: '',
        Products: [
          {
            ProdDeptId: '',
            ProductId: '',
            ProductName: 'Basic Voucher Check Value Pack with Stamp',
            ProductDesc:
              'Includes Basic Voucher Checks with matching envelopes, your choice of Deposit Slips, and an Endorsement stamp.',
            ImageSrc: chk_vvp_120,
            ImageLrg: null,
            ButtonText: null,
            LearnMoreLink: '/checks/voucher-check-value-pack',
            ActualPrice: 0,
            DiscountPrice: 0,
            Sku: null,
            ButtonLink: null,
            Quantity: null,
            SpecialProcessId: 0,
            HasPricing: true,
            PFID: null,
          },
          {
            ProdDeptId: '',
            ProductId: '',
            ProductName: 'Basic Voucher Check Value Pack',
            ProductDesc:
              'Includes Basic Voucher Checks with matching envelopes, and your choice of Deposit Slips.',
            ImageSrc: VoucherCheckBundle_Basic_sm,
            ImageLrg: null,
            ButtonText: null,
            LearnMoreLink: '/checks/basic-check-value-pack',
            ActualPrice: 0,
            DiscountPrice: 0,
            Sku: null,
            ButtonLink: null,
            Quantity: null,
            SpecialProcessId: 0,
            HasPricing: true,
            PFID: null,
          },
          {
            ProdDeptId: '',
            ProductId: '',
            ProductName: 'Basic Standard Check Value Pack with Stamp',
            ProductDesc:
              'Includes Basic Standard Voucher Checks with matching envelopes, your choice of Deposit Slips, and an Endorsement stamp.',
            ImageSrc: chk_svp_120,
            ImageLrg: null,
            ButtonText: null,
            LearnMoreLink: '/checks/standard-value-pack',
            ActualPrice: 0,
            DiscountPrice: 0,
            Sku: null,
            ButtonLink: null,
            Quantity: null,
            SpecialProcessId: 0,
            HasPricing: true,
            PFID: null,
          },
        ],
      },
    ],
  },
  VOUCHER_VALUE_PACKS: {
    Department: {
      DeptName: 'QuickBooks Voucher Check Value Packs',
      DeptDesc: (
        <span>
          <span className="font-wgt-500">Save up to $85 </span>
          on essentials for printing checks, simplifying bill payment, and easier business banking.
          Help protect your business from fraud by choosing our Secure Plus or Secure Premier
          checks. The matching self-sealing envelopes add an extra layer of security. Save money and
          get essential business supplies that make running your business easier.
        </span>
      ),
      DeptId: '',
      FetchPricing: false,
      RequestBody: null,
      Remarks: ['1. Based on feature comparison of secure checks conducted June 2021'],
    },
    ProductSections: [
      {
        SectionHeader: '',
        SectionDesc: '',
        Products: [
          {
            ProdDeptId: '',
            ProductId: '',
            ProductName: 'Secure Plus Voucher Check Value Pack',
            ProductDesc: (
              <span>
                Get our Secure Plus Voucher Checks with
                <span className="font-wgt-600"> 23 security features </span>
                to help keep your business safe from fraud along with matching security envelopes
                and your choice of deposit slips.
              </span>
            ),
            ImageSrc: VoucherCheckBundle_SecurePlus_sm,
            ImageLrg: null,
            ButtonText: null,
            LearnMoreLink: '/checks/secure-plus-check-value-pack',
            ActualPrice: 0,
            DiscountPrice: 0,
            Sku: null,
            ButtonLink: null,
            Quantity: null,
            SpecialProcessId: 0,
            HasPricing: true,
            PFID: null,
          },
          {
            ProdDeptId: '',
            ProductId: '',
            ProductName: 'Secure Premier Voucher Check Value Pack',
            ProductDesc: (
              <span>
                Includes our Secure Premier Voucher Checks -
                <span className="font-wgt-600"> the most secure checks on the market¹</span> - with
                matching envelopes and your choice of Deposit Slips.
              </span>
            ),
            ImageSrc: VoucherCheckBundle_SecurePremier_sm,
            ImageLrg: null,
            ButtonText: null,
            LearnMoreLink: '/checks/secure-premier-check-value-pack',
            ActualPrice: 0,
            DiscountPrice: 0,
            Sku: null,
            ButtonLink: null,
            Quantity: null,
            SpecialProcessId: 0,
            HasPricing: true,
            PFID: null,
          },
          {
            ProdDeptId: '',
            ProductId: '',
            ProductName: 'Basic Voucher Check Value Pack',
            ProductDesc:
              'Includes Basic Voucher Checks with matching envelopes and your choice of Deposit Slips.',
            ImageSrc: VoucherCheckBundle_Basic_sm,
            ImageLrg: null,
            ButtonText: null,
            LearnMoreLink: '/checks/basic-check-value-pack',
            ActualPrice: 0,
            DiscountPrice: 0,
            Sku: null,
            ButtonLink: null,
            Quantity: null,
            SpecialProcessId: 0,
            HasPricing: true,
            PFID: null,
          },
        ],
      },
    ],
  },
  VOUCHER_PLUS_VALUE_PACKS: {
    Department: {
      DeptName: 'Secure Plus Voucher Check Value Pack',
      DeptDesc: null,
      DeptId: '',
      FetchPricing: false,
      RequestBody: null,
      Image: VoucherCheckBundle_SecurePlus_sm,
      ImageLg: VoucherCheckBundle_SecurePlus_lg,
      PFID: 'BUNDLE-06',
      ProductInformation: {
        Desc: (
          <span>
            Save up to $85 on your bill payment and banking basics. Use voucher checks to track
            payroll deductions and invoice payment details - ideal for payroll and accounts payable.
          </span>
        ),
        DescExtras: [
          <span>
            Secure Plus Voucher Checks offer advanced protection against check fraud with{' '}
            <span className="font-wgt-600">23 additional security features,</span> including a heat
            sensitive icon that prevents photocopying, a true watermark that protects against
            forgery and counterfeiting, and our exclusive security coating that blocks tampering
            with your check info.
          </span>,
        ],
        EachKitContains: [
          '250, 500, or 1000 Secure Plus Voucher Checks',
          '250 or 500 matching Self-Seal Envelopes',
          '250 Printable or 200 Booked Manual Deposit Slips',
        ],
        Specifications: null,
        Compatibility: [
          <span key={1}>
            Secure Plus Voucher checks are compatible with all supported versions of QuickBooks.
          </span>,
          <span key={2}>
            Printable Deposit Slips are compatible with all supported versions of QuickBooks Online,
            QuickBooks Desktop, and QuickBooks for Macintosh 2007 and above.
          </span>,
        ],
      },
      PricingInformation: {
        Type: 'BUNDLE',
        Link: '/checks/secure-plus-check-value-pack/customization',
        LinkEnabled: true,
        depositSlipsParts: [
          { Label: 'Printable, 1 part', Value: '3' },
          { Label: 'Booked Manual, 1 part', Value: '1' },
          { Label: 'Booked Manual, 2 parts', Value: '2' },
        ],
      },
    },
  },
  VOUCHER_PREMIER_VALUE_PACKS: {
    Department: {
      DeptName: 'Secure Premier Voucher Check Value Pack',
      DeptDesc: null,
      DeptId: '',
      FetchPricing: false,
      RequestBody: null,
      Image: VoucherCheckBundle_SecurePremier_sm,
      ImageLg: VoucherCheckBundle_SecurePremier_lg,
      PFID: 'BUNDLE-07',
      ProductInformation: {
        Desc: (
          <span>
            Save up to $85 on your bill payment and banking basics. Use voucher checks to track
            payroll deductions and invoice payment details - ideal for payroll and accounts payable.
          </span>
        ),
        DescExtras: [
          <span>
            With <span className="font-wgt-600">29 security features -</span> including a security
            hologram that makes your check virtually impossible to counterfeit - our Secure Premier
            Checks are <span className="font-wgt-600">the most secure checks in the market.¹</span>
          </span>,
        ],
        EachKitContains: [
          '250, 500, or 1000 Secure Premier Voucher Checks',
          '250 or 500 matching Self-Seal Envelopes',
          '250 Printable or 200 Booked Manual Deposit Slips',
        ],
        Specifications: null,
        Compatibility: [
          <span key={1}>
            Secure Premier Voucher checks are compatible with all supported versions of QuickBooks.
          </span>,
          <span key={2}>
            Printable Deposit Slips are compatible with all supported versions of QuickBooks Online,
            QuickBooks Desktop, and QuickBooks for Macintosh 2007 and above.
          </span>,
        ],
        Remarks: ['1. Based on feature comparison of secure checks conducted June 2021'],
      },
      PricingInformation: {
        Type: 'BUNDLE',
        Link: '/checks/secure-premier-check-value-pack/customization',
        LinkEnabled: true,
        depositSlipsParts: [
          { Label: 'Printable, 1 part', Value: '3' },
          { Label: 'Booked Manual, 1 part', Value: '1' },
          { Label: 'Booked Manual, 2 parts', Value: '2' },
        ],
      },
    },
  },
  CHECKS_RESOURCE_CENTER: {
    title: 'Checks',
    subTitle: 'Explore our checks and find the ones just right for your business.',
    isSmallCard: false,
    image: ResourceCheckImage,
    details: [
      {
        smallHeader: 'CHECKS',
        heading: 'Four Questions to Ask When Choosing Business Checks',
        description:
          'Asking yourself these four questions will help you figure out which business check meets your company’s needs.',
        bottomMargin: '',
        link: '/resources/fraud-prevention/4-questions-to-ask-when-choosing-checks',
        boxStyle: 'padding-right-80px',
      },
    ],
    faq: [
      {
        smallHeader: 'CHECKS',
        heading: 'What You Need to Know About Business Checks',
        description:
          'While using a paper document for a payment may seem outdated, by no means are paper checks going out of style.',
        bottomMargin: '',
        link: '/resources/checks/what-is-a-business-check',
        paraStyle: 'padding-right-35px',
      },
      {
        smallHeader: 'CHECKS',
        heading: 'What is Check 21 and How Does it Affect Small Business Owners?',
        description:
          'In 2004, a federal law passed that made regulations around the process of clearing checks faster and more efficient. That law was called the Check Clearing for the 21st Century Act, or Check 21 for short.',
        bottomMargin: '',
        link: '/resources/checks/check-21',
        paraStyle: 'padding-right-30px',
      },
      {
        smallHeader: 'CHECKS',
        heading: 'Business Check Endorsements: What You Should Know',
        description:
          'You receive a check for payment for your business. What next? You sign the back of the check and then take it to the bank, right? Not so fast.',
        bottomMargin: '',
        link: '/resources/checks/endorsements',
        paraStyle: 'padding-right-30px',
      },
      {
        smallHeader: 'CHECKS',
        heading: '5 Must-Haves for Your Check Writing Software',
        description:
          'If you’re considering printing business checks we recommend keeping these five things in mind when deciding which check-writing software to use.',
        bottomMargin: 'padding-top-55px',
        link: '/resources/checks/check-printing-software',
        paraStyle: 'padding-right-30px',
      },
      {
        smallHeader: 'CHECKS',
        heading: '7 Most Common Business Check Mistakes to Avoid',
        description:
          'In this article, we’ll lay out some common check writing errors, so you can take steps to stay clear of them.',
        bottomMargin: 'padding-top-55px',
        link: '/resources/fraud-prevention/7-most-common-business-check-mistakes',
        paraStyle: 'padding-right-30px',
      },
      {
        smallHeader: 'CHECKS',
        heading: 'Choosing the Right Type of Business Checks',
        description:
          'Picking the right check style for your business really comes down to how you plan to use the checks.',
        bottomMargin: 'padding-top-55px',
        link: '/resources/checks/types-of-business-checks-why-it-matters',
        paraStyle: 'padding-right-30px',
      },
    ],
  },
  TAX_FORMS_RESOURCE_CENTER: {
    title: 'Tax Forms',
    isSmallCard: true,
    subTitle: 'Resources to help you get to know common tax forms and how to use them.',
    image: ResourceTaxFormImage,
    DetailsbottomPadding: 'padding-bottom-20px',
    details: [
      {
        smallHeader: 'TAX FORMS',
        heading: 'Get to Know IRS Tax Forms 1099 and 1096',
        description:
          'Have part-time workers or contractors you’ve paid more than $600 this year? You’ll need to send them a 1099 form.',
        bottomMargin: 'margin-bottom-60px',
        link: '/resources/tax-forms/1099-1096',
        boxStyle: 'padding-right-210px',
      },
      {
        smallHeader: 'TAX FORMS',
        heading: 'Get to Know IRS Tax Form W-2',
        description:
          'If you have employees, you’re required to report their wages and tax with holdings each year to the IRS. The W-2 is how you report an individual employee’s total annual wages.',
        bottomMargin: '',
        link: '/resources/tax-forms/w2-w3',
        boxStyle: 'padding-right-235px',
      },
    ],
  },
  CHECK_FRAUD_RESOURCE_CENTER: {
    title: 'Check Fraud Prevention',
    subTitle: 'Learn about the dangers of check fraud and how our checks can help prevent it.',
    isSmallCard: true,
    image: ResourceCheckFraudImage,
    details: [
      {
        smallHeader: 'CHECK FRAUD PREVENTION',
        heading: 'The Big Three in Check Security',
        description:
          'While no check can claim to be 100% fraud proof, the right security features can go a long way to keep criminal from altering or photocopying your checks.',
        bottomMargin: '',
        link: '/resources/fraud-prevention/security-check-features',
        boxStyle: 'padding-right-235px',
      },
    ],
    faq: [
      {
        smallHeader: 'CHECK FRAUD PREVENTION',
        heading: '5 Essential Tips on Check Security',
        description:
          'If you’re not careful, your physical checks can become a fraudster’s key to unlocking your finances, leaving you to spend months, maybe even years, recouping your losses. ',
        bottomMargin: '',
        link: '/resources/fraud-prevention/5-things-to-look-for',
        paraStyle: 'padding-right-30px',
      },
      {
        smallHeader: 'CHECK FRAUD PREVENTION',
        heading: 'What is Check Fraud & Why it Matters to Your Business',
        description:
          'Check fraud is a type of payment fraud that often involves someone intercepting your check and changing its information or creating fake checks using your check information.',
        bottomMargin: '',
        link: '/resources/fraud-prevention/counterfeiting',
      },
      {
        smallHeader: 'CHECK FRAUD PREVENTION',
        heading: 'A Case Study in Check Fraud: Carole Maddux',
        description: `In Maddux's case, it involved a criminal cashing counterfeit checks drawn on one of her client's accounts - complete with Maddux's forged signature.`,
        bottomMargin: '',
        link: '/resources/fraud-prevention/it-can-happen-to-you',
        paraStyle: 'padding-right-20px',
      },
      {
        smallHeader: 'CHECK FRAUD PREVENTION',
        heading: 'How to Properly Dispose of Business Checks',
        description:
          'Choosing the right way to get rid of your checks can help make sure your financial and business information remains safe and secure.',
        bottomMargin: 'padding-top-55px',
        link: '/resources/fraud-prevention/how-to-dispose-of-checks',
        paraStyle: '',
      },
    ],
  },
  BUSINESS_FRAUD_RESOURCE_CENTER: {
    title: 'Business Fraud Prevention',
    subTitle: 'Useful tips and tools to help keep your business safe from fraud.',
    isSmallCard: true,

    faq: [
      {
        smallHeader: 'BUSINESS FRAUD PREVENTION',
        heading: '6 Scams Targetting Small Businesses and How to Avoid Them',
        description:
          'Small businesses are increasingly falling victim to scams, so it pays to keep up your guard.',
        link: '/resources/business-fraud-prevention/6-scams-to-know-and-avoid',
        paraStyle: 'padding-right-30px',
      },
      {
        smallHeader: 'BUSINESS FRAUD PREVENTION',
        heading: '10 Ways Your Employees Can Help You Fight Fraud',
        description:
          'Cybercriminals are waiting outside the proverbial door to steal confidential financial data and more. And guess who lets them in?',
        link: '/resources/business-fraud-prevention/10-ways-employees-stop-scams',
      },
      {
        smallHeader: 'BUSINESS FRAUD PREVENTION',
        heading: 'Small Business Tax Scams: How to Lower Your Risk',
        description:
          'By learning how to spot the tax scams criminals like to use, you can help prevent your business from becoming a tax fraud victim.',
        link: '/resources/business-fraud-prevention/tax-scams-to-avoid',
      },
    ],
  },
};

export default PageData;
