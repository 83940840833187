import React from 'react';
import { Modal, ModalContent } from '@ids/modal-dialog';
import '../../../../stylesheets/customization/customization.scss';
import Button from '@ids/button';
import { useSelector } from 'react-redux';

export const CheckConfirmationModal = (props) => {
  const { open } = props;
  const customizationReducer = useSelector((state) => state.customizationReducer);

  const { routingNumber, accountNumber } = customizationReducer;

  const handleClick = () => {
    props.callBack();
    props.close();
  };

  return (
    <Modal
      size="small"
      onClose={() => {
        props.close();
      }}
      className="confirmation-modal"
      open={open}
      styleOverrideReason="none"
    >
      <ModalContent alignment="left" className="confirmation-md-body">
        <span className="confirmation-md-title">Confirm your routing and account numbers</span>
        <div>
          <p className="confirmation-text">
            We couldn’t verify the bank information you entered. Double-check that you’ve entered
            your routing and account numbers correctly; make sure you include any zeros at the
            beginning of your account number.
          </p>
          <div className="confirmation-md-bank">
            <span>Routing number:</span>
            <div>{routingNumber}</div>
          </div>
          <div className="confirmation-md-bank">
            <span>Checking account number:</span>
            <div>{accountNumber}</div>
          </div>
          <div>
            <p className="confirmation-text">
              If the bank information is correct, click{' '}
              <span className="confirmation-sub-title">{props.btnText}</span> Otherwise, click the
              edit link to change it.
            </p>
          </div>
        </div>
        <div className="confirmation-md-footer">
          <Button buttonType="tertiary" theme="quickbooks" onClick={props.close}>
            Edit bank information
          </Button>
          <Button buttonType="primary" theme="quickbooks" onClick={handleClick}>
            {props.btnText}
          </Button>
        </div>
      </ModalContent>
    </Modal>
  );
};
