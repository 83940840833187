export const NAVIGATION = [
  {
    title: 'Checks',
    link: '/checks',
    datalink: 'hdr-checks:all checks',
    navigation: [
      {
        link: '/checks',
        name: 'All Checks',
        datalink: 'hdr-checks:all checks',
      },
      {
        link: '/checks/voucher-checks',
        name: 'Voucher Checks',
        datalink: 'hdr-checks:voucher checks',
      },
      {
        link: '/checks/standard-checks',
        name: 'Standard Checks',
        datalink: 'hdr-checks:standard checks',
      },
      {
        link: '/checks/wallet-checks',
        name: 'Business Wallet Checks',
        datalink: 'hdr-checks:business wallet checks',
      },
      {
        link: '/checks/manual-checks',
        name: 'Manual Business Checks',
        datalink: 'hdr-checks:manual business checks',
      },
      {
        link: '/checks/office-away-checks',
        name: 'Office and Away Checks',
        datalink: 'hdr-checks:office and away checks',
      },
      {
        link: '/checks/personal-checks',
        name: 'Secure Plus Personal Checks',
        datalink: 'hdr-check:secure plus personal checks',
      },
      {
        link: '/checks/direct-deposit-forms',
        name: 'Direct Deposit Forms',
        datalink: 'hdr-checks:direct deposit forms',
      },
    ],
  },
  {
    title: 'Tax Products',
    link: '/tax-products',
    datalink: 'hdr-taxforms:all tax forms',
    navigation: [
      {
        link: '/tax-forms',
        name: 'All Tax Forms',
        datalink: 'hdr-taxforms:all tax forms',
      },
      {
        link: '/tax-forms/w2-forms/pre-printed-w-2-kits',
        name: 'Pre-Printed W-2 Kits',
        datalink: 'hdr-taxforms:pre-printed w-2 kits',
      },
      {
        link: '/tax-forms/w2-forms/w-2-blank-envelope-kit',
        name: 'Blank W-2 Kits',
        datalink: 'hdr-taxforms:blank w-2 kits',
      },
      {
        link: '/tax-forms/1099-forms',
        name: '1099-NEC Kits',
        datalink: 'hdr-taxforms:1099-NEC kits',
      },
      {
        link: '/tax-forms/1099-forms',
        name: '1099-MISC Kits',
        datalink: 'hdr-taxforms:1099-MISC kits',
      },
      {
        link: '/tax-forms/w3-forms',
        name: 'W-3 Forms',
        datalink: 'hdr-taxforms:w-3 forms',
      },
      {
        link: '/tax-forms/1096-forms',
        name: '1096 Forms',
        datalink: 'hdr-taxforms:1096 forms',
      },
      {
        link: '/tax-products/tax-return-folders',
        name: 'Tax Return Folders',
        datalink: 'hdr-taxforms:tax return folders',
      },
      {
        link: '/tax-products/tax-filing-envelopes',
        name: 'Tax Filing Envelopes',
        datalink: 'hdr-taxforms:tax filing envelopes',
      },
      {
        link: '/tax-products/organizer-envelopes',
        name: 'Tax Organizer Envelopes',
        datalink: 'hdr-taxforms:tax organizer envelopes',
      },
    ],
  },
  {
    title: 'Envelopes',
    link: '/envelopes',
    datalink: 'hdr-envelopes:all envelopes',
    navigation: [
      {
        link: '/envelopes',
        name: 'All Envelopes',
        datalink: 'hdr-envelopes:all envelopes',
      },
      {
        link: '/envelopes/check-window',
        name: 'Check Window',
        datalink: 'hdr-envelopes:check window',
      },
      {
        link: '/envelopes/form-envelopes',
        name: 'Forms Envelopes',
        datalink: 'hdr-envelopes:forms',
      },
      {
        link: '/envelopes/custom-printed-envelopes',
        name: 'Custom Printed',
        datalink: 'hdr-envelopes:custom printed',
      },
      {
        link: '/tax-products/tax-filing-envelopes',
        name: 'Tax Filing Envelopes',
        datalink: 'hdr-envelopes:tax filing envelopes',
      },
      {
        link: '/tax-products/organizer-envelopes',
        name: 'Tax Organizer Envelopes',
        datalink: 'hdr-envelopes:tax organizer envelopes',
      },
    ],
  },
  {
    title: 'Deposit Slips',
    link: '/deposit-slips',
    datalink: 'hdr-depositslips:all deposit slips',
    navigation: [
      {
        link: '/deposit-slips',
        name: 'All Deposit Slips',
        datalink: 'hdr-depositslips:all deposit slips',
      },
      {
        link: '/deposit-slips/printable',
        name: 'Printable',
        datalink: 'hdr-depositslips:printable',
      },
      {
        link: '/deposit-slips/manual',
        name: 'Booked Manual',
        datalink: 'hdr-depositslips:manual',
      },
      {
        link: '/deposit-slips/disposable-deposit-bags',
        name: 'Disposable Deposit Bags',
        datalink: 'hdr-depositslips:disposable deposit bags',
      },
    ],
  },
  {
    title: 'More Supplies',
    link: '/supplies',
    datalink: 'hdr-moresupplies:moresupplies',
    navigation: [
      {
        link: '/supplies',
        name: 'Office & Point of Sale Supplies',
        datalink: 'hdr-moresupplies:moresupplies',
      },
      {
        link: '/supplies/stamps',
        name: 'Self Inking Stamps',
        datalink: 'hdr-moresupplies:stamps',
      },
      {
        link: '/supplies/pos',
        name: 'Point of Sale Supplies',
        datalink: 'hdr-moresupplies:pointofsalesupplies',
      },
      {
        link: '/supplies/labels/laser-labels',
        name: 'Laser and Inkjet Labels',
        datalink: 'hdr-moresupplies:labels',
      },
      {
        link: '/supplies/binders',
        name: 'Check Binders',
        datalink: 'hdr-moresupplies:checkbinders',
      },
      {
        link: '/supplies/forms',
        name: 'Business Forms',
        datalink: 'hdr-moresupplies:forms',
      },
      {
        link: '/supplies/security-pens',
        name: 'Security Pens',
        datalink: 'hdr-moresupplies:securitypens',
      },
    ],
  },
  {
    title: 'Resource Center',
    link: '/resources',
    datalink: 'hdr-resource-center:Resource Center',
    navigation: [
      {
        link: '/resources/checks',
        name: 'Checks',
        datalink: 'hdr-resource-center:Checks',
      },
      {
        link: '/resources/tax-forms',
        name: 'Tax Forms',
        datalink: 'hdr-resource-center:Tax Forms',
      },
      {
        link: '/resources/fraud-prevention',
        name: 'Check Fraud Prevention',
        datalink: 'hdr-resource-center:Check Fraud Prevention',
      },
      {
        link: '/resources/business-fraud-prevention',
        name: 'Business Fraud Prevention',
        datalink: 'hdr-resource-center:Business Fraud Prevention',
      },
      {
        link: '/help',
        name: 'Support',
        datalink: 'hdr-resource-center:support',
      },
      {
        link: '/secure-products',
        name: 'QuickBooks Security Products',
        datalink: 'hdr-security-products:QuickBooks Security Products',
        lastElement: true,
      },
    ],
  },
];
