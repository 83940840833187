import React from 'react';

import spvc_hero from '../../images/IM/Homepage/SPVC_Hero.png';
import voucher_checks_img from '../../images/IM/Homepage/background_voucher.gif';
import standard_checks_img from '../../images/IM/Homepage/background_standard.png';
import wallet_checks_img from '../../images/IM/Homepage/background_wallet.png';
import voucher_modal_img from '../../images/IM/Homepage/modal_voucher.jpg';
import standard_modal_img from '../../images/IM/Homepage/modal_standard.jpg';
import wallet_modal_img from '../../images/IM/Homepage/modal_wallet.jpg';
import CHECK_VALUE_PACK_IMG from '../../images/IM/Homepage/HP_check_value_pack_sized.png';
import DEP_IMG from '../../images/IM/Homepage/HP_icon_page_depositslips.png';
import ENV_IMG from '../../images/IM/Homepage/CH_illustration_page_envelopes.png';

const HomePageData = {
  HOME_PAGE: {
    Hero: {
      heading: 'Save time with QuickBooks Printable Checks',
      image: spvc_hero,
      imageAlt: '',
      features: [
        <span>
          Designed <span className="font-wgt-600">exclusively</span> for QuickBooks
        </span>,
        <span>
          Keep all your check records in <span className="font-wgt-600">one place</span>
        </span>,
        <span>
          QuickBooks <span className="font-wgt-600">automatically</span> fills in your checks for
          you
        </span>,
      ],
      getStartedLink: '/spvc',
      getStartedText: 'Get started with our Secure Plus Voucher Checks',
      otherProductsLink: '/checks',
      otherProductsText: 'Shop all check styles',
      reorderText: 'Returning customer?',
      reorderLinkText: 'Time to reorder checks',
    },
    RightChecks: {
      heading: 'We have the right checks for your business.',
      subHeading: "What's your style?",
      popularProducts: [
        {
          name: 'Voucher',
          description: 'Keep a check stub as proof of payment for invoices and payroll',
          imageUrl: voucher_checks_img,
          learnMoreLink: '/checks/voucher-checks',
          customizeLink: '/checks/voucher-checks/secure-plus-voucher-checks/customization',
          isMostPopular: true,
          modal: {
            image: voucher_modal_img,
            title: 'Voucher',
            subTitle: 'Our best-selling check',
            description:
              'Great for every business. A professional, customized check, plus a stub for proof of payment for all your invoices and employee payroll.',
          },
        },
        {
          name: 'Standard',
          description: '3-to-a-page business-sized checks for faster printing',
          imageUrl: standard_checks_img,
          learnMoreLink: '/checks/standard-checks',
          customizeLink:
            '/checks/standard-checks/secure-plus-standard-business-checks/customization',
          modal: {
            image: standard_modal_img,
            title: 'Standard',
            subTitle: 'Business-sized checks',
            description:
              "Great for those who want a professional look, but don't need stubs for bookkeeping. Three checks to a page saves you printing time.",
          },
        },
        {
          name: 'Wallet',
          description: 'Professional-looking checks and stubs, in a smaller size',
          imageUrl: wallet_checks_img,
          learnMoreLink: '/checks/wallet-checks',
          customizeLink: '/checks/wallet-checks/secure-plus-wallet-checks/customization',
          modal: {
            image: wallet_modal_img,
            title: 'Wallet',
            subTitle: 'Personal-sized checks',
            description:
              'Great for those who like a professional look and stubs for better record keeping, in a smaller size. Three checks to a page saves you printing time.',
          },
        },
      ],
    },
    shouldDisplayYouTubeSection: false,
    YouTubeSection: {
      title:
        'Our exclusive security coating helps keep the name and check amount safe from tampering.',
      vedioId: 'a42ypxvCry0',
      vedioMessage: 'Smarter security for your business checks(0:19)',
      productAdv: {
        productText: 'Get started with our Secure Plus Voucher Checks.',
        productLink: '/checks/voucher-checks/secure-plus-voucher-checks/customization',
      },
    },
    SmoothRunningBusinessSupplies: {
      title: 'Supplies to keep your business running smoothly',
      products: [
        {
          productName: 'Check Value Packs',
          productImage: CHECK_VALUE_PACK_IMG,
          productFeatures: [
            'Checks, envelopes, deposit slips',
            'All-in-one bundle',
            'Our best price: save up to $100',
          ],
          learnMoreLink: '/checks/value-packs',
        },
        {
          productName: 'Deposit Slips',
          productImage: DEP_IMG,
          productFeatures: [
            'Print easily from QuickBooks',
            'Manual slips available',
            'Customize for a professional look',
          ],
          learnMoreLink: '/deposit-slips',
        },
        {
          productName: 'Security Envelopes',
          productImage: ENV_IMG,
          productFeatures: [
            'Professional look',
            'Keeps contents confidential',
            'No need to handwrite addresses',
          ],
          learnMoreLink: '/envelopes/check-window',
        },
      ],
    },
  },
};

export default HomePageData;
