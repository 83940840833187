import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { checkForTaxSeason, getImageSrc } from '../Util/CommonUtils';
import { ReduxEvent } from '../../../assets/pagedata/constant';
import '../../../stylesheets/footer.scss';

// eslint-disable-next-line require-jsdoc
const FabComponentPage = () => {
  const dispatch = useDispatch();
  const [backtopDisplay, setBacktopDisplay] = useState('fade-out');
  const mainReducer = useSelector((state) => state.mainReducer);
  useEffect(() => {
    function fixNavigation() {
      if (window.pageYOffset > 300) {
        // header.classList.add('sticky-header');
        setBacktopDisplay('fade-in');
      } else {
        // header.classList.remove('sticky-header');
        setBacktopDisplay('fade-out');
      }
    }
    window.onscroll = () => {
      fixNavigation();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // displaying tax banner based on system date
  useEffect(() => {
    const isTaxSeason = checkForTaxSeason(mainReducer.systemDate);
    isTaxSeason &&
      dispatch({ type: ReduxEvent.TAX_SEASON, data: { isTaxSeason: { isTaxSeason } } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainReducer.systemDate]);
  const scrollTopEvt = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  return (
    <div className="StaticComponant" aria-label="Call for sales">
      <div tabIndex="0" className="phone-icon-content-section">
        <p>
          <span className="contact-us" style={{ paddingLeft: '38px' }}>
            For Sales:
          </span>
        </p>
        <img
          alt="For Sales: call (866) 570-3842"
          className="phone-callout"
          height={40}
          width={185}
          src={getImageSrc('/ui/images/phoneicon.png')}
        />
      </div>
      <div className="scroll-top-container">
        <div id="top-scroll">
          <img
            onClick={scrollTopEvt}
            className={backtopDisplay}
            title="Back to Top"
            src={getImageSrc('/ui/images/backtotop.png')}
            alt="backtotop"
            loading='lazy'
          />
        </div>
      </div>
    </div>
  );
};

export default FabComponentPage;
